/**
 * SystemCommandsPage Component
 *
 * /pages/ConfigurePage/SystemCommandsPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";
import { FormattedSystemCommands } from "../../types/formatted/FormattedSystemCommands";
import { RequiredProperties } from "../../types/wrappers/RequiredProperties";

import { useServer } from "../../hooks/useServer";
import { useRequest } from "../../hooks/useRequest";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { InputToggleSwitch } from "../../components/InputToggleSwitch/InputToggleSwitch";
import { SettingSection } from "../../components/SettingSection/SettingSection";
import { SettingPage } from "../../components/SettingPage/SettingPage";
import { FormFooter } from "../../components/FormFooter/FormFooter";

import { settingsConfig } from "../../config/settings.config";

import { CommandsSchema } from "../../schemas/commands.schema";

/**
 * Define the SystemCommandsPage Component
 * @returns SystemCommandsPage Component
 */
export const SystemCommandsPage = () => {

    // Define relevant data
    const request = useRequest();
    const { systemNumber } = useParams();
    const { server, setServer } = useServer();
    const [ updating, setUpdating ] = useState( false );
    const [ success, setSuccess ] = useState( false );
    const [ error, setError ] = useState( "" );

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    let systemSettings = systemCheck;

    // Define formik form and validation
    const formik = useFormik<RequiredProperties<FormattedSystemCommands>>( {
        enableReinitialize: true,
        initialValues: {
            enableCommandName: systemSettings.enableCommandName
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandName.default.premium : settingsConfig.commands.settings.enableCommandName.default.free ),
            enableCommandLimit: systemSettings.enableCommandLimit
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandLimit.default.premium : settingsConfig.commands.settings.enableCommandLimit.default.free ),
            enableCommandBitrate: systemSettings.enableCommandBitrate
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandBitrate.default.premium : settingsConfig.commands.settings.enableCommandBitrate.default.free ),
            enableCommandLock: systemSettings.enableCommandLock
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandLock.default.premium : settingsConfig.commands.settings.enableCommandLock.default.free ),
            enableCommandUnlock: systemSettings.enableCommandUnlock
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandUnlock.default.premium : settingsConfig.commands.settings.enableCommandUnlock.default.free ),
            enableCommandGrant: systemSettings.enableCommandGrant
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandGrant.default.premium : settingsConfig.commands.settings.enableCommandGrant.default.free ),
            enableCommandDeny: systemSettings.enableCommandDeny
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandDeny.default.premium : settingsConfig.commands.settings.enableCommandDeny.default.free ),
            enableCommandClear: systemSettings.enableCommandClear
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandClear.default.premium : settingsConfig.commands.settings.enableCommandClear.default.free ),
            enableCommandKick: systemSettings.enableCommandKick
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandKick.default.premium : settingsConfig.commands.settings.enableCommandKick.default.free ),
            enableCommandMute: systemSettings.enableCommandMute
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandMute.default.premium : settingsConfig.commands.settings.enableCommandMute.default.free ),
            enableCommandUnmute: systemSettings.enableCommandUnmute
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandUnmute.default.premium : settingsConfig.commands.settings.enableCommandUnmute.default.free ),
            enableCommandTransfer: systemSettings.enableCommandTransfer
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandTransfer.default.premium : settingsConfig.commands.settings.enableCommandTransfer.default.free ),
            enableCommandClaim: systemSettings.enableCommandClaim
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandClaim.default.premium : settingsConfig.commands.settings.enableCommandClaim.default.free ),
            enableCommandDelete: systemSettings.enableCommandDelete
                ?? ( server.premium ? settingsConfig.commands.settings.enableCommandDelete.default.premium : settingsConfig.commands.settings.enableCommandDelete.default.free )
        },
        validationSchema: CommandsSchema(),
        onSubmit: ( values ) => {
            setUpdating( true );
            setError( "" );
            request.post( `/server/${ server.id }/system/${ systemNumber }/commands`, values ).then( () => {
                systemSettings = { ... systemSettings, ... values };
                server.settings.systems[ systemNumber ] = systemSettings;
                setServer( server );
                setUpdating( false );
                setSuccess( true );
            } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
                setUpdating( false );
                setSuccess( false );
                setError( e.response?.data?.message ?? "Failed to update system." );
            } );
        }
    } );

    // Display the page
    return (

        <SettingPage
            heading={ `System #${ systemNumber } Commands` }
            subheading={ `${ systemSettings.systemName } System` }>

            <FormikProvider value={ formik }>
                <form onSubmit={ formik.handleSubmit } onReset={ formik.handleReset } autoComplete="off">

                    <SettingSection title="Channel Commands">

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandName.title }
                            name="enableCommandName"
                            description={ settingsConfig.commands.settings.enableCommandName.description }
                            metaData={ settingsConfig.commands.settings.enableCommandName.metaData }
                            value={ formik.values.enableCommandName }
                            error={ formik.errors.enableCommandName }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandName.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandName.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandLimit.title }
                            name="enableCommandLimit"
                            description={ settingsConfig.commands.settings.enableCommandLimit.description }
                            metaData={ settingsConfig.commands.settings.enableCommandLimit.metaData }
                            value={ formik.values.enableCommandLimit }
                            error={ formik.errors.enableCommandLimit }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandLimit.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandLimit.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandBitrate.title }
                            name="enableCommandBitrate"
                            description={ settingsConfig.commands.settings.enableCommandBitrate.description }
                            metaData={ settingsConfig.commands.settings.enableCommandBitrate.metaData }
                            value={ formik.values.enableCommandBitrate }
                            error={ formik.errors.enableCommandBitrate }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandBitrate.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandBitrate.falseText } />

                    </SettingSection>

                    <SettingSection title="State Commands">

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandLock.title }
                            name="enableCommandLock"
                            description={ settingsConfig.commands.settings.enableCommandLock.description }
                            metaData={ settingsConfig.commands.settings.enableCommandLock.metaData }
                            value={ formik.values.enableCommandLock }
                            error={ formik.errors.enableCommandLock }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandLock.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandLock.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandUnlock.title }
                            name="enableCommandUnlock"
                            description={ settingsConfig.commands.settings.enableCommandUnlock.description }
                            metaData={ settingsConfig.commands.settings.enableCommandUnlock.metaData }
                            value={ formik.values.enableCommandUnlock }
                            error={ formik.errors.enableCommandUnlock }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandUnlock.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandUnlock.falseText } />

                    </SettingSection>

                    <SettingSection title="Access Commands">

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandGrant.title }
                            name="enableCommandGrant"
                            description={ settingsConfig.commands.settings.enableCommandGrant.description }
                            metaData={ settingsConfig.commands.settings.enableCommandGrant.metaData }
                            value={ formik.values.enableCommandGrant }
                            error={ formik.errors.enableCommandGrant }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandGrant.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandGrant.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandDeny.title }
                            name="enableCommandDeny"
                            description={ settingsConfig.commands.settings.enableCommandDeny.description }
                            metaData={ settingsConfig.commands.settings.enableCommandDeny.metaData }
                            value={ formik.values.enableCommandDeny }
                            error={ formik.errors.enableCommandDeny }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandDeny.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandDeny.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandClear.title }
                            name="enableCommandClear"
                            description={ settingsConfig.commands.settings.enableCommandClear.description }
                            metaData={ settingsConfig.commands.settings.enableCommandClear.metaData }
                            value={ formik.values.enableCommandClear }
                            error={ formik.errors.enableCommandClear }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandClear.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandClear.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandKick.title }
                            name="enableCommandKick"
                            description={ settingsConfig.commands.settings.enableCommandKick.description }
                            metaData={ settingsConfig.commands.settings.enableCommandKick.metaData }
                            value={ formik.values.enableCommandKick }
                            error={ formik.errors.enableCommandKick }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandKick.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandKick.falseText } />

                    </SettingSection>

                    <SettingSection title="Voice Commands">

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandMute.title }
                            name="enableCommandMute"
                            description={ settingsConfig.commands.settings.enableCommandMute.description }
                            metaData={ settingsConfig.commands.settings.enableCommandMute.metaData }
                            value={ formik.values.enableCommandMute }
                            error={ formik.errors.enableCommandMute }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandMute.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandMute.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandUnmute.title }
                            name="enableCommandUnmute"
                            description={ settingsConfig.commands.settings.enableCommandUnmute.description }
                            metaData={ settingsConfig.commands.settings.enableCommandUnmute.metaData }
                            value={ formik.values.enableCommandUnmute }
                            error={ formik.errors.enableCommandUnmute }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandUnmute.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandUnmute.falseText } />

                    </SettingSection>

                    <SettingSection title="Ownership Commands">

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandTransfer.title }
                            name="enableCommandTransfer"
                            description={ settingsConfig.commands.settings.enableCommandTransfer.description }
                            metaData={ settingsConfig.commands.settings.enableCommandTransfer.metaData }
                            value={ formik.values.enableCommandTransfer }
                            error={ formik.errors.enableCommandTransfer }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandTransfer.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandTransfer.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandClaim.title }
                            name="enableCommandClaim"
                            description={ settingsConfig.commands.settings.enableCommandClaim.description }
                            metaData={ settingsConfig.commands.settings.enableCommandClaim.metaData }
                            value={ formik.values.enableCommandClaim }
                            error={ formik.errors.enableCommandClaim }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandClaim.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandClaim.falseText } />

                    </SettingSection>

                    <SettingSection title="Utility Commands">

                        <InputToggleSwitch
                            title={ settingsConfig.commands.settings.enableCommandDelete.title }
                            name="enableCommandDelete"
                            description={ settingsConfig.commands.settings.enableCommandDelete.description }
                            metaData={ settingsConfig.commands.settings.enableCommandDelete.metaData }
                            value={ formik.values.enableCommandDelete }
                            error={ formik.errors.enableCommandDelete }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.commands.settings.enableCommandDelete.trueText }
                            falseText={ settingsConfig.commands.settings.enableCommandDelete.falseText } />

                    </SettingSection>

                    <FormFooter ready={ formik.dirty && formik.isValid } success={ success } setSuccess={ setSuccess } updating={ updating } error={ error } />

                </form>
            </FormikProvider>

        </SettingPage>

    );

};
