/**
 * Breakpoints Definition
 *
 * /theme/breakpoints.tsx
 *
 * Copyright (C) 2022 wadawe
 */

/**
 * Define the website breakpoints
 */
export const breakpoints = {
    values: {
        xs: 0,
        sm: 620,
        md: 860,
        lg: 1120,
        xl: 1440
    }
};
