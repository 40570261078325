/**
 * useRefresh Hook
 * For handling user refresh tokens
 *
 * /hooks/useRefresh.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { AxiosResponse } from "axios";
import { useLocation } from "react-router-dom";

import { UserLoginResponse } from "../types/responses/UserLoginResponse";

import { authRequest } from "../utils/API";

import { useAuth } from "./useAuth";

/**
 * Define the useRefresh Hook
 * @returns useRefresh Hook
 */
export const useRefresh = () => {

    // Define relevant data
    const { setAuth } = useAuth();
    const location = useLocation();

    // Create refresh function
    const refreshAccess = async () : Promise<string | null | void> => {

        // Get new access token
        const response : AxiosResponse<UserLoginResponse> | void = await authRequest.get( `/refresh/${ localStorage.getItem( "VB_USER" ) }` ).catch( () => {} );

        // Redirect to login when expired
        if ( ! response ) {
            localStorage.setItem( "VB_REDIRECT", location.pathname );
            return window.location.replace( "/login" );
        }

        // Store auth details
        localStorage.setItem( "VB_USER", response.data.id );
        localStorage.setItem( "VB_ACCESS", response.data.accessToken );
        setAuth( response.data );

        // Return updated access token
        return response.data.accessToken;

    };

    // Return the refresh function
    return refreshAccess;

};
