/**
 * StyledTextField Component
 *
 * /custom/StyledTextField/StyledTextField.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { alpha, styled, TextField, TextFieldProps, inputBaseClasses } from "@mui/material";

import { theme } from "../../theme/theme";

/**
 * Define the StyledTextField Component
 * @param props The StyledTextField props
 * @returns StyledTextField Component
 */
export const StyledTextField = styled( ( props : TextFieldProps ) => {

    return <TextField variant="outlined" color="secondary" size="small" fullWidth { ... props } />;

} )( () => ( {

    [ `& .${ inputBaseClasses.root }` ]: {
        backgroundColor: alpha( theme.palette.background.paper, 0.1 ),
        borderRadius: "5px",
        color: theme.palette.grey.A100,
        overflow: "hidden"
    },

    "& fieldset": {
        border: `1px solid ${ alpha( theme.palette.grey[ 300 ], 0.2 ) }`
    }

} ) );
