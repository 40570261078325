/**
 * PremiumPage
 *
 * /pages/PremiumPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Link, Divider } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";

import { Section } from "../../layout/Section/Section";

import { Hero } from "../../components/Hero/Hero";
import { PricingCard } from "../../components/PricingCard/PricingCard";
import { FeatureList } from "../../components/FeatureList/FeatureList";

import { SettingTitle } from "../../font/SettingTitle";

import { theme } from "../../theme/theme";

/**
 * Define the PremiumPage Component
 * @returns PremiumPage Component
 */
export const PremiumPage = () => {

    // Display the page
    return (
        <Grid container>

            {/* Display page hero */}
            <Grid item xs={ 12 }>
                <Section>
                    <Hero title="Premium Channels for Discord" kicker="VeeBot"
                        subtitle="Enhance your VeeBot experience with Premium. Let your community do more, with additional systems, features, and settings; all packed into a simple and affordable subscription!"
                        buttons={ [
                            <Link to={ "/purchase" } component={ RouterLink } underline="none">
                                <Button color="warning" variant="outlined" startIcon={ <DiamondIcon /> }>Get Premium</Button>
                            </Link>
                        ] } />
                    <Divider />
                    <Grid container spacing={ 4 }>
                        <PricingCard title="Monthly" months={ 1 } price={ 5 } discount={ 0 } />
                        <PricingCard title="Quarterly" months={ 3 } price={ 5 } discount={ 20 } />
                        <PricingCard title="Biannually" months={ 6 } price={ 5 } discount={ 50 } />
                        <Grid item xs={ 12 }>
                            <SettingTitle textAlign="center">Premium is not required to use VeeBot.</SettingTitle>
                            <SettingTitle textAlign="center">Premium features are currently free for all until further notice.</SettingTitle>
                        </Grid>
                    </Grid>
                </Section>
            </Grid>

            {/* Display feature comparison */}
            <Grid item xs={ 12 }>
                <Section colour={ theme.palette.divider }>
                    <FeatureList />
                    <SettingTitle textAlign="center">Premium is not required to use VeeBot.</SettingTitle>
                </Section>
            </Grid>

        </Grid>
    );

};
