/**
 * MissingPage
 *
 * /pages/MissingPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, Link, Button } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";

import { Section } from "../../layout/Section/Section";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";

/**
 * Define the MissingPage Component
 * @returns MissingPage Component
 */
export const MissingPage = () => {

    // Display the page
    return (
        <Grid container>
            <Grid item xs={ 12 }>
                <Section>
                    <Box display="flex" minHeight="20vh">

                        <ErrorCircle title="Page Not Found" description="The page you are looking for was moved, removed, renamed, or might never have existed!">
                            <Link to={ "/" } component={ RouterLink } underline="none">
                                <Button color="secondary" variant="outlined" startIcon={ <ReplyIcon /> }>Return Home</Button>
                            </Link>
                        </ErrorCircle>

                    </Box>
                </Section>
            </Grid>
        </Grid>
    );

};
