/**
 * FeatureCard Component
 *
 * /components/FeatureCard/FeatureCard.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Grid, Avatar } from "@mui/material";
import { ReactElement } from "react";

import { SubHeading } from "../../font/SubHeading";
import { SubTitle } from "../../font/SubTitle";

type FeatureCardProps = {
    color : string;
    icon : ReactElement;
    title : string;
    description : string;
};

/**
 * Define the FeatureCard Component
 * @param props The FeatureCard props
 * @returns FeatureCard Component
 */
export const FeatureCard = ( props : FeatureCardProps ) => {

    // Display the component
    return (
        <Grid item sm={ 4 } xs={ 12 }>
            <Grid container spacing={ 1 }>

                {/* Display feature icon */}
                <Grid item sm={ 12 } xs={ false }>
                    <Avatar variant="rounded" sx={{ backgroundColor: props.color }}>
                        { props.icon }
                    </Avatar>
                </Grid>

                {/* Display feature text */}
                <Grid item sm={ 12 } xs={ true }>
                    <SubHeading>{ props.title }</SubHeading>
                    <SubTitle>{ props.description }</SubTitle>
                </Grid>

            </Grid>
        </Grid>
    );

};
