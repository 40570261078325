/**
 * ServerListPage
 *
 * /pages/ConfigurePage/ServerListPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Grid } from "@mui/material";

import { useServers } from "../../hooks/useServers";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { ServerList } from "../../components/ServerList/ServerList";

/**
 * Define the ServerListPage Component
 * @returns ServerListPage Component
 */
export const ServerListPage = () => {

    // Get user servers
    const { servers } = useServers();

    // Display the page
    return (
        <Grid container>
            <Grid item xs={ 12 }>
                {
                    servers && Object.keys( servers ).length > 0
                        ? <ServerList link="/configure" servers={ servers } />
                        : <ErrorCircle title="No servers available." />
                }
            </Grid>
        </Grid>
    );

};
