/**
 * Root File
 * For displaying the website
 *
 * /App.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import { theme } from "./theme/theme";

import { RequireAddress } from "./providers/RequireAddress";
import { RequireAuth } from "./providers/RequireAuth";
import { RequireServers } from "./providers/RequireServers";
import { RequireServer } from "./providers/RequireServer";
import { RequireRedirect } from "./providers/RequireRedirect";

import { Header } from "./layout/Header/Header";
import { Footer } from "./layout/Footer/Footer";

import { ErrorCircle } from "./components/ErrorCircle/ErrorCircle";

import { HomePage } from "./pages/HomePage/HomePage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { LogoutPage } from "./pages/LogoutPage/LogoutPage";
import { PremiumPage } from "./pages/PremiumPage/PremiumPage";
import { ConfigurePage } from "./pages/ConfigurePage/ConfigurePage";
import { ServerListPage } from "./pages/ConfigurePage/ServerListPage";
import { CreateSystemPage } from "./pages/ConfigurePage/CreateSystemPage";
import { SystemBasicsPage } from "./pages/ConfigurePage/SystemBasicsPage";
import { SystemDefaultsPage } from "./pages/ConfigurePage/SystemDefaultsPage";
import { SystemCommandsPage } from "./pages/ConfigurePage/SystemCommandsPage";
import { SystemBehaviourPage } from "./pages/ConfigurePage/SystemBehaviourPage";
import { SystemPersistencePage } from "./pages/ConfigurePage/SystemPersistencePage";
import { SystemPermissionsPage } from "./pages/ConfigurePage/SystemPermissionsPage";
import { MissingPage } from "./pages/MissingPage/MissingPage";
import { FontsPage } from "./pages/FontsPage/FontsPage";

/**
 * Define the App Component
 * @returns App Component
 */
export const App = () => {

    // Define relevant data
    const headerHeight = 80;
    const footerHeight = 200;

    // Display the component
    return (
        <BrowserRouter>
            <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: "100vh", minWidth: "300px", overflowX: "hidden" }}>
                <Grid container>

                    <Grid item xs={ 12 } position="relative">
                        <Header height={ headerHeight } />
                        <Box sx={{ background: `linear-gradient( 180deg, ${ theme.palette.background.paper }, ${ theme.palette.background.default } )`, position: "absolute" }}
                            height="30vh" width="100%" zIndex={ 0 } />
                    </Grid>

                    <Grid item xs={ 12 } sx={{ minHeight: `calc(100vh - ${ headerHeight + footerHeight }px)` }} zIndex={ 1 }>
                        <Routes>
                            <Route element={ <RequireAddress /> }>
                                <Route index element={ <HomePage /> } />
                                <Route path="/login" element={ <LoginPage /> } />
                                <Route path="/logout" element={ <LogoutPage /> } />
                                <Route path="/premium" element={ <PremiumPage /> } />
                                <Route element={ <RequireRedirect /> }>
                                    <Route element={ <RequireAuth /> }>
                                        <Route element={ <RequireServers /> } >
                                            <Route path="/configure" element={ <ServerListPage /> } />
                                            <Route element={ <RequireServer /> }>
                                                <Route path="/configure/:serverId" element={ <ConfigurePage /> }>
                                                    <Route index element={ <ErrorCircle title="Please select a setting group..." /> } />
                                                    <Route path="/configure/:serverId/global-settings" element={ <ErrorCircle title="Settings coming soon..." /> } />
                                                    <Route path="/configure/:serverId/create-new-system" element={ <CreateSystemPage /> } />
                                                    <Route path="/configure/:serverId/system/:systemNumber/basics" element={ <SystemBasicsPage /> } />
                                                    <Route path="/configure/:serverId/system/:systemNumber/defaults" element={ <SystemDefaultsPage /> } />
                                                    <Route path="/configure/:serverId/system/:systemNumber/behaviour" element={ <SystemBehaviourPage /> } />
                                                    <Route path="/configure/:serverId/system/:systemNumber/commands" element={ <SystemCommandsPage /> } />
                                                    <Route path="/configure/:serverId/system/:systemNumber/persistence" element={ <SystemPersistencePage /> } />
                                                    <Route path="/configure/:serverId/system/:systemNumber/permissions" element={ <SystemPermissionsPage /> } />
                                                    <Route path="/configure/:serverId/system/:systemNumber/management" element={ <ErrorCircle title="Settings coming soon..." /> } />
                                                    <Route path="/configure/:serverId/*" element={ <ErrorCircle title="Invalid setting group..." /> } />
                                                </Route>
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="/fonts" element={ <FontsPage /> } />
                                <Route path="*" element={ <MissingPage /> } />
                            </Route>
                        </Routes>
                    </Grid>

                    <Grid item xs={ 12 }>
                        <Footer height={ footerHeight } />
                    </Grid>

                </Grid>
            </Box>
        </BrowserRouter>
    );

};
