/**
 * StyledTableCell Component
 *
 * /custom/StyledTableCell/StyledTableCell.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, TableCell, TableCellProps } from "@mui/material";

/**
 * Define the StyledTableCell Component
 * @param props The StyledTableCell props
 * @returns StyledTableCell Component
 */
export const StyledTableCell = styled( ( props : TableCellProps ) => {

    return <TableCell size="small" { ... props } />;

} )( () => ( {

    border: 0,
    height: "30px"

} ) );
