/**
 * StyledMenuItem Component
 *
 * /custom/StyledMenuItem/StyledMenuItem.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { alpha, styled, MenuItem, MenuItemProps, menuItemClasses } from "@mui/material";

import { theme } from "../../theme/theme";

/**
 * Define the StyledMenuItem Component
 * @param props The StyledMenuItem props
 * @returns StyledMenuItem Component
 */
export const StyledMenuItem = styled( ( props : MenuItemProps ) => {

    return <MenuItem dense color="secondary" { ... props } />;

} )( () => ( {

    [ `&.${ menuItemClasses.selected }` ]: {
        backgroundColor: `${ alpha( theme.palette.secondary.main, 0.2 ) } !important`
    },

    "&.:hover": {
        backgroundColor: `${ alpha( theme.palette.secondary.main, 0.2 ) } !important`
    }

} ) );
