/**
 * StyledTreeItem Component
 *
 * /custom/StyledTreeItem/StyledTreeItem.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled } from "@mui/material";
import { TreeItem, TreeItemProps, treeItemClasses } from "@mui/lab";

import { theme } from "../../theme/theme";

/**
 * Define the StyledTreeItem Component
 * @param props The StyledTreeItem props
 * @returns StyledTreeItem Component
 */
export const StyledTreeItem = styled( ( props : TreeItemProps ) => {

    return <TreeItem { ... props } />;

} )( {

    color: theme.palette.text.secondary,
    borderRadius: "5px 0 0 5px",
    overflow: "hidden",
    marginTop: "5px",
    transition: "200ms !important",

    "&.active": {
        backgroundColor: `${ theme.palette.divider } !important`,
        color: theme.palette.text.primary,
        [ `& .${ treeItemClasses.label }` ]: {
            fontWeight: theme.typography.fontWeightRegular
        }
    },

    [ `& .${ treeItemClasses.selected }, & .${ treeItemClasses.focused }` ]: {
        backgroundColor: "inherit !important"
    },

    [ `& .${ treeItemClasses.expanded }` ]: {
        borderRadius: "5px 0 0 5px",
        color: theme.palette.text.primary,
        transition: "200ms",
        [ `& .${ treeItemClasses.label }` ]: {
            fontWeight: theme.typography.fontWeightRegular
        }
    },

    [ `& .${ treeItemClasses.group }` ]: {},

    [ `& .${ treeItemClasses.content }` ]: {
        backgroundColor: "inherit",
        padding: `${ theme.spacing( 1 ) } 0`,
        width: "100%",
        "&:hover": {
            backgroundColor: `${ theme.palette.divider } !important`
        }
    },

    [ `& .${ treeItemClasses.group }` ]: {
        marginLeft: 0,
        transition: "200ms",
        [ `& .${ treeItemClasses.content }` ]: {
            paddingLeft: theme.spacing( 2 ),
            width: `calc( 100% - ${ theme.spacing( 2 ) } )`
        }
    },

    [ `& .${ treeItemClasses.iconContainer }` ]: {
        padding: `0 ${ theme.spacing( 2 ) }`
    },

    [ `& .${ treeItemClasses.label }` ]: {
        fontWeight: theme.typography.fontWeightLight,
        marginRight: theme.spacing( 2 )
    }

} );
