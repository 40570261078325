/**
 * ServerMenu Component
 *
 * /components/ServerMenu/ServerMenu.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { TreeView } from "@mui/lab";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PublicIcon from "@mui/icons-material/Public";
import AddIcon from "@mui/icons-material/Add";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import TuneIcon from "@mui/icons-material/Tune";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SaveIcon from "@mui/icons-material/Save";

import { ServerMenuItem } from "./ServerMenuItem";

import { StyledTreeItem } from "../../custom/StyledTreeItem/StyledTreeItem";

import { UserLoginResponse } from "../../types/responses/UserLoginResponse";
import { ServerSettingsResponse } from "../../types/responses/ServerSettingsResponse";

import { BodyText } from "../../font/BodyText";

type ServerMenuProps = {
    server : ServerSettingsResponse;
    auth : UserLoginResponse;
    width : string;
};

/**
 * Define the ServerMenu Component
 * @param props The ServerMenu props
 * @returns ServerMenu Component
 */
export const ServerMenu = ( props : ServerMenuProps ) => {

    // Define relevant data
    const location = useLocation();
    const [ expanded, setExpanded ] = useState<string[]>( [] );
    const [ systemButtons, setSystemButtons ] = useState<JSX.Element[]>( [] );
    const systemCount = Math.min( props.server.systemCount || 0, props.server.maxSystems || 0 );
    const locationMatch = new RegExp( /\/system\/(\d+)/, "g" ).exec( location.pathname );
    const activeSystem = locationMatch ? locationMatch[ 1 ] : null;

    // Create system buttons
    // Update system buttons on location change
    useEffect( () => {

        // Define relevant data
        let startingIndex = 1;
        const settingMenuItemCount = 7;

        // Set the buttons based on the system count
        setSystemButtons( [ ... Array( systemCount ) ].map( ( value, index ) => {

            // Define button index values
            const endingIndex = startingIndex + settingMenuItemCount;
            startingIndex = endingIndex + 1;

            // Define which system group is open
            if ( activeSystem && activeSystem === `${ index + 1 }` ) {
                setExpanded( [ `${ endingIndex }` ] );
            }

            // Return the system setting buttons
            return <Box key={ `${ index }` } >
                <StyledTreeItem nodeId={ `${ endingIndex }` } label={ <BodyText color="inherit !important">{ `System #${ index + 1 } : ${ props.server.settings.systems[ `${ index + 1 }` ]?.systemName ?? "n/a" }` }</BodyText> }>
                    <ServerMenuItem index={ endingIndex - 1 } label="System Basics" link={ `/configure/${ props.server.id }/system/${ index + 1 }/basics` } icon={ <RoomPreferencesIcon /> } />
                    <ServerMenuItem index={ endingIndex - 2 } label="System Defaults" link={ `/configure/${ props.server.id }/system/${ index + 1 }/defaults` } icon={ <TuneIcon /> } />
                    <ServerMenuItem index={ endingIndex - 3 } label="System Behaviour" link={ `/configure/${ props.server.id }/system/${ index + 1 }/behaviour` } icon={ <PsychologyIcon /> } />
                    <ServerMenuItem index={ endingIndex - 4 } label="System Commands" link={ `/configure/${ props.server.id }/system/${ index + 1 }/commands` } icon={ <SmartButtonIcon /> } />
                    <ServerMenuItem index={ endingIndex - 5 } label="System Persistence" link={ `/configure/${ props.server.id }/system/${ index + 1 }/persistence` } icon={ <SaveIcon /> } />
                    <ServerMenuItem index={ endingIndex - 6 } label="System Permissions" link={ `/configure/${ props.server.id }/system/${ index + 1 }/permissions` } icon={ <PeopleIcon /> } />
                    <ServerMenuItem index={ endingIndex - 7 } label="System Management" link={ `/configure/${ props.server.id }/system/${ index + 1 }/management` } icon={ <AdminPanelSettingsIcon /> } />
                </StyledTreeItem>
            </Box>;
        } ) );

    }, [ location ] );

    // Display the component
    return (
        <TreeView defaultExpandIcon={ <ArrowRightIcon /> } defaultCollapseIcon={ <ArrowDropDownIcon /> } expanded={ expanded } onNodeToggle={ ( _, nodeIds ) => { setExpanded( nodeIds ); } }
            sx={{ width: props.width }} >

            {/* Display server setting button */}
            <ServerMenuItem index={ - 1 } label="Global Settings" link={ `/configure/${ props.server.id }/global-settings` } icon={ <PublicIcon /> } />

            {/* Display system setting buttons */}
            { systemButtons }

            {/* Display new system button */}
            <ServerMenuItem index={ 0 } label="Create New System" link={ `/configure/${ props.server.id }/create-new-system` } icon={ <AddIcon /> } />

        </TreeView>
    );

};
