/**
 * LoginPage
 *
 * /pages/LoginPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useState } from "react";
import { useLocation } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { v4 } from "uuid";

import { useAuth } from "../../hooks/useAuth";

import { UserLoginResponse } from "../../types/responses/UserLoginResponse";
import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";

import { authRequest } from "../../utils/API";
import { getLoginUrl } from "../../utils/Links";

import { Section } from "../../layout/Section/Section";

import { LoadingCircle } from "../../components/LoadingCircle/LoadingCircle";
import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";

/**
 * Define the LoginPage Component
 * @returns LoginPage Component
 */
export const LoginPage = () => {

    // Define relevant data
    const { setAuth } = useAuth();
    const [ verified, setVerified ] = useState( false );
    const [ error, setError ] = useState( "" );
    const location = useLocation();
    const redirect = localStorage.getItem( "VB_REDIRECT" );
    const query = Object.fromEntries( new URLSearchParams( location.search ) );

    // Verify error
    if ( error ) {
        return <Section><ErrorCircle title={ error } /></Section>;
    }

    // Redirect after login
    if ( verified ) {
        window.location.replace( redirect || "/" );
        return <Section><LoadingCircle title="Redirecting..." /></Section>;
    }

    // Verify user error
    if ( query.error ) {
        setError( "Cancelled login" );
        return <Section><LoadingCircle title="Something went wrong..." /></Section>;
    }

    // Redirect to login
    else if ( ! query.code || ! query.state ) {
        localStorage.setItem( "VB_STATE", v4() );
        window.location.replace( getLoginUrl( window.location.origin ) );
        return <Section><LoadingCircle title="Redirecting to Discord..." /></Section>;
    }

    // Verify returned state
    else if ( query.state === localStorage.getItem( "VB_STATE" ) ) {

        // Make login request
        authRequest.post( "/login", {
            code: query.code
        } ).then( ( loginResponse : AxiosResponse<UserLoginResponse> ) => {
            localStorage.setItem( "VB_USER", loginResponse.data.id );
            localStorage.setItem( "VB_ACCESS", loginResponse.data.accessToken );
            setAuth( loginResponse.data );
            setVerified( true );
        } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
            const errorContent = e.response?.data;
            setError( errorContent?.message || "Failed to create login." );
        } );
        return <Section><LoadingCircle title="Creating login..." /></Section>;

    }

    // Invalid login state
    setError( "Invalid login state." );
    return <Section><LoadingCircle title="Something went wrong..." /></Section>;

};
