/**
 * SettingWrapper Component
 *
 * /components/SettingWrapper/SettingWrapper.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Box, Grid, useMediaQuery, Stack } from "@mui/material";

import { SettingCaption } from "../../font/SettingCaption";
import { SettingTitle } from "../../font/SettingTitle";

import { SettingDescription } from "../SettingDescription/SettingDescription";

import { theme } from "../../theme/theme";

type SettingWrapperProps = {
    title : string;
    description : string;
    metaData : string[];
    children : JSX.Element | JSX.Element[];
    error ?: string;
};

/**
 * Define the SettingWrapper Component
 * @param props The SettingWrapper props
 * @returns SettingWrapper Component
 */
export const SettingWrapper = ( props : SettingWrapperProps ) => {

    // Define relevant data
    const isNarrow = useMediaQuery( theme.breakpoints.down( "sm" ) );
    const horizontalSpacing = 2;

    // Display the component
    return (
        <Grid container padding={ `${ theme.spacing( 2 ) } 0` }>

            {/* Display the title */}
            {/* Adjust grid style and width based on screen width */}
            <Grid item xs={ isNarrow ? 12 : false } style={{ width: isNarrow ? "auto" : "180px" }}>
                <Box>

                    {/* Align text based on screen width */}
                    <SettingTitle textAlign={ isNarrow ? "left" : "right" }
                        padding={ `0 0 ${ isNarrow ? "3px" : 0 } ${ isNarrow ? theme.spacing( horizontalSpacing ) : theme.spacing( horizontalSpacing + 1 ) }` }>
                        { props.title }
                    </SettingTitle>

                </Box>
            </Grid>

            {/* Display the setting section */}
            <Grid item xs={ isNarrow ? 12 : true }>
                <Box sx={{ padding: `0 ${ theme.spacing( horizontalSpacing ) }`, width: `calc( 100% - ${ theme.spacing( horizontalSpacing * 2 ) })` }}>
                    <Stack>

                        {/* Display input area */}
                        { props.children }

                        {/* Display setting description */}
                        <SettingDescription description={ props.description } metaData={ props.metaData } />

                        {/* Display setting error */}
                        <SettingCaption color={ `${ theme.palette.error.main } !important` }>
                            { props.error }
                        </SettingCaption>

                    </Stack>
                </Box>
            </Grid>

        </Grid>
    );

};
