/**
 * HeaderDrawer Component
 *
 * /components/Header/HeaderDrawer.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { NavLink } from "react-router-dom";
import { Drawer, SvgIcon, Link, Container, Toolbar, Stack, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ReactComponent as VeeBotLogo } from "../../assets/VeeBot.svg";

import { HeaderNavigation } from "./HeaderNavigation";

import { StyledBackdrop } from "../../custom/StyledBackdrop/StyledBackdrop";

import { theme } from "../../theme/theme";

type HeaderDrawerProps = {
    anchor : "bottom" | "left" | "top" | "right";
    drawerOpen : boolean;
    setDrawerOpen : ( value : boolean ) => void;
};

/**
 * Define the HeaderDrawer Component
 * @param props The HeaderDrawer props
 * @returns HeaderDrawer Component
 */
export const HeaderDrawer = ( props : HeaderDrawerProps ) => {

    // Define relevant data
    const height = 80;

    // Define close function
    const closeDrawer = () => {
        props.setDrawerOpen( false );
    };

    // Display the component
    return (

        <Drawer anchor={ props.anchor } open={ props.drawerOpen } onClose={ closeDrawer } elevation={ 0 } components={{ Backdrop: StyledBackdrop }}>

            {/* Display drawer header */}
            <Container>
                <Toolbar sx={{ height: `${ height }px` }} disableGutters>

                    {/* Display veebot logo */}
                    <Stack direction="row" sx={{ flexGrow: 1 }}>
                        <Link to={ "/" } component={ NavLink } style={{ height: height / 2, width: height / 2 }} onClick={ closeDrawer }>
                            <SvgIcon sx={{ color: theme.palette.text.primary, height: height / 2, width: height / 2 }} component={ VeeBotLogo } inheritViewBox />
                        </Link>
                    </Stack>

                    {/* Display close button */}
                    <Stack direction="row-reverse" sx={{ flexGrow: 1 }}>
                        <IconButton color="secondary" onClick={ closeDrawer }>
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                </Toolbar>
            </Container>

            {/* Display drawer navigation */}
            <HeaderNavigation setDrawerOpen={ props.setDrawerOpen } vertical />

        </Drawer>

    );

};
