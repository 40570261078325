/**
 * FeatureList Component
 *
 * /components/FeatureList/FeatureList.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { TableContainer, Table, TableHead, TableRow, Avatar, TableBody, alpha } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DiamondIcon from "@mui/icons-material/Diamond";

import { BodyText } from "../../font/BodyText";
import { SubTitle } from "../../font/SubTitle";
import { KickerText } from "../../font/KickerText";

import { StyledTableCell } from "../../custom/StyledTableCell/StyledTableCell";

import { settingsConfig } from "../../config/settings.config";

import { theme } from "../../theme/theme";

/**
 * Define the FeatureList Component
 * @returns FeatureList Component
 */
export const FeatureList = () => {

    // Display the component
    return (
        <TableContainer sx={{ "& *": { transition: "200ms" } }}>
            <Table sx={{ overflowX: "auto" }}>
                <TableHead>

                    {/* Display table icons */}
                    <TableRow>

                        <StyledTableCell align="left">
                            <KickerText color={ `${ theme.palette.text.primary } !important` } padding={ `${ theme.spacing( 2 ) } 0` }>Features</KickerText>
                        </StyledTableCell>

                        <StyledTableCell align="center" sx={{ width: "180px" }}>
                            <Avatar variant="rounded" sx={{ backgroundColor: theme.palette.grey[ 300 ], margin: "0 auto" }}>
                                <NotInterestedIcon />
                            </Avatar>
                        </StyledTableCell>

                        <StyledTableCell align="center" sx={{ width: "180px" }}>
                            <Avatar variant="rounded" sx={{ backgroundColor: theme.palette.warning.main, margin: "0 auto" }}>
                                <DiamondIcon />
                            </Avatar>
                        </StyledTableCell>

                    </TableRow>

                    {/* Display table header */}
                    <TableRow>

                        <StyledTableCell align="left"></StyledTableCell>

                        <StyledTableCell align="center" sx={{ backgroundColor: alpha( theme.palette.background.paper, 0.2 ), width: "180px" }}>
                            <SubTitle color={ `${ theme.palette.text.primary } !important` }>Free</SubTitle>
                        </StyledTableCell>

                        <StyledTableCell align="center" sx={{ backgroundColor: alpha( theme.palette.primary.main, 0.2 ), width: "180px" }}>
                            <SubTitle color={ `${ theme.palette.text.primary } !important` } >Premium</SubTitle>
                        </StyledTableCell>

                    </TableRow>

                </TableHead>

                {/* Display setting group */}
                { Object.values( settingsConfig ).map( ( group ) => {
                    return <TableBody>

                        <TableRow sx={{ backgroundColor: theme.palette.background.default }} >

                            <StyledTableCell align="left">
                                <SubTitle color={ `${ theme.palette.text.primary } !important` }>{ group.name }</SubTitle>
                            </StyledTableCell>

                            <StyledTableCell sx={{ width: "180px" }}></StyledTableCell>

                            <StyledTableCell sx={{ width: "180px" }}></StyledTableCell>

                        </TableRow>

                        {/* Display group settings */}
                        { Object.values( group.settings ).filter( ( entry ) => { return entry.isDisplayed; } ).map( ( entry ) => {
                            return <TableRow sx={{ backgroundColor: alpha( theme.palette.background.paper, 0.1 ) }} hover>

                                <StyledTableCell align="left">
                                    <BodyText>{ entry.feature }</BodyText>
                                </StyledTableCell>

                                <StyledTableCell align="center" sx={{ backgroundColor: alpha( theme.palette.background.paper, 0.1 ), width: "180px" }}>
                                    { entry.displayValue
                                        ? <SubTitle>{ entry.default.free.toString() }</SubTitle>
                                        : entry.isPremium
                                            ? <CancelIcon fontSize="small" color="disabled" sx={{ paddingTop: "5px" }} />
                                            : <CheckCircleIcon fontSize="small" color="success" sx={{ paddingTop: "5px" }} /> }
                                </StyledTableCell>

                                <StyledTableCell align="center" sx={{ backgroundColor: alpha( theme.palette.primary.main, 0.1 ), width: "180px" }}>
                                    { entry.displayValue
                                        ? <SubTitle>{ entry.default.premium.toString() }</SubTitle>
                                        : <CheckCircleIcon fontSize="small" color="success" sx={{ paddingTop: "5px" }} /> }
                                </StyledTableCell>

                            </TableRow>;
                        } ) }

                    </TableBody>;
                } ) }
            </Table>
        </TableContainer>
    );

};
