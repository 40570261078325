/**
 * EditFixedPermissions Component
 *
 * /components/EditFixedPermissions/EditFixedPermissions.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import EditIcon from "@mui/icons-material/Edit";

import { useRequest } from "../../hooks/useRequest";
import { useServer } from "../../hooks/useServer";

import { PermissionsGroupName } from "../../types/names/PermissionsGroupName";
import { FormattedPermissionsMap } from "../../types/formatted/FormattedPermissionsMap";
import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";

import { SettingWrapper } from "../SettingWrapper/SettingWrapper";
import { PermissionsDialog } from "../PermissionsDialog/PermissionsDialog";
import { ErrorCircle } from "../ErrorCircle/ErrorCircle";

type EditFixedPermissionsProps = {
    title : string;
    name : PermissionsGroupName;
    description : string;
    metaData : string[];
    permsValue : FormattedPermissionsMap;
};

/**
 * Define the EditFixedPermissions Component
 * @param props The EditFixedPermissions props
 * @returns EditFixedPermissions Component
 */
export const EditFixedPermissions = ( props : EditFixedPermissionsProps ) => {

    // Define relevant data
    const request = useRequest();
    const { systemNumber } = useParams();
    const { server, setServer } = useServer();
    const [ updating, setUpdating ] = useState( false );
    const [ success, setSuccess ] = useState( false );
    const [ error, setError ] = useState( "" );
    const [ isPermsOpen, setPermsOpen ] = useState( false );
    const [ permsValue, setPermsValue ] = useState( props.permsValue );
    const isMounted = useRef( false );

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    const systemSettings = systemCheck;

    // Handle value change
    useEffect( () => {

        // Verify component is mounted
        if ( isMounted.current ) {
            setError( "" );
            setUpdating( true );

            // Send values to server
            request.post( `/server/${ server.id }/system/${ systemNumber }/permissions/${ props.name }`, permsValue ).then( () => {
                systemSettings[ props.name ] = permsValue;
                server.settings.systems[ systemNumber ] = systemSettings;
                setServer( server );
                setUpdating( false );
                setSuccess( true );
            } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
                setUpdating( false );
                setSuccess( false );
                setError( e.response?.data?.message || "Failed to update system." );
            } );
        }

        // Set component as mounted
        else {
            isMounted.current = true;
        }

    }, [ permsValue ] );

    // Display the component
    return (
        <SettingWrapper title={ props.title } description={ props.description } metaData={ props.metaData } error={ error }>

            <ButtonGroup variant="outlined" size="small" disableElevation>

                {/* Display edit button */}
                <Button onClick={ () => { setPermsOpen( true ); } }>
                    <EditIcon />
                </Button>

            </ButtonGroup>

            {/* Display permissions dialog */}
            <PermissionsDialog
                { ... props }
                success={ success }
                setSuccess={ setSuccess }
                isPermsOpen={ isPermsOpen }
                setPermsOpen={ setPermsOpen }
                permsValue={ permsValue }
                setPermsValue={ setPermsValue }
                updating={ updating }
                error={ error } />

        </SettingWrapper>
    );

};
