/**
 * RequireAddress Component
 *
 * /providers/RequireAddress.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useLocation, Outlet } from "react-router-dom";

import { globalConfig } from "../config/global.config";

/**
 * Define the RequireAddress Component
 * @returns RequireAddress Component
 */
export const RequireAddress = () => {

    // Define relevant data
    const location = useLocation();

    // Force website url
    if ( window.location.origin !== globalConfig.websiteUrl ) {
        window.location.href = `${ globalConfig.websiteUrl }${ location.pathname ?? "" } ${ location.search ?? "" }`;
    }

    // Display the component
    return <Outlet />;

};
