/**
 * useRequest Hook
 * For handling user requests
 *
 * /hooks/useRequest.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useEffect } from "react";
import { AxiosRequestHeaders } from "axios";

import { serverRequest } from "../utils/API";

import { useRefresh } from "./useRefresh";
import { useAuth } from "./useAuth";

/**
 * Define the useRequest Hook
 * @returns useRequest Hook
 */
export const useRequest = () => {

    // Define relevant data
    const refreshAccess = useRefresh();
    const { auth } = useAuth();

    // Create request hooks
    useEffect( () => {

        // Define request interceptor
        // Verify authorization header
        const requestIntercept = serverRequest.interceptors.request.use(
            ( config ) => {
                if ( ! config.headers?.Authorization ) {
                    config.headers = config.headers || {} as AxiosRequestHeaders;
                    config.headers.Authorization = `Bearer ${ localStorage.getItem( "VB_ACCESS" ) }`;
                }
                return config;
            },
            ( error ) => {
                return Promise.reject( error );
            }
        );

        // Define response interceptor
        // Handle error responses
        const responseIntercept = serverRequest.interceptors.response.use(
            ( response ) => {
                return response;
            },

            async ( error ) => {
                const prevRequest = error?.config;
                if ( error?.response?.status === 403 && ! prevRequest?.sent ) {
                    prevRequest.sent = true;
                    const accessToken = await refreshAccess();
                    prevRequest.headers.Authorization = `Bearer ${ accessToken }`;
                    return serverRequest( prevRequest );
                }
                return Promise.reject( error );
            }

        );

        // Remove interceptors
        return () => {
            serverRequest.interceptors.request.eject( requestIntercept );
            serverRequest.interceptors.response.eject( responseIntercept );
        };

    }, [ auth, refreshAccess ] );

    // Return request hook
    return serverRequest;

};
