/**
 * BreadcrumbTrail Component
 *
 * /components/BreadcrumbTrail/BreadcrumbTrail.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Link as RouterLink, useLocation } from "react-router-dom";
import { Breadcrumbs, Grid, Link, Divider } from "@mui/material";

import { ButtonText } from "../../font/ButtonText";

import { theme } from "../../theme/theme";

/**
 * Define the BreadcrumbTrail Component
 * @returns BreadcrumbTrail Component
 */
export const BreadcrumbTrail = () => {

    // Define relevant data
    const location = useLocation();
    const locationStages = location.pathname.split( "/" );
    const currentLocation = locationStages.pop();

    // Create breadcrumb items
    let prevStage = "/";
    const breadcrumbLinks = locationStages.map( ( stage ) => {

        // Create breadcrumb link location
        const currentStage = `${ prevStage }/${ stage }`.replace( /\/{2,}/gm, "/" );
        prevStage = currentStage;

        // Retrun breadcrumb item
        return (
            <Link key={ currentStage } to={ currentStage } component={ RouterLink } underline="none" sx={{
                color: theme.palette.text.secondary, transition: "200ms", "&:hover": { color: theme.palette.text.primary } }}>
                <ButtonText>{ stage.replace( /-/gm, " " ) }</ButtonText>
            </Link>
        );

    } );

    // Display the component
    return (
        <Grid container spacing={ 1 }>

            {/* Display breadcrumbs */}
            <Grid item xs={ 12 }>
                <Breadcrumbs>

                    {/* Display breadcrumb hyperlinks */}
                    { breadcrumbLinks }

                    {/* Display current location */}
                    <ButtonText sx={{ color: theme.palette.text.primary }}>
                        { currentLocation?.replace( /-/gm, " " ) }
                    </ButtonText>

                </Breadcrumbs>
            </Grid>

            {/* Display breadcrumb underline */}
            <Grid item xs={ 12 }>
                <Divider />
            </Grid>

        </Grid>
    );

};
