/**
 * StyledTabs Component
 *
 * /custom/StyledTabs/StyledTabs.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Tabs, TabsProps, tabsClasses, tabClasses } from "@mui/material";

import { theme } from "../../theme/theme";

/**
 * Define the StyledTabs Component
 * @param props The StyledTabs props
 * @returns StyledTabs Component
 */
export const StyledTabs = styled( ( props : TabsProps ) => {

    return <Tabs { ... props } />;

} )( () => ( {

    opacity: 1,

    [ `& .${ tabsClasses.scrollButtons }.Mui-disabled` ]: {
        color: theme.palette.secondary.main,
        opacity: 0.2
    },

    [ `& .${ tabsClasses.indicator }` ]: {
        backgroundColor: theme.palette.common.white,
        opacity: 1
    },

    [ `& .${ tabClasses.root }` ]: {
        padding: `${ theme.spacing( 0 ) } ${ theme.spacing( 2 ) }`
    }

} ) );
