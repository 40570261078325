/**
 * System Defaults Schema
 *
 * /schemas/defaults.schema.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import * as yup from "yup";

import { settingsConfig } from "../config/settings.config";

import { FormattedSystemDefaults } from "../types/formatted/FormattedSystemDefaults";

/**
 * Define the Defaults Schema
 * @returns Defaults Schema
 */
export const DefaultsSchema = () => {
    return yup.object<Partial<Record<keyof FormattedSystemDefaults, yup.AnySchema>>>( {

        defaultChannelName: yup.string()
            .required( `A valid '${ settingsConfig.defaults.settings.defaultChannelName.title }' value is required.` )
            .typeError( "Must be a string." )
            .min( settingsConfig.defaults.settings.defaultChannelName.minLength, `Must be at least '${ settingsConfig.defaults.settings.defaultChannelName.minLength }' characters long.` )
            .max( settingsConfig.defaults.settings.defaultChannelName.maxLength, `Must be at most '${ settingsConfig.defaults.settings.defaultChannelName.maxLength }' characters long.` ),

        defaultChannelUserLimit: yup.number().integer()
            .typeError( "Must be a number." )
            .required( `A valid '${ settingsConfig.defaults.settings.defaultChannelUserLimit.title }' value is required.` )
            .min( settingsConfig.defaults.settings.defaultChannelUserLimit.minValue, `Must be '${ settingsConfig.defaults.settings.defaultChannelUserLimit.minValue }' or above.` )
            .max( settingsConfig.defaults.settings.defaultChannelUserLimit.maxValue, `Must be '${ settingsConfig.defaults.settings.defaultChannelUserLimit.maxValue }' or below.` ),

        defaultChannelBitrate: yup.number().integer()
            .typeError( "Must be a number." )
            .required( `A valid '${ settingsConfig.defaults.settings.defaultChannelBitrate.title }' value is required.` )
            .min( settingsConfig.defaults.settings.defaultChannelBitrate.minValue, `Must be '${ settingsConfig.defaults.settings.defaultChannelBitrate.minValue }' or above.` )
            .max( settingsConfig.defaults.settings.defaultChannelBitrate.maxValue, `Must be '${ settingsConfig.defaults.settings.defaultChannelBitrate.maxValue }' or below.` ),

        defaultChannelState: yup.boolean()
            .required( `A valid '${ settingsConfig.defaults.settings.defaultChannelState.title }' value is required.` )
            .typeError( "Must be a boolean." )

    } );
};
