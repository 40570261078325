/**
 * SettingBox Component
 *
 * /components/SettingBox/SettingBox.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { alpha, Box } from "@mui/material";

import { theme } from "../../theme/theme";

type SettingBoxProps = {
    children : JSX.Element | JSX.Element[];
};

/**
 * Define the SettingBox Component
 * @param props The SettingBox props
 * @returns SettingBox Component
 */
export const SettingBox = ( props : SettingBoxProps ) => {

    // Display the component
    return (
        <Box bgcolor={ alpha( theme.palette.background.paper, 0.1 ) } padding={ `${ theme.spacing( 1 ) } ${ theme.spacing( 2 ) }` }
            border={ `1px solid ${ alpha( theme.palette.grey[ 300 ], 0.2 ) }` } sx={{ "&:hover": { border: `1px solid ${ theme.palette.grey[ 50 ] }` } }} borderRadius="5px">

            {/* Display box contents */}
            { props.children }

        </Box>
    );

};
