/**
 * SettingTitle Component
 *
 * /font/SettingTitle.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Typography, TypographyProps } from "@mui/material";

import { theme } from "../theme/theme";

/**
 * Define the SettingTitle Component
 * @param props The SettingTitle props
 * @returns SettingTitle Component
 */
export const SettingTitle = styled( ( props : TypographyProps ) => {

    return <Typography variant="h6" { ... props } />;

} )( () => ( {

    color: theme.palette.text.primary

} ) );
