/**
 * SectionTitle Component
 *
 * /font/SectionTitle.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Typography, TypographyProps } from "@mui/material";

import { theme } from "../theme/theme";

/**
 * Define the SectionTitle Component
 * @param props The SectionTitle props
 * @returns SectionTitle Component
 */
export const SectionTitle = styled( ( props : TypographyProps ) => {

    return <Typography variant="overline" { ... props } />;

} )( () => ( {

    color: theme.palette.text.secondary

} ) );
