/**
 * ErrorCircle Component
 *
 * /components/ErrorCircle/ErrorCircle.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";

import { CircleWrapper } from "../CircleWrapper/CircleWrapper";

type ErrorCircleProps = {
    title ?: string;
    description ?: string;
    children ?: JSX.Element | JSX.Element[];
};

/**
 * Define the ErrorCircle Component
 * @param props The ErrorCircle props
 * @returns ErrorCircle Component
 */
export const ErrorCircle = ( props : ErrorCircleProps ) => {

    // Display the component
    return (
        <CircleWrapper title={ props.title } description={ props.description } children={ props.children } icon={
            <ErrorSharpIcon color="error" sx={{ height: "70px", width: "70px" }} />
        } />
    );

};
