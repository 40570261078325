/**
 * Colour Palette Definition
 *
 * /theme/palette.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { ColorPartial, PaletteOptions } from "@mui/material/styles/createPalette";

/**
 * Define grey colours
 */
export const greys : Required<ColorPartial> = {
    50: "#F2F1F3",
    100: "#DEDDE0",
    200: "#C8C7CC",
    300: "#B1B0B8",
    400: "#A19FA8",
    500: "#908E99",
    600: "#888691",
    700: "#7D7B86",
    800: "#73717C",
    900: "#615F6B",
    A100: "#CCD4E3",
    A200: "#AEB7CA",
    A400: "#79869F",
    A700: "#444F66"

};

/**
 * Define common colours
 */
export const common : PaletteOptions[ "common" ] = {
    black: "#000000",
    white: "#FFFFFF"
};

/**
 * Define the palette
 */
export const palette : PaletteOptions = {

    mode: "dark",
    contrastThreshold: 4.5,

    primary: {
        main: "#6347EB"
    },

    secondary: {
        main: greys[ 300 ]
    },

    error: {
        main: "#EB4747"
    },

    warning: {
        main: "#EBCF47"
    },

    success: {
        main: "#7EEB47"
    },

    info: {
        main: "#47B4EB"
    },

    grey: greys,

    common: common,

    background: {
        default: "#211F2E",
        paper: "#16141F"
    },

    text: {
        primary: common.white,
        secondary: greys[ 300 ],
        disabled: greys[ 900 ]
    },

    divider: "#2C293D"

};
