/**
 * RequireRedirect Component
 *
 * /components/RequireRedirect/RequireRedirect.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useLocation, Outlet } from "react-router-dom";

/**
 * Define the RequireRedirect Component
 * @returns RequireRedirect Component
 */
export const RequireRedirect = () => {

    // Set redirect path
    const location = useLocation();
    localStorage.setItem( "VB_REDIRECT", location.pathname );

    // Show contents
    return (
        <Outlet />
    );

};
