/**
 * Header Component
 *
 * /components/Header/Header.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, Toolbar, SvgIcon, Link, Button, Stack, useMediaQuery, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { theme } from "../../theme/theme";

import { ReactComponent as VeeBotLogo } from "../../assets/VeeBot.svg";

import { HeaderNavigation } from "./HeaderNavigation";
import { HeaderDrawer } from "./HeaderDrawer";

type HeaderProps = {
    height : number;
};

/**
 * Define the Header Component
 * @param props The Header props
 * @returns Header Component
 */
export const Header = ( props : HeaderProps ) => {

    // Define relevant data
    const accessToken = localStorage.getItem( "VB_ACCESS" );
    const isNarrow = useMediaQuery( theme.breakpoints.down( "lg" ) );
    const [ drawerOpen, setDrawerOpen ] = useState( false );

    // Display the component
    return (
        <Box sx={{ backgroundColor: theme.palette.background.paper, height: `${ props.height }px` }}>

            {/* Center header contents */}
            <Toolbar sx={{ height: `${ props.height }px` }}>

                {/* Display left side of header */}
                <Stack direction="row" spacing={ 2 }>

                    {/* Display veebot logo */}
                    <Link to={ "/" } component={ NavLink } style={{ height: props.height / 2, width: props.height / 2 }}>
                        <SvgIcon sx={{ color: theme.palette.text.primary, height: props.height / 2, width: props.height / 2 }} component={ VeeBotLogo } inheritViewBox />
                    </Link>

                </Stack>

                {/* Display center of header */}
                <Stack direction="row" spacing={ 2 } justifyContent={ isNarrow ? "left" : "center" } sx={{ flexGrow: 1, overflow: "hidden", padding: `0 ${ theme.spacing( 2 ) }` }}>

                    {/* Display drawer button */}
                    { isNarrow && <IconButton color="secondary" onClick={ () => { setDrawerOpen( true ); } }>
                        <MenuIcon />
                    </IconButton> }

                    {/* Display header navigation bar */}
                    { ! isNarrow && <HeaderNavigation setDrawerOpen={ setDrawerOpen } /> }

                </Stack>

                {/* Display right side of header */}
                <Stack direction="row-reverse" spacing={ 2 }>

                    {/* Display login/logout button */}
                    <Button color="primary" variant="contained" href= { accessToken ? "/logout" : "/login" }>
                        { accessToken ? "Logout" : "Login" }
                    </Button>

                </Stack>

            </Toolbar>

            <HeaderDrawer anchor="left" drawerOpen={ drawerOpen } setDrawerOpen={ setDrawerOpen } />

        </Box>
    );

};
