/**
 * StyledRadioGroup Component
 *
 * /custom/StyledRadioGroup/StyledRadioGroup.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, RadioGroup, RadioGroupProps } from "@mui/material";

/**
 * Define the StyledRadioGroup Component
 * @param props The StyledRadioGroup props
 * @returns StyledRadioGroup Component
 */
export const StyledRadioGroup = styled( ( props : RadioGroupProps ) => {

    return <RadioGroup row { ... props } />;

} )( () => ( {

} ) );
