/**
 * Footer Component
 *
 * /layout/Footer/Footer.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Box, Toolbar } from "@mui/material";

import { theme } from "../../theme/theme";

type FooterProps = {
    height : number;
};

/**
 * Define the Footer Component
 * @param props The Footer props
 * @returns Footer Component
 */
export const Footer = ( props : FooterProps ) => {

    // Display the component
    return (
        <Box sx={{ backgroundColor: theme.palette.background.paper, height: `${ props.height }px` }}>

            {/* Center footer contents */}
            <Toolbar sx={{ height: `${ props.height }px` }}></Toolbar>

        </Box>
    );

};
