/**
 * HeaderNavigation Component
 *
 * /components/Header/HeaderNavigation.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useState, ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SvgIcon } from "@mui/material";
import RoofingIcon from "@mui/icons-material/Roofing";
import SettingsIcon from "@mui/icons-material/Settings";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import StorageIcon from "@mui/icons-material/Storage";
import DiamondIcon from "@mui/icons-material/Diamond";

import { StyledTabs } from "../../custom/StyledTabs/StyledTabs";

import { theme } from "../../theme/theme";

import { HeaderButton } from "./HeaderButton";

type HeaderNavigationProps = {
    vertical ?: boolean;
    setDrawerOpen : ( value : boolean ) => void;
};

type HeaderButtonData = {
    text : string;
    icon : ReactElement;
    link : string;
    color : string;
}[];

/**
 * Define the HeaderNavigation Component
 * @param props The HeaderNavigation props
 * @returns HeaderNavigation Component
 */
export const HeaderNavigation = ( props : HeaderNavigationProps ) => {

    // Define navigation buttons
    const buttons : HeaderButtonData = [
        { text: "Home", link: "/", icon: <RoofingIcon />, color: theme.palette.text.primary },
        { text: "Configure", link: "/configure", icon: <SettingsIcon />, color: theme.palette.text.primary },
        { text: "Support", link: "/support", icon: <QuestionAnswerIcon />, color: theme.palette.text.primary },
        { text: "Status", link: "/status", icon: <StorageIcon />, color: theme.palette.text.primary },
        { text: "Premium", link: "/premium", icon: <DiamondIcon />, color: theme.palette.warning.main }
    ];

    // Define relevant data
    const location = useLocation();
    let activePath = `/${ location.pathname.split( "/" )[ 1 ] }`;
    let activeIndex = buttons.findIndex( ( element ) => { return element.link === activePath; } );
    const [ activeTab, setActiveTab ] = useState( activeIndex >= 0 ? activeIndex : 0 );

    // Update active navigation tab
    useEffect( () => {
        activePath = `/${ location.pathname.split( "/" )[ 1 ] }`;
        activeIndex = buttons.findIndex( ( element ) => { return element.link === activePath; } );
        if ( activeIndex >= 0 ) {
            setActiveTab( activeIndex );
        }
    }, [ location.pathname ] );

    // Display the component
    return (

        <StyledTabs sx={{ color: theme.palette.common.white }} value={ activeTab } variant="scrollable" indicatorColor="secondary"
            orientation={ props.vertical ? "vertical" : "horizontal" } scrollButtons allowScrollButtonsMobile>

            {/* Display navigation buttons */}
            { buttons.map( ( value, index ) => {
                return <HeaderButton key={ index } index={ index }
                    text={ value.text } icon={ <SvgIcon fontSize="small">{ value.icon }</SvgIcon> } link={ value.link }
                    activePath={ activePath } setActiveTab={ setActiveTab } setDrawerOpen={ props.setDrawerOpen }
                    color={ value.color } vertical={ props.vertical } />;
            } ) }

        </StyledTabs>

    );

};
