/**
 * RequireServer Component
 *
 * /providers/RequireServer.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import { useRequest } from "../hooks/useRequest";
import { useServer } from "../hooks/useServer";

import { ErrorMessageResponse } from "../types/responses/ErrorMessageResponse";
import { ServerSettingsResponse } from "../types/responses/ServerSettingsResponse";

import { Section } from "../layout/Section/Section";

import { ErrorCircle } from "../components/ErrorCircle/ErrorCircle";
import { LoadingCircle } from "../components/LoadingCircle/LoadingCircle";

/**
 * Define the RequireServer Component
 * @returns RequireServer Component
 */
export const RequireServer = () => {

    // Define relevant data
    const request = useRequest();
    const [ loaded, setLoaded ] = useState( false );
    const [ error, setError ] = useState( "" );
    const { setServer } = useServer();
    const { serverId } = useParams();

    // Get the selected server
    useEffect( () => {
        request.get( `/server/${ serverId }` ).then( ( settingsResponse : AxiosResponse<ServerSettingsResponse> ) => {
            setServer( settingsResponse.data );
            setLoaded( true );
        } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
            const errorContent = e.response?.data;
            setError( errorContent?.message || "Failed to load server." );
        } );
    }, [ serverId ] );

    // Display the component
    return (
        error
            ? <Section><ErrorCircle title={ error } /></Section>
            : loaded
                ? <Outlet />
                : <Section><LoadingCircle title="Loading server..."/></Section>
    );

};
