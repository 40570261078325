/**
 * Permissions config file
 * For storing permissions configuration details
 *
 * /config/permissions.config.ts
 *
 * Copyright (C) 2022 wadawe
 */

import { PermissionString } from "discord.js";

type PermissionSetting = {
    title : string;
    description : string;
}

type PermissionsConfig = {

    requiredPerms : PermissionString[];
    allowedPerms : PermissionString[];

    details : {
        [ key in PermissionString ] : PermissionSetting;
    }

};

/**
 * The configurable permissions
 */
const allowedPerms : PermissionString[] = [
    "CREATE_INSTANT_INVITE",
    "VIEW_CHANNEL",
    "CONNECT",
    "SPEAK",
    "USE_VAD",
    "STREAM",
    "PRIORITY_SPEAKER",
    "MOVE_MEMBERS",
    "SEND_MESSAGES",
    "READ_MESSAGE_HISTORY",
    "MANAGE_MESSAGES",
    "EMBED_LINKS",
    "ATTACH_FILES",
    "ADD_REACTIONS",
    "USE_APPLICATION_COMMANDS",
    "USE_EXTERNAL_EMOJIS",
    "USE_EXTERNAL_STICKERS"
];

/**
 * The permissions configuration
 */
export const permissionsConfig : PermissionsConfig = {

    requiredPerms: [
        ... allowedPerms,
        "MANAGE_CHANNELS",
        "MANAGE_ROLES"
    ],

    allowedPerms,

    details: {

        /**
         * Channel Permissions
         */

        CREATE_INSTANT_INVITE: {
            title: "Create Instant Invite",
            description: "Allows members to create invites that link directly to a dynamic channel."
        },

        VIEW_CHANNEL: {
            title: "View Channel",
            description: "Allows members to see the a dynamic channel and its current occupants."
        },

        /**
         * Voice Permissions
         */

        CONNECT: {
            title: "Connect",
            description: "Allows members to connect to a dynamic channel."
        },

        SPEAK: {
            title: "Speak",
            description: "Allows members to speak in the dynamic channel."
        },

        USE_VAD: {
            title: "Use Voice Activity",
            description: "Allows members to use voice activity in the dynamic channel."
        },

        STREAM: {
            title: "Video",
            description: "Allows members to stream video in the dynamic channel."
        },

        PRIORITY_SPEAKER: {
            title: "Priority Speaker",
            description: "Allows members to be more easily heard in the dynamic channel."
        },

        MOVE_MEMBERS: {
            title: "Move Members",
            description: "Allows members to move other users in and out of the dynamic channel."
        },

        START_EMBEDDED_ACTIVITIES: {
            title: "Use Activities",
            description: "Allows members to use activities in the dynamic channel."
        },

        /**
         * Text Permissions
         */

        SEND_MESSAGES: {
            title: "Send Messages",
            description: "Allows members to send message in the text area of a dynamic channel."
        },

        READ_MESSAGE_HISTORY: {
            title: "Read Message History",
            description: "Allows members to read the message history in the text area of a dynamic channel."
        },

        MANAGE_MESSAGES: {
            title: "Manage Messages",
            description: "Allows members to pin and delete messages in the text area of a dynamic channel."
        },

        EMBED_LINKS: {
            title: "Embed Links",
            description: "Allows members to send embeded links in the text area of a dynamic channel."
        },

        ATTACH_FILES: {
            title: "Attach Files",
            description: "Allows members to send files and images in the text area of a dynamic channel."
        },

        ADD_REACTIONS: {
            title: "Add Reactions",
            description: "Allows members to react to messages sent in the text area of a dynamic channel."
        },

        USE_APPLICATION_COMMANDS: {
            title: "Use Application Commands",
            description: "Allows user to use application commands in the text area of a dynamic channel."
        },

        USE_EXTERNAL_EMOJIS: {
            title: "Use External Emojis",
            description: "Allows Discord Nitro users to use emojis from other servers in the text area of a dynamic channel."
        },

        USE_EXTERNAL_STICKERS: {
            title: "Use External Stickers",
            description: "Allows Discord Nitro users to use stickers from other servers in the text area of a dynamic channel."
        },

        /**
         * Unused Permissions
         */

        USE_SOUNDBOARD: {
            title: "Use Soundboard",
            description: "Allows members to send sounds from the servers soundboard in the dynamic channel."
        },

        ADMINISTRATOR: {
            title: "",
            description: ""
        },

        BAN_MEMBERS: {
            title: "",
            description: ""
        },

        CHANGE_NICKNAME: {
            title: "",
            description: ""
        },

        CREATE_PRIVATE_THREADS: {
            title: "",
            description: ""
        },

        CREATE_PUBLIC_THREADS: {
            title: "",
            description: ""
        },

        DEAFEN_MEMBERS: {
            title: "",
            description: ""
        },

        KICK_MEMBERS: {
            title: "",
            description: ""
        },

        MANAGE_CHANNELS: {
            title: "",
            description: ""
        },

        MANAGE_EMOJIS_AND_STICKERS: {
            title: "",
            description: ""
        },

        MANAGE_EVENTS: {
            title: "",
            description: ""
        },

        MANAGE_GUILD: {
            title: "",
            description: ""
        },

        MANAGE_NICKNAMES: {
            title: "",
            description: ""
        },

        MANAGE_ROLES: {
            title: "",
            description: ""
        },

        MANAGE_THREADS: {
            title: "",
            description: ""
        },

        MANAGE_WEBHOOKS: {
            title: "",
            description: ""
        },

        MENTION_EVERYONE: {
            title: "",
            description: ""
        },

        MODERATE_MEMBERS: {
            title: "",
            description: ""
        },

        MUTE_MEMBERS: {
            title: "",
            description: ""
        },

        REQUEST_TO_SPEAK: {
            title: "",
            description: ""
        },

        SEND_MESSAGES_IN_THREADS: {
            title: "",
            description: ""
        },

        SEND_TTS_MESSAGES: {
            title: "",
            description: ""
        },

        USE_PRIVATE_THREADS: {
            title: "",
            description: ""
        },

        USE_PUBLIC_THREADS: {
            title: "",
            description: ""
        },

        VIEW_AUDIT_LOG: {
            title: "",
            description: ""
        },

        VIEW_GUILD_INSIGHTS: {
            title: "",
            description: ""
        },

        SEND_VOICE_MESSAGES: {
            title: "",
            description: ""
        },

        VIEW_CREATOR_MONETIZATION_ANALYTICS: {
            title: "",
            description: ""
        }

    }

};
