/**
 * SettingDescription Component
 *
 * /components/SettingDescription/SettingDescription.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { AccordionDetails, AccordionSummary, ListItemIcon, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { StyledAccordion } from "../../custom/StyledAccordion/StyledAccordion";
import { StyledList } from "../../custom/StyledList/StyledList";
import { StyledListItem } from "../../custom/StyledList/StyledListItem";

import { SettingCaption } from "../../font/SettingCaption";

import { theme } from "../../theme/theme";

type SettingDescriptionProps = {
    description : string;
    metaData : string[];
};

/**
 * Define the SettingDescription Component
 * @param props The SettingDescription props
 * @returns SettingDescription Component
 */
export const SettingDescription = ( props : SettingDescriptionProps ) => {

    // Define relevant data
    const metaData = props.metaData && props.metaData.length > 0 ? props.metaData : [ "No extra information to display." ];

    // Create metadata list
    const metaList = metaData.map( ( entry, index ) => {
        return <StyledListItem key={ index }>
            <ListItemIcon><KeyboardDoubleArrowRightIcon color="secondary" fontSize="small" /></ListItemIcon>
            <ListItemText><SettingCaption>{ entry }</SettingCaption></ListItemText>
        </StyledListItem>;
    } );

    // Display the component
    return (
        <StyledAccordion>

            {/* Display accordion summary */}
            <AccordionSummary expandIcon={ <ExpandMoreIcon fontSize="small" /> }>
                <SettingCaption textAlign="left"
                    padding={ `${ theme.spacing( 0.5 ) } 0` } sx={{ transition: "200ms", "&:hover": { color: theme.palette.text.primary } }}>
                    { props.description }
                </SettingCaption>
            </AccordionSummary>

            {/* Display accordion details */}
            <AccordionDetails>
                <StyledList>

                    {/* Display list of metadata */}
                    { metaList }

                </StyledList>
            </AccordionDetails>

        </StyledAccordion>
    );

};
