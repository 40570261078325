/**
 * CircleWrapper Component
 *
 * /components/CircleWrapper/CircleWrapper.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Grid, Box, Container } from "@mui/material";

import { SubHeading } from "../../font/SubHeading";
import { SubTitle } from "../../font/SubTitle";

type CircleWrapperProps = {
    title ?: string;
    description ?: string;
    icon : JSX.Element;
    children ?: JSX.Element | JSX.Element[];
};

/**
 * Define the CircleWrapper Component
 * @param props The CircleWrapper props
 * @returns CircleWrapper Component
 */
export const CircleWrapper = ( props : CircleWrapperProps ) => {

    // Display the component
    return (
        <Grid container>
            <Grid item xs={ 12 } >
                <Container>

                    {/* Set minimum height of component */}
                    <Box minHeight="300px" position="relative">

                        {/* Center component */}
                        <Grid container spacing={ 2 } position="absolute" top="50%" sx={{
                            transform: "translateY( -50% )",
                            "-ms-transform": "translateY(-50%)" }}>

                            {/* Display icon */}
                            <Grid item xs={ 12 }>
                                <Box textAlign="center">
                                    { props.icon }
                                </Box>
                            </Grid>

                            {/* Display title */}
                            <Grid item xs={ 12 }>
                                <SubHeading textAlign="center" textTransform="capitalize">
                                    { props.title }
                                </SubHeading>
                            </Grid>

                            {/* Display description */}
                            <Grid item xs={ 12 }>
                                <SubTitle textAlign="center">
                                    { props.description }
                                </SubTitle>
                            </Grid>

                            {/* Display children */}
                            { props.children && <Grid item xs={ 12 }>
                                <Box textAlign="center">
                                    { props.children }
                                </Box>
                            </Grid> }

                        </Grid>

                    </Box>

                </Container>
            </Grid>
        </Grid>
    );

};
