/**
 * Typography Definition
 *
 * /theme/typography.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { TypographyOptions } from "@mui/material/styles/createTypography";

import { breakpoints } from "./breakpoints";

/**
 * Define the typograhpy
 */
export const typography : TypographyOptions = {

    fontSize: 14,
    htmlFontSize: 14,

    fontFamily: "'Public Sans', sans-serif",

    fontWeightLight: "300",
    fontWeightRegular: "400",
    fontWeightMedium: "500",
    fontWeightBold: "600",

    h1: {
        fontSize: "2.4rem",
        fontWeight: 300,
        lineHeight: 1.1,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "2.2rem"
        }
    },

    h2: {
        fontSize: "2.0rem",
        fontWeight: 300,
        lineHeight: 1.2,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "1.8rem"
        }
    },

    h3: {
        fontSize: "1.6rem",
        fontWeight: 400,
        lineHeight: 1.3,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "1.4rem"
        }
    },

    h4: {
        fontSize: "1.4rem",
        fontWeight: 400,
        lineHeight: 1.4,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "1.2rem"
        }
    },

    h5: {
        fontSize: "1.1rem",
        fontWeight: 500,
        lineHeight: 1.5,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.9rem"
        }
    },

    h6: {
        fontSize: "0.8rem",
        fontWeight: 600,
        lineHeight: 1.6,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.7rem"
        }
    },

    subtitle1: {
        fontSize: "1.0rem",
        fontWeight: 500,
        lineHeight: 1.6,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.8rem"
        }
    },

    subtitle2: {
        fontSize: "0.9rem",
        fontWeight: 500,
        lineHeight: 1.4,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.8rem"
        }
    },

    body1: {
        fontSize: "0.8rem",
        fontWeight: 300,
        lineHeight: 1.5,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.7rem"
        }
    },

    body2: {
        fontSize: "0.9rem",
        fontWeight: 300,
        lineHeight: 1.4,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.8rem"
        }
    },

    caption: {
        fontSize: "0.7rem",
        fontWeight: 300,
        lineHeight: 1.6,
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.6rem"
        }
    },

    overline: {
        fontSize: "0.9rem",
        fontWeight: 500,
        lineHeight: 1.0,
        textTransform: "uppercase",
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.8rem"
        }
    },

    button: {
        fontSize: "1.0rem",
        fontWeight: 500,
        lineHeight: 1.6,
        textTransform: "capitalize",
        [ `@media (max-width:${ breakpoints.values.md }px)` ]: {
            fontSize: "0.8rem"
        }
    }

};
