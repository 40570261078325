/**
 * Theme Definition
 *
 * /theme/theme.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { createTheme } from "@mui/material";

import { breakpoints } from "./breakpoints";
import { palette } from "./palette";
import { typography } from "./typography";

/**
 * Define the website theme
 */
export const theme = createTheme( {

    spacing: 8,

    breakpoints,

    typography,

    palette

} );
