/**
 * Global config file
 * For storing global configuration details
 *
 * /config/global.config.ts
 *
 * Copyright (C) 2022 wadawe
 */

import { EnvironmentName } from "../types/names/EnvironmentName";

type GlobalConfig = {
    production : GlobalConfigStructure;
    development : Partial<GlobalConfigStructure>;
    test : Partial<GlobalConfigStructure>;
};

type GlobalConfigStructure = {
    clientId : string;
    authUrl : string;
    clientUrl : string;
    serverUrl : string;
    websiteUrl : string;
    loginScope : string;
    inviteScope : string;
    logLevel : string;
    forceDatabaseUpdate : boolean;
    forceDatabaseReset : boolean;
    secureRequests : boolean;
    basicSystemCount : number;
    premiumSystemCount : number;
    modifiedPermissionLimit : number;
    cacheDuration : number;
    fileExtension : string;
};

/**
 * The global configuration
 */
const config : GlobalConfig = {

    /**
     * Production configuration values
     */
    production: {

        clientId: "498201601786380289",

        authUrl: "https://auth.veebot.xyz",
        clientUrl: "https://client.veebot.xyz",
        serverUrl: "https://server.veebot.xyz",
        websiteUrl: "https://www.veebot.xyz",

        loginScope: "guilds identify",
        inviteScope: "bot applications.commands",

        logLevel: "INFO",

        forceDatabaseUpdate: true,
        forceDatabaseReset: false,
        secureRequests: true,

        basicSystemCount: 10,
        premiumSystemCount: 10,

        modifiedPermissionLimit: 32,

        cacheDuration: 90,

        fileExtension: "js"

    },

    /**
     * Development configuration values
     * Overwrites production values
     */
    development: {

        clientId: "548528851580485662",

        authUrl: "http://localhost:3003",
        clientUrl: "http://localhost:3002",
        serverUrl: "http://localhost:3001",
        websiteUrl: "http://localhost:3000",

        logLevel: "ALL",

        forceDatabaseUpdate: true,
        secureRequests: false,

        fileExtension: "ts"

    },

    /**
     * Test configuration values
     * Overwrites production values
     */
    test: {

        clientId: "548528851580485662",

        authUrl: "http://localhost:3003",
        clientUrl: "http://localhost:3002",
        serverUrl: "http://localhost:3001",
        websiteUrl: "http://localhost:3000",

        logLevel: "ALL",

        forceDatabaseReset: true,
        secureRequests: false

    }

};

// Generate the exported config
// Based on the current environment
const env = process.env.NODE_ENV as EnvironmentName || "production";
export const globalConfig : GlobalConfigStructure & { env : EnvironmentName } = {
    env: env,
    ... config.production,
    ... config[ env ]
};
