/**
 * FontsPage
 *
 * /pages/FontsPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Box, Typography, Divider } from "@mui/material";

import { ButtonText } from "../../font/ButtonText";
import { SubHeading } from "../../font/SubHeading";
import { KickerText } from "../../font/KickerText";
import { Heading } from "../../font/Heading";
import { SettingCaption } from "../../font/SettingCaption";
import { SettingTitle } from "../../font/SettingTitle";
import { SettingValue } from "../../font/SettingValue";
import { SectionTitle } from "../../font/SectionTitle";
import { SubTitle } from "../../font/SubTitle";
import { BodyText } from "../../font/BodyText";

import { Section } from "../../layout/Section/Section";

/**
 * Define the FontsPage Component
 * @returns FontsPage Component
 */
export const FontsPage = () => {

    // Display the page
    return (
        <Section>

            <Box>
                <KickerText>KickerText (h3)</KickerText>
                <Heading>Heading (h1)</Heading>
                <SubTitle>SubTitle (subtitle1)</SubTitle>
            </Box>

            <SubHeading>SubHeading (h4)</SubHeading>

            <SectionTitle>SectionTitle (overline)</SectionTitle>

            <Box>
                <SettingTitle>SettingTitle (h6)</SettingTitle>
                <Box>
                    <SettingValue>SettingValue (body1)</SettingValue>
                </Box>
                <SettingCaption>SettingCaption (caption)</SettingCaption>
            </Box>

            <BodyText>BodyText (body2)</BodyText>

            <ButtonText>ButtonText (button)</ButtonText>

            <Divider />

            <Box>
                <Typography variant="h1">variant="h1"</Typography>
                <Typography variant="h2">variant="h2"</Typography>
                <Typography variant="h3">variant="h3"</Typography>
                <Typography variant="h4">variant="h4"</Typography>
                <Typography variant="h5">variant="h5"</Typography>
                <Typography variant="h6">variant="h6"</Typography>
                <Typography variant="subtitle1">variant="subtitle1"</Typography>
                <Typography variant="subtitle2">variant="subtitle2"</Typography>
                <Typography variant="body1">variant="body1"</Typography>
                <Typography variant="body2">variant="body2"</Typography>
                <Typography variant="caption">variant="caption"</Typography>
                <Box><Typography variant="overline">variant="overline"</Typography></Box>
                <Box><Typography variant="button">variant="button"</Typography></Box>
            </Box>

        </Section>
    );

};
