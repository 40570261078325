/**
 * Section Component
 *
 * /components/Section/Section.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Children } from "react";
import { Box, Stack, useMediaQuery } from "@mui/material";

import { StyledContainer } from "../../custom/StyledContainer/StyledContainer";

import { theme } from "../../theme/theme";

type SectionProps = {
    colour ?: string;
    children : JSX.Element | JSX.Element[];
};

/**
 * Define the Section Component
 * @param props The Section props
 * @returns Section Component
 */
export const Section = ( props : SectionProps ) => {

    // Define relevant data
    const childrenArray = Children.toArray( props.children );
    const isNarrow = useMediaQuery( theme.breakpoints.down( "md" ) );

    // Display the component
    return (
        <Box sx={{ background: props.colour ?? "transparent" }}>
            <StyledContainer>
                <Stack direction="column" justifyContent="initial" spacing={ isNarrow ? 4 : 6 } padding={ `${ theme.spacing( isNarrow ? 6 : 10 ) } 0` } width="100%">
                    { childrenArray.map( ( element, key ) => {
                        return <Box key={ key } width="100%">{ element } </Box>;
                    } ) }
                </Stack>
            </StyledContainer>
        </Box>
    );

};
