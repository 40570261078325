/**
 * SettingCaption Component
 *
 * /font/SettingCaption.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Typography, TypographyProps } from "@mui/material";

import { theme } from "../theme/theme";

/**
 * Define the SettingCaption Component
 * @param props The SettingCaption props
 * @returns SettingCaption Component
 */
export const SettingCaption = styled( ( props : TypographyProps ) => {

    return <Typography variant="caption" component="span" { ... props } />;

} )( () => ( {

    color: theme.palette.text.secondary

} ) );
