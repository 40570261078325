/**
 * SystemPersistencePage Component
 *
 * /pages/ConfigurePage/SystemPersistencePage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";
import { FormattedSystemPersistence } from "../../types/formatted/FormattedSystemPersistence";
import { RequiredProperties } from "../../types/wrappers/RequiredProperties";

import { useServer } from "../../hooks/useServer";
import { useRequest } from "../../hooks/useRequest";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { InputToggleSwitch } from "../../components/InputToggleSwitch/InputToggleSwitch";
import { SettingSection } from "../../components/SettingSection/SettingSection";
import { SettingPage } from "../../components/SettingPage/SettingPage";
import { FormFooter } from "../../components/FormFooter/FormFooter";

import { settingsConfig } from "../../config/settings.config";

import { PersistenceSchema } from "../../schemas/persistence.schema";

/**
 * Define the SystemPersistencePage Component
 * @returns SystemPersistencePage Component
 */
export const SystemPersistencePage = () => {

    // Define relevant data
    const request = useRequest();
    const { systemNumber } = useParams();
    const { server, setServer } = useServer();
    const [ updating, setUpdating ] = useState( false );
    const [ success, setSuccess ] = useState( false );
    const [ error, setError ] = useState( "" );

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    let systemSettings = systemCheck;

    // Define formik form and validation
    const formik = useFormik<RequiredProperties<FormattedSystemPersistence>>( {
        enableReinitialize: true,
        initialValues: {
            saveChannelNames: systemSettings.saveChannelNames
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelNames.default.premium : settingsConfig.persistence.settings.saveChannelNames.default.free ),
            saveChannelNamesDefault: systemSettings.saveChannelNamesDefault
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelNamesDefault.default.premium : settingsConfig.persistence.settings.saveChannelNamesDefault.default.free ),
            saveChannelNamesOverride: systemSettings.saveChannelNamesOverride
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelNamesOverride.default.premium : settingsConfig.persistence.settings.saveChannelNamesOverride.default.free ),
            saveChannelUserLimits: systemSettings.saveChannelUserLimits
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelUserLimits.default.premium : settingsConfig.persistence.settings.saveChannelUserLimits.default.free ),
            saveChannelUserLimitsDefault: systemSettings.saveChannelUserLimitsDefault
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelUserLimitsDefault.default.premium : settingsConfig.persistence.settings.saveChannelUserLimitsDefault.default.free ),
            saveChannelUserLimitsOverride: systemSettings.saveChannelUserLimitsOverride
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelUserLimitsOverride.default.premium : settingsConfig.persistence.settings.saveChannelUserLimitsOverride.default.free ),
            saveChannelBitrates: systemSettings.saveChannelBitrates
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelBitrates.default.premium : settingsConfig.persistence.settings.saveChannelBitrates.default.free ),
            saveChannelBitratesDefault: systemSettings.saveChannelBitratesDefault
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelBitratesDefault.default.premium : settingsConfig.persistence.settings.saveChannelBitratesDefault.default.free ),
            saveChannelBitratesOverride: systemSettings.saveChannelBitratesOverride
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelBitratesOverride.default.premium : settingsConfig.persistence.settings.saveChannelBitratesOverride.default.free ),
            saveChannelStates: systemSettings.saveChannelStates
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelStates.default.premium : settingsConfig.persistence.settings.saveChannelStates.default.free ),
            saveChannelStatesDefault: systemSettings.saveChannelStatesDefault
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelStatesDefault.default.premium : settingsConfig.persistence.settings.saveChannelStatesDefault.default.free ),
            saveChannelStatesOverride: systemSettings.saveChannelStatesOverride
                ?? ( server.premium ? settingsConfig.persistence.settings.saveChannelStatesOverride.default.premium : settingsConfig.persistence.settings.saveChannelStatesOverride.default.free ),
            saveGrantedUsers: systemSettings.saveGrantedUsers
                ?? ( server.premium ? settingsConfig.persistence.settings.saveGrantedUsers.default.premium : settingsConfig.persistence.settings.saveGrantedUsers.default.free ),
            saveGrantedUsersDefault: systemSettings.saveGrantedUsersDefault
                ?? ( server.premium ? settingsConfig.persistence.settings.saveGrantedUsersDefault.default.premium : settingsConfig.persistence.settings.saveGrantedUsersDefault.default.free ),
            saveGrantedUsersOverride: systemSettings.saveGrantedUsersOverride
                ?? ( server.premium ? settingsConfig.persistence.settings.saveGrantedUsersOverride.default.premium : settingsConfig.persistence.settings.saveGrantedUsersOverride.default.free ),
            saveDeniedUsers: systemSettings.saveDeniedUsers
                ?? ( server.premium ? settingsConfig.persistence.settings.saveDeniedUsers.default.premium : settingsConfig.persistence.settings.saveDeniedUsers.default.free ),
            saveDeniedUsersDefault: systemSettings.saveDeniedUsersDefault
                ?? ( server.premium ? settingsConfig.persistence.settings.saveDeniedUsersDefault.default.premium : settingsConfig.persistence.settings.saveDeniedUsersDefault.default.free ),
            saveDeniedUsersOverride: systemSettings.saveDeniedUsersOverride
                ?? ( server.premium ? settingsConfig.persistence.settings.saveDeniedUsersOverride.default.premium : settingsConfig.persistence.settings.saveDeniedUsersOverride.default.free )
        },
        validationSchema: PersistenceSchema(),
        onSubmit: ( values ) => {
            setUpdating( true );
            setError( "" );
            request.post( `/server/${ server.id }/system/${ systemNumber }/persistence`, values ).then( () => {
                systemSettings = { ... systemSettings, ... values };
                server.settings.systems[ systemNumber ] = systemSettings;
                setServer( server );
                setUpdating( false );
                setSuccess( true );
            } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
                setUpdating( false );
                setSuccess( false );
                setError( e.response?.data?.message ?? "Failed to update system." );
            } );
        }
    } );

    return (

        <SettingPage
            heading={ `System #${ systemNumber } Persistence` }
            subheading={ `${ systemSettings.systemName } System` }>

            <FormikProvider value={ formik }>
                <form onSubmit={ formik.handleSubmit } onReset={ formik.handleReset } autoComplete="off">

                    <SettingSection title="Channel Names">

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelNames.title }
                            name="saveChannelNames"
                            description={ settingsConfig.persistence.settings.saveChannelNames.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelNames.metaData }
                            value={ formik.values.saveChannelNames }
                            error={ formik.errors.saveChannelNames }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelNames.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelNames.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelNamesDefault.title }
                            name="saveChannelNamesDefault"
                            description={ settingsConfig.persistence.settings.saveChannelNamesDefault.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelNamesDefault.metaData }
                            value={ formik.values.saveChannelNamesDefault }
                            error={ formik.errors.saveChannelNamesDefault }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelNamesDefault.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelNamesDefault.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelNamesOverride.title }
                            name="saveChannelNamesOverride"
                            description={ settingsConfig.persistence.settings.saveChannelNamesOverride.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelNamesOverride.metaData }
                            value={ formik.values.saveChannelNamesOverride }
                            error={ formik.errors.saveChannelNamesOverride }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelNamesOverride.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelNamesOverride.falseText } />

                    </SettingSection>

                    <SettingSection title="Channel User Limits">

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelUserLimits.title }
                            name="saveChannelUserLimits"
                            description={ settingsConfig.persistence.settings.saveChannelUserLimits.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelUserLimits.metaData }
                            value={ formik.values.saveChannelUserLimits }
                            error={ formik.errors.saveChannelUserLimits }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelUserLimits.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelUserLimits.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelUserLimitsDefault.title }
                            name="saveChannelUserLimitsDefault"
                            description={ settingsConfig.persistence.settings.saveChannelUserLimitsDefault.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelUserLimitsDefault.metaData }
                            value={ formik.values.saveChannelUserLimitsDefault }
                            error={ formik.errors.saveChannelUserLimitsDefault }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelUserLimitsDefault.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelUserLimitsDefault.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelUserLimitsOverride.title }
                            name="saveChannelUserLimitsOverride"
                            description={ settingsConfig.persistence.settings.saveChannelUserLimitsOverride.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelUserLimitsOverride.metaData }
                            value={ formik.values.saveChannelUserLimitsOverride }
                            error={ formik.errors.saveChannelUserLimitsOverride }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelUserLimitsOverride.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelUserLimitsOverride.falseText } />

                    </SettingSection>

                    <SettingSection title="Channel Bitrates">

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelBitrates.title }
                            name="saveChannelBitrates"
                            description={ settingsConfig.persistence.settings.saveChannelBitrates.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelBitrates.metaData }
                            value={ formik.values.saveChannelBitrates }
                            error={ formik.errors.saveChannelBitrates }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelBitrates.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelBitrates.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelBitratesDefault.title }
                            name="saveChannelBitratesDefault"
                            description={ settingsConfig.persistence.settings.saveChannelBitratesDefault.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelBitratesDefault.metaData }
                            value={ formik.values.saveChannelBitratesDefault }
                            error={ formik.errors.saveChannelBitratesDefault }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelBitratesDefault.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelBitratesDefault.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelBitratesOverride.title }
                            name="saveChannelBitratesOverride"
                            description={ settingsConfig.persistence.settings.saveChannelBitratesOverride.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelBitratesOverride.metaData }
                            value={ formik.values.saveChannelBitratesOverride }
                            error={ formik.errors.saveChannelBitratesOverride }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelBitratesOverride.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelBitratesOverride.falseText } />

                    </SettingSection>

                    <SettingSection title="Channel States">

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelStates.title }
                            name="saveChannelStates"
                            description={ settingsConfig.persistence.settings.saveChannelStates.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelStates.metaData }
                            value={ formik.values.saveChannelStates }
                            error={ formik.errors.saveChannelStates }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelStates.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelStates.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelStatesDefault.title }
                            name="saveChannelStatesDefault"
                            description={ settingsConfig.persistence.settings.saveChannelStatesDefault.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelStatesDefault.metaData }
                            value={ formik.values.saveChannelStatesDefault }
                            error={ formik.errors.saveChannelStatesDefault }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelStatesDefault.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelStatesDefault.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveChannelStatesOverride.title }
                            name="saveChannelStatesOverride"
                            description={ settingsConfig.persistence.settings.saveChannelStatesOverride.description }
                            metaData={ settingsConfig.persistence.settings.saveChannelStatesOverride.metaData }
                            value={ formik.values.saveChannelStatesOverride }
                            error={ formik.errors.saveChannelStatesOverride }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveChannelStatesOverride.trueText }
                            falseText={ settingsConfig.persistence.settings.saveChannelStatesOverride.falseText } />

                    </SettingSection>

                    <SettingSection title="Granted Users">

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveGrantedUsers.title }
                            name="saveGrantedUsers"
                            description={ settingsConfig.persistence.settings.saveGrantedUsers.description }
                            metaData={ settingsConfig.persistence.settings.saveGrantedUsers.metaData }
                            value={ formik.values.saveGrantedUsers }
                            error={ formik.errors.saveGrantedUsers }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveGrantedUsers.trueText }
                            falseText={ settingsConfig.persistence.settings.saveGrantedUsers.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveGrantedUsersDefault.title }
                            name="saveGrantedUsersDefault"
                            description={ settingsConfig.persistence.settings.saveGrantedUsersDefault.description }
                            metaData={ settingsConfig.persistence.settings.saveGrantedUsersDefault.metaData }
                            value={ formik.values.saveGrantedUsersDefault }
                            error={ formik.errors.saveGrantedUsersDefault }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveGrantedUsersDefault.trueText }
                            falseText={ settingsConfig.persistence.settings.saveGrantedUsersDefault.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveGrantedUsersOverride.title }
                            name="saveGrantedUsersOverride"
                            description={ settingsConfig.persistence.settings.saveGrantedUsersOverride.description }
                            metaData={ settingsConfig.persistence.settings.saveGrantedUsersOverride.metaData }
                            value={ formik.values.saveGrantedUsersOverride }
                            error={ formik.errors.saveGrantedUsersOverride }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveGrantedUsersOverride.trueText }
                            falseText={ settingsConfig.persistence.settings.saveGrantedUsersOverride.falseText } />

                    </SettingSection>

                    <SettingSection title="Denied Users">

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveDeniedUsers.title }
                            name="saveDeniedUsers"
                            description={ settingsConfig.persistence.settings.saveDeniedUsers.description }
                            metaData={ settingsConfig.persistence.settings.saveDeniedUsers.metaData }
                            value={ formik.values.saveDeniedUsers }
                            error={ formik.errors.saveDeniedUsers }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveDeniedUsers.trueText }
                            falseText={ settingsConfig.persistence.settings.saveDeniedUsers.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveDeniedUsersDefault.title }
                            name="saveDeniedUsersDefault"
                            description={ settingsConfig.persistence.settings.saveDeniedUsersDefault.description }
                            metaData={ settingsConfig.persistence.settings.saveDeniedUsersDefault.metaData }
                            value={ formik.values.saveDeniedUsersDefault }
                            error={ formik.errors.saveDeniedUsersDefault }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveDeniedUsersDefault.trueText }
                            falseText={ settingsConfig.persistence.settings.saveDeniedUsersDefault.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.persistence.settings.saveDeniedUsersOverride.title }
                            name="saveDeniedUsersOverride"
                            description={ settingsConfig.persistence.settings.saveDeniedUsersOverride.description }
                            metaData={ settingsConfig.persistence.settings.saveDeniedUsersOverride.metaData }
                            value={ formik.values.saveDeniedUsersOverride }
                            error={ formik.errors.saveDeniedUsersOverride }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.persistence.settings.saveDeniedUsersOverride.trueText }
                            falseText={ settingsConfig.persistence.settings.saveDeniedUsersOverride.falseText } />

                    </SettingSection>

                    <FormFooter ready={ formik.dirty && formik.isValid } success={ success } setSuccess={ setSuccess } updating={ updating } error={ error } />

                </form>
            </FormikProvider>

        </SettingPage>

    );

};
