/**
 * HomePage
 *
 * /pages/HomePage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Link, Divider, Box } from "@mui/material";
import { Masonry } from "@mui/lab";
import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import StarRateIcon from "@mui/icons-material/StarRate";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

import HomePageHero from "../../assets/HomePageHero.gif";
import TemporaryVoiceChannelsHero from "../../assets/TemporaryVoiceChannelsHero.gif";

import { getInviteUrl } from "../../utils/Links";

import { Section } from "../../layout/Section/Section";

import { Hero } from "../../components/Hero/Hero";
import { FeatureCard } from "../../components/FeatureCard/FeatureCard";
import { AppraisalCard } from "../../components/AppraisalCard/AppraisalCard";

import { Heading } from "../../font/Heading";
import { SettingTitle } from "../../font/SettingTitle";

import { theme } from "../../theme/theme";

import { appraisalsConfig } from "../../config/appraisals.config";

/**
 * Define the HomePage Component
 * @returns HomePage Component
 */
export const HomePage = () => {

    // Display the page
    return (
        <Grid container>

            {/* Display page hero */}
            <Grid item xs={ 12 }>
                <Section>
                    <Hero title="Dynamic Channels for Discord" kicker="VeeBot"
                        subtitle="VeeBot is a stylish and original bot that brings ease to the management of your Discord Community with Dynamic Voice Channel Systems. Users can create, moderate, and manage their very own voice channels; allowing them to stay in control of who they talk to and play with."
                        image={ HomePageHero }
                        buttons={ [
                            <Button color="primary" variant="contained" startIcon={ <EmailIcon /> } href={ getInviteUrl() } target="_blank">Add to Discord</Button>,
                            <Link to={ "/configure" } component={ RouterLink } underline="none">
                                <Button color="secondary" variant="outlined" startIcon={ <SettingsIcon /> }>Configure</Button>
                            </Link>
                        ] } />
                    <Divider />
                    <SettingTitle textAlign="center">Trusted by over 1500 communities</SettingTitle>
                </Section>
            </Grid>

            {/* Display showcase section */}
            <Grid item xs={ 12 }>
                <Section colour={ theme.palette.divider }>
                    <Hero title="Temporary Voice Channels"
                        subtitle="With VeeBot, your users can create voice channels on demand. Once finished with, the channel is then deleted!" />
                    <Hero title="Get Setup in Minutes" flipped
                        subtitle="With the intuitive web panel, you can change every aspect of your Dynamic Channel Systems to create a completely customised experience. You can create multiple Dynamic Voice Channel Systems, each with their own unique behaviour."
                        image={ TemporaryVoiceChannelsHero }
                        buttons={ [
                            <Link to={ "/configure" } component={ RouterLink } underline="none">
                                <Button color="info" variant="contained" startIcon={ <SettingsIcon /> }>Lets Go!</Button>
                            </Link>
                        ] } />
                    <Grid container spacing={ 4 }>
                        <FeatureCard icon={ <StarRateIcon /> } color={ theme.palette.warning.main }
                            title="Dynamic is KING"
                            description="Clean up your community! Endless scrolling through empty channels can deter users from using your community. Fix channel bloat with VeeBot!" />
                        <FeatureCard icon={ <PersonAddIcon /> } color={ theme.palette.success.main }
                            title="Play with FRIENDS"
                            description="Users can choose who has access to their Dynamic Voice Channel; enhancing your community environment as your users continue to meet new people and make new friends." />
                        <FeatureCard icon={ <PersonRemoveIcon /> } color={ theme.palette.error.main }
                            title="Control those TROLLS"
                            description="Users can remove people from their Dynamic Voice Channel; completely eliminating the need for you to monitor every... single... voice channel..." />
                    </Grid>
                </Section>
            </Grid>

            {/* Display appraisals */}
            <Grid item xs={ 12 }>
                <Section colour={ theme.palette.background.default }>
                    <Heading textAlign="center">Loved by @everyone</Heading>

                    {/* Counter right margin from masonry */}
                    <Box sx={{ marginRight: - 4 }}>
                        <Masonry columns={{ md: 3, sm: 2, xs: 1 }} spacing={ 4 }>

                            {/* Order appraisals randomly and retieve the first 16 */}
                            { appraisalsConfig.map( ( appraisal ) => { return { ... appraisal, order: Math.random() }; } ).sort( ( a, b ) => { return a.order - b.order; } ).map( ( appraisal, index ) => {
                                return <AppraisalCard key={ index } text={ appraisal.contents } server={ appraisal.displayName } />;
                            } ).slice( 0, 16 ) }

                        </Masonry>
                    </Box>

                </Section>
            </Grid>

        </Grid>
    );

};
