/**
 * Settings config file
 * For storing setting configuration details
 *
 * /config/settings.config.ts
 *
 * Copyright (C) 2022 wadawe
 */

import { PermissionString } from "discord.js";

import { commandsConfig } from "./commands.config";
import { globalConfig } from "./global.config";

type SettingGroup = {
    name : string;
};

type CustomSetting = {
    title : string;
    feature : string;
    description : string;
    explanation : string;
    metaData : string[];
    isDisplayed : boolean;
    displayValue : boolean;
};

export type StringSetting = {
    isPremium : boolean;
    default : {
        free : string;
        premium : string;
    };
    minLength : number;
    maxLength : number;
};

export type NumberSetting = {
    isPremium : boolean;
    default : {
        free : number;
        premium : number;
    };
    minValue : number;
    maxValue : number;
};

export type BooleanSetting = {
    isPremium : boolean;
    default : {
        free : boolean;
        premium : boolean;
    };
    trueText : string;
    falseText : string;
};

export type PermissionSetting = {
    isPremium : boolean;
    default : {
        free : {
            true : PermissionString[];
            false : PermissionString[];
        };
        premium : {
            true : PermissionString[];
            false : PermissionString[];
        };
    };
};

type SettingsConfig = {

    systems : SettingGroup & { settings : {
        systemCount : NumberSetting & CustomSetting;
    } },

    basics : SettingGroup & { settings : {
        systemName : StringSetting & CustomSetting;
        createChannel : StringSetting & CustomSetting;
        parentCategory : StringSetting & CustomSetting;
        publicRole : StringSetting & CustomSetting;
    } },

    defaults : SettingGroup & { settings : {
        defaultChannelName : StringSetting & CustomSetting;
        defaultChannelUserLimit : NumberSetting & CustomSetting;
        defaultChannelBitrate : NumberSetting & CustomSetting;
        defaultChannelState : BooleanSetting & CustomSetting;
    } },

    behaviour : SettingGroup & { settings : {
        deleteChannelWhenEmpty : BooleanSetting & CustomSetting;
        deleteChannelWhenOwnerDC : BooleanSetting & CustomSetting;
        moveUserOnCreate : BooleanSetting & CustomSetting;
        disconnectUserOnDeny : BooleanSetting & CustomSetting;
        inheritParentPermissions : BooleanSetting & CustomSetting;
    } },

    persistence : SettingGroup & { settings : {
        saveChannelNames : BooleanSetting & CustomSetting;
        saveChannelNamesDefault : BooleanSetting & CustomSetting;
        saveChannelNamesOverride : BooleanSetting & CustomSetting;
        saveChannelUserLimits : BooleanSetting & CustomSetting;
        saveChannelUserLimitsDefault : BooleanSetting & CustomSetting;
        saveChannelUserLimitsOverride : BooleanSetting & CustomSetting;
        saveChannelBitrates : BooleanSetting & CustomSetting;
        saveChannelBitratesDefault : BooleanSetting & CustomSetting;
        saveChannelBitratesOverride : BooleanSetting & CustomSetting;
        saveChannelStates : BooleanSetting & CustomSetting;
        saveChannelStatesDefault : BooleanSetting & CustomSetting;
        saveChannelStatesOverride : BooleanSetting & CustomSetting;
        saveGrantedUsers : BooleanSetting & CustomSetting;
        saveGrantedUsersDefault : BooleanSetting & CustomSetting;
        saveGrantedUsersOverride : BooleanSetting & CustomSetting;
        saveDeniedUsers : BooleanSetting & CustomSetting;
        saveDeniedUsersDefault : BooleanSetting & CustomSetting;
        saveDeniedUsersOverride : BooleanSetting & CustomSetting;
    } },

    permissions : SettingGroup & { settings : {
        ownerPerms : PermissionSetting & CustomSetting;
        lockedPerms : PermissionSetting & CustomSetting;
        unlockedPerms : PermissionSetting & CustomSetting;
        grantedPerms : PermissionSetting & CustomSetting;
        deniedPerms : PermissionSetting & CustomSetting;
        createRolePermissions : BooleanSetting & CustomSetting;
    } },

    commands : SettingGroup & { settings : {
        enableCommandName : BooleanSetting & CustomSetting;
        enableCommandLimit : BooleanSetting & CustomSetting;
        enableCommandBitrate : BooleanSetting & CustomSetting;
        enableCommandLock : BooleanSetting & CustomSetting;
        enableCommandUnlock : BooleanSetting & CustomSetting;
        enableCommandGrant : BooleanSetting & CustomSetting;
        enableCommandDeny : BooleanSetting & CustomSetting;
        enableCommandClear : BooleanSetting & CustomSetting;
        enableCommandKick : BooleanSetting & CustomSetting;
        enableCommandMute : BooleanSetting & CustomSetting;
        enableCommandUnmute : BooleanSetting & CustomSetting;
        enableCommandTransfer : BooleanSetting & CustomSetting;
        enableCommandClaim : BooleanSetting & CustomSetting;
        enableCommandDelete : BooleanSetting & CustomSetting;
    } }

};

/**
 * The commands configuration
 */
export const settingsConfig : SettingsConfig = {

    systems: {
        name: "Systems",
        settings: {

            systemCount: {
                title: "Dynamic Voice Channel Systems Count",
                feature: "Dynamic Voice Channel Systems",
                description: "Define how many dynamic voice channel systems can be created.",
                explanation: "The number of dynamic voice channel systems that can be created.",
                metaData: [],
                isPremium: true,
                isDisplayed: true,
                displayValue: true,
                default: {
                    free: 10,
                    premium: 10
                },
                minValue: 1,
                maxValue: 10
            }

        }
    },

    basics: {
        name: "System Basics",
        settings: {

            systemName: {
                title: "System Name",
                feature: "Define a System Name",
                description: "Define a name value for the dynamic shannel systems.",
                explanation: "The name for a dynamic voice channel system.",
                metaData: [
                    "Each 'System Name' value must be unique across all dynamic voice channel systems.",
                    "The 'System Name' is used to identify and differentiate your dynamic voice channel systems."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: "",
                    premium: ""
                },
                minLength: 3,
                maxLength: 16
            },

            createChannel: {
                title: "Create Channel",
                feature: "Define a System Create Channel",
                description: "Select the channel users must join to create their own dynamic voice channel.",
                explanation: "The channel users must join to create their own dynamic voice channel.",
                metaData: [
                    "Each 'Create Channel' value must be unique across all dynamic voice channel systems.",
                    "Options are greyed out if VeeBot does not have access to manage that channel."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: "",
                    premium: ""
                },
                minLength: 16,
                maxLength: 32
            },

            parentCategory: {
                title: "Parent Category",
                feature: "Define a System Parent Category",
                description: "Select the category channel that dynamic voice channels are created under.",
                explanation: "The category channel that dynamic voice channels are created under.",
                metaData: [
                    "Each 'Parent Category' value should be unique across all dynamic voice channel systems, however this is not enforced.",
                    "Options are greyed out if VeeBot does not have access to manage that channel.",
                    "Due to the limitations of Discord Category Channels, each System has a maximum limit of '50' dynamic voice channels."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: "",
                    premium: ""
                },
                minLength: 16,
                maxLength: 32
            },

            publicRole: {
                title: "Public Role",
                feature: "Define a System Public Role",
                description: "Define a role that is considered your public user role.",
                explanation: "The role that is considered your public user role.",
                metaData: [
                    "The default is the '@everyone' role.",
                    "Options are greyed out if VeeBot does not have access to manage that role.",
                    "VeeBot's role must be above the 'Public Role' in the Discord Role hierarchy.",
                    "The 'Public Role' is used to alter the locked/unlocked state of a dynamic voice channel."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: "",
                    premium: ""
                },
                minLength: 0,
                maxLength: 32
            }

        }
    },

    defaults: {
        name: "System Defaults",
        settings: {

            defaultChannelName: {
                title: "Default Channel Name",
                feature: "Define a Default Channel Name",
                description: "Define a name value that all dynamic voice channels are created with by default.",
                explanation: "The name that all dynamic voice channels are created with by default.",
                metaData: [
                    "The channel name can be altered by users through the use of the '/name' command.",
                    "The '{id}' placeholder tag will get replaced with the dynamic voice channel owner's id.",
                    "The '{user}' placeholder tag will get replaced with the dynamic voice channel owner's username.",
                    "The '{nick}' placeholder tag will get replaced with the dynamic voice channel owner's server nickname.",
                    "The '{disc}' placeholder tag will get replaced with the dynamic voice channel owner's discriminator."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: "RENAME ME : {nick}",
                    premium: "RENAME ME : {nick}"
                },
                minLength: 1,
                maxLength: 32
            },

            defaultChannelUserLimit: {
                title: "Default Channel User Limit",
                feature: "Define a Default Channel User Limit",
                description: "Define a user limit value that all dynamic voice channels are created with by default.",
                explanation: "The user limit that all dynamic voice channels are created with by default.",
                metaData: [
                    "The channel user limit can be altered by users through the use of the '/limit' command."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: 0,
                    premium: 0
                },
                minValue: 0,
                maxValue: 99
            },

            defaultChannelBitrate: {
                title: "Default Channel Bitrate",
                feature: "Define a Default Channel Bitrate",
                description: "Define a bitrate value that all dynamic voice channels are created with by default.",
                explanation: "The bitrate that all dynamic voice channels are created with by default.",
                metaData: [
                    "The channel bitrate can be altered by users through the use of the '/bitrate' command."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: 64,
                    premium: 64
                },
                minValue: 8,
                maxValue: 384
            },

            defaultChannelState: {
                title: "Default Channel State",
                feature: "Define a Default Channel State",
                description: "Define a locked/unlocked state that all dynamic voice channels are created with by default.",
                explanation: "The locked or unlocked state that all dynamic voice channels are created with by default.",
                metaData: [
                    "Locked state means that only granted users should be able to join.",
                    "Unlocked state means that only denied users should not be able to join.",
                    "The channel state can be altered by users through the use of the '/lock' or '/unlock' command."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Locked",
                falseText: "Unlocked"
            }

        }
    },

    behaviour: {
        name: "System Behaviour",
        settings: {

            deleteChannelWhenEmpty: {
                title: "Delete Channels When Empty",
                feature: "Delete Channels When Empty",
                description: "Define whether a dynamic voice channel should be deleted when it is empty.",
                explanation: "Dynamic voice channels can be deleted when they are empty.",
                metaData: [
                    "Disabling all channel deletion features will result in channels never being automatically deleted."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: true,
                    premium: true
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            deleteChannelWhenOwnerDC: {
                title: "Delete Channels When Owner Disconnects",
                feature: "Delete Channels When Owner Disconnects",
                description: "Define whether a dynamic voice channel should be deleted when the owner disconnects.",
                explanation: "Dynamic voice channels can be deleted when the owner disconnects.",
                metaData: [
                    "Disabling all channel deletion features will result in channels never being automatically deleted."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            moveUserOnCreate: {
                title: "Move User to Created Channel",
                feature: "Move User to Created Channel",
                description: "Define whether users are moved to their new dynamic voice channel upon creation.",
                explanation: "Move users to their newly created dynamic voice channels.",
                metaData: [
                    "You should consider disabling the '/claim' command if you disable this feature."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: true,
                    premium: true
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            disconnectUserOnDeny: {
                title: "Disconnect User When Denied",
                feature: "Disconnect User When Denied",
                description: "Define whether users are disconnected from a dynamic voice channel when they are denied access to it.",
                explanation: "Disconnect users when they are denied access to a dynamic voice channel.",
                metaData: [
                    "The specified user must be in the dynamic voice channel in order for them to be disconnected from it.",
                    "Disabling this feature will require the user to be manually removed with the '/kick' command after being denied access."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: true,
                    premium: true
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            inheritParentPermissions: {
                title: "Inherit Parent Permissions",
                feature: "Parent Category Permissions Inheritance",
                description: "Define whether a dynamic voice channel should inherit the parent category permissions upon creation.",
                explanation: "Dynamic voice channels can inherit permissions from their parent category.",
                metaData: [
                    "Inheritance only applies to role permissions.",
                    "Inherited permissions will be filtered through a list of accepted permissions.",
                    "Inherited permissions will always be overwritten by any custom defined role permissions.",
                    `There is a total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel. This includes both roles and users.`
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            }

        }
    },

    persistence: {
        name: "System Persistence",
        settings: {

            saveChannelNames: {
                title: "Use Saved Channel Names",
                feature: "Persistent Dynamic Channel Names",
                description: "Define whether saved channel names should persist across newly created dynamic voice channels.",
                explanation: "Altered channel names can persist across newly created dynamic voice channels.",
                metaData: [
                    "Saves only apply to values changed through the use of commands.",
                    "You must enable at least one of the saving methods below for this setting to work."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelNamesDefault: {
                title: "Save Channel Names by Default",
                feature: "",
                description: "Define whether altered dynamic voice channel names are saved by default.",
                explanation: "",
                metaData: [
                    "When enabled, changes will be automatically saved when altered through the use of commands."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelNamesOverride: {
                title: "Save Channel Names Override",
                feature: "",
                description: "Define whether users can override the default channel name persistency value with the optional 'save' command parameter.",
                explanation: "",
                metaData: [
                    "If saving is enabled by default, users can manually disable it.",
                    "If saving is disabled by default, users can manually enable it."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelUserLimits: {
                title: "Use Saved Channel User Limits",
                feature: "Persistent Dynamic Channel User Limits",
                description: "Define whether saved channel user limits should persist across newly created dynamic voice channels.",
                explanation: "Altered channel user limits can persist across newly created dynamic voice channels.",
                metaData: [
                    "Saves only apply to values changed through the use of commands.",
                    "You must enable at least one of the saving methods below for this setting to work."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelUserLimitsDefault: {
                title: "Save Channel User Limits by Default",
                feature: "",
                description: "Define whether altered dynamic voice channel user limits are saved by default.",
                explanation: "",
                metaData: [
                    "When enabled, changes will be automatically saved when altered through the use of commands."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelUserLimitsOverride: {
                title: "Save Channel User Limits Override",
                feature: "",
                description: "Define whether users can override the default channel user limit persistency value with the optional 'save' command parameter.",
                explanation: "",
                metaData: [
                    "If saving is enabled by default, users can manually disable it.",
                    "If saving is disabled by default, users can manually enable it."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelBitrates: {
                title: "Use Saved Channel Bitrates",
                feature: "Persistent Dynamic Channel Bitrates",
                description: "Define whether saved channel bitrates should persist across newly created dynamic voice channels.",
                explanation: "Altered channel bitrates can persist across newly created dynamic voice channels.",
                metaData: [
                    "Saves only apply to values changed through the use of commands.",
                    "You must enable at least one of the saving methods below for this setting to work."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelBitratesDefault: {
                title: "Save Channel Bitrates by Default",
                feature: "",
                description: "Define whether altered dynamic voice channel bitrates are saved by default.",
                explanation: "",
                metaData: [
                    "When enabled, changes will be automatically saved when altered through the use of commands."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelBitratesOverride: {
                title: "Save Channel Bitrates Override",
                feature: "",
                description: "Define whether users can override the default channel bitrate persistency value with the optional 'save' command parameter.",
                explanation: "",
                metaData: [
                    "If saving is enabled by default, users can manually disable it.",
                    "If saving is disabled by default, users can manually enable it."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelStates: {
                title: "Use Saved Channel States",
                feature: "Persistent Dynamic Channel States",
                description: "Define whether saved channel locked/unlocked states should persist across newly created dynamic voice channels.",
                explanation: "Altered channel states can persist across newly created dynamic voice channels.",
                metaData: [
                    "Saves only apply to values changed through the use of commands.",
                    "You must enable at least one of the saving methods below for this setting to work."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelStatesDefault: {
                title: "Save Channel States by Default",
                feature: "",
                description: "Define whether altered dynamic voice channel locked/unlocked states are saved by default.",
                explanation: "",
                metaData: [
                    "When enabled, changes will be automatically saved when altered through the use of commands."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveChannelStatesOverride: {
                title: "Save Channel States Override",
                feature: "",
                description: "Define whether users can override the default channel locked/unlocked state persistency value with the optional 'save' command parameter.",
                explanation: "",
                metaData: [
                    "If saving is enabled by default, users can manually disable it.",
                    "If saving is disabled by default, users can manually enable it."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveGrantedUsers: {
                title: "Use Saved Granted Users",
                feature: "Persistent Granted User Access",
                description: "Define whether saved granted users should persist across newly created dynamic voice channels.",
                explanation: "Granted user access can persist across newly created dynamic voice channels.",
                metaData: [
                    "You must enable at least one of the saving methods below for this setting to work.",
                    `There is a total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel. This includes both roles and users.`
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveGrantedUsersDefault: {
                title: "Save Granted Users by Default",
                feature: "",
                description: "Define whether granted users are saved by default.",
                explanation: "",
                metaData: [
                    "When enabled, changes will be automatically saved when altered through the use of commands."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveGrantedUsersOverride: {
                title: "Save Granted Users Override",
                feature: "",
                description: "Define whether users can override the default granted user persistency value with the optional 'save' command parameter.",
                explanation: "",
                metaData: [
                    "If saving is enabled by default, users can manually disable it.",
                    "If saving is disabled by default, users can manually enable it."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveDeniedUsers: {
                title: "Use Saved Denied Users",
                feature: "Persistent Denied User Access",
                description: "Define whether saved denied users should persist across newly created dynamic voice channels.",
                explanation: "Denied user access can persist across newly created dynamic voice channels.",
                metaData: [
                    "You must enable at least one of the saving methods below for this setting to work.",
                    `There is a total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel. This includes both roles and users.`
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveDeniedUsersDefault: {
                title: "Save Denied Users by Default",
                feature: "",
                description: "Define whether denied users are saved by default.",
                explanation: "",
                metaData: [
                    "When enabled, changes will be automatically saved when altered through the use of commands."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            saveDeniedUsersOverride: {
                title: "Save Denied Users Override",
                feature: "",
                description: "Define whether users can override the default denied user persistency value with the optional 'save' command parameter.",
                explanation: "",
                metaData: [
                    "If saving is enabled by default, users can manually disable it.",
                    "If saving is disabled by default, users can manually enable it."
                ],
                isPremium: false,
                isDisplayed: false,
                displayValue: false,
                default: {
                    free: false,
                    premium: false
                },
                trueText: "Enabled",
                falseText: "Disabled"
            }

        }
    },

    permissions: {
        name: "System Permissions",
        settings: {

            ownerPerms: {
                title: "Owner Permissions",
                feature: "Modify Channel Owner Permissions",
                description: "Define the channel permissions given to the owner of a dynamic voice channel.",
                explanation: "Define the channel permissions given to the owner of a dynamic voice channel.",
                metaData: [
                    "These permissions will be given to the channel owner in their dynamic voice channel."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: {
                        true: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT", "SPEAK", "USE_VAD", "STREAM", "MOVE_MEMBERS", "SEND_MESSAGES", "READ_MESSAGE_HISTORY", "MANAGE_MESSAGES" ],
                        false: []
                    },
                    premium: {
                        true: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT", "SPEAK", "USE_VAD", "STREAM", "MOVE_MEMBERS", "SEND_MESSAGES", "READ_MESSAGE_HISTORY", "MANAGE_MESSAGES" ],
                        false: []
                    }
                }
            },

            lockedPerms: {
                title: "Locked Permissions",
                feature: "Modify Locked Channel Permissions",
                description: "Define the channel permissions given to the public role in a locked dynamic voice channel.",
                explanation: "Define the channel permissions given to the public role in a locked dynamic voice channel.",
                metaData: [
                    "These permissions are the default permissions for a user in a locked dynamic voice channel."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: {
                        true: [],
                        false: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ]
                    },
                    premium: {
                        true: [],
                        false: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ]
                    }
                }
            },

            unlockedPerms: {
                title: "Unlocked Permissions",
                feature: "Modify Unlocked Channel Permissions",
                description: "Define the channel permissions given to the public role in an unlocked dynamic voice channel.",
                explanation: "Define the channel permissions given to the public role in an unlocked dynamic voice channel.",
                metaData: [
                    "These permissions are the default permissions for a user in an unlocked dynamic voice channel."
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: {
                        true: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ],
                        false: []
                    },
                    premium: {
                        true: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ],
                        false: []
                    }
                }
            },

            grantedPerms: {
                title: "Granted Permissions",
                feature: "Modify Granted User Permissions",
                description: "Define the channel permissions given to a granted user in a dynamic voice channel.",
                explanation: "Define the channel permissions given to a granted user in a dynamic voice channel.",
                metaData: [
                    "These permissions override the default locked/unlocked permissions for a user in a dynamic voice channel.",
                    `There is a total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel. This includes both roles and users.`
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: {
                        true: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ],
                        false: []
                    },
                    premium: {
                        true: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ],
                        false: []
                    }
                }
            },

            deniedPerms: {
                title: "Denied Permissions",
                feature: "Modify Denied User Permissions",
                description: "Define the permissions for a denied user in a dynamic voice channel.",
                explanation: "Define the channel permissions given to a denied user in a dynamic voice channel.",
                metaData: [
                    "These permissions override the default locked/unlocked permissions for a user in a dynamic voice channel.",
                    `There is a total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel. This includes both roles and users.`
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: {
                        true: [],
                        false: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ]
                    },
                    premium: {
                        true: [],
                        false: [ "CREATE_INSTANT_INVITE", "VIEW_CHANNEL", "CONNECT" ]
                    }
                }
            },

            createRolePermissions: {
                title: "Create Role Permission",
                feature: "Create Custom Role Permissions",
                description: "Select a role to define permissions for it in dynamic voice channels.",
                explanation: "Create custom channel permissions given to specific roles in a dynamic voice channel.",
                metaData: [
                    "Options are greyed out if VeeBot does not have access to manage that role.",
                    "VeeBot's role must be above each role in the Discord Role hierarchy.",
                    "The system 'Public Role' will be greyed out as permissions for this role can only be set through the use of locked/unlocked permissions.",
                    `There is a total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel. This includes both roles and users.`
                ],
                isPremium: false,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: true,
                    premium: true
                },
                trueText: "Enabled",
                falseText: "Disabled"
            }

        }
    },

    commands: {
        name: "System Commands",
        settings: {

            enableCommandName: {
                title: "Name Command",
                feature: "Channel Name Slash Command",
                description: "Enable or disable the '/name' command.",
                explanation: commandsConfig.name.description,
                metaData: [
                    commandsConfig.name.description
                ],
                isPremium: commandsConfig.name.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.name.isPremium,
                    premium: ! commandsConfig.name.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandLimit: {
                title: "Limit Command",
                feature: "Channel Limit Slash Command",
                description: "Enable or disable the '/limit' command.",
                explanation: commandsConfig.limit.description,
                metaData: [
                    commandsConfig.limit.description
                ],
                isPremium: commandsConfig.limit.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.limit.isPremium,
                    premium: ! commandsConfig.limit.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandBitrate: {
                title: "Bitrate Command",
                feature: "Channel Bitrate Slash Command",
                description: "Enable or disable the '/bitrate' command.",
                explanation: commandsConfig.bitrate.description,
                metaData: [
                    commandsConfig.bitrate.description
                ],
                isPremium: commandsConfig.bitrate.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.bitrate.isPremium,
                    premium: ! commandsConfig.bitrate.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandLock: {
                title: "Lock Command",
                feature: "Lock Channel Slash Command",
                description: "Enable or disable the '/lock' command.",
                explanation: commandsConfig.lock.description,
                metaData: [
                    commandsConfig.lock.description
                ],
                isPremium: commandsConfig.lock.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.lock.isPremium,
                    premium: ! commandsConfig.lock.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandUnlock: {
                title: "Unlock Command",
                feature: "Unlock Channel Slash Command",
                description: "Enable or disable the '/unlock' command.",
                explanation: commandsConfig.unlock.description,
                metaData: [
                    commandsConfig.unlock.description
                ],
                isPremium: commandsConfig.unlock.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.unlock.isPremium,
                    premium: ! commandsConfig.unlock.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandGrant: {
                title: "Grant Command",
                feature: "Grant User Slash Command",
                description: "Enable or disable the '/grant' command.",
                explanation: commandsConfig.grant.description,
                metaData: [
                    commandsConfig.grant.description,
                    `This command contributes towards the total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel.`
                ],
                isPremium: commandsConfig.grant.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.grant.isPremium,
                    premium: ! commandsConfig.grant.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandDeny: {
                title: "Deny Command",
                feature: "Deny User Slash Command",
                description: "Enable or disable the '/deny' command.",
                explanation: commandsConfig.deny.description,
                metaData: [
                    commandsConfig.deny.description,
                    `This command contributes towards the total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel.`
                ],
                isPremium: commandsConfig.deny.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.deny.isPremium,
                    premium: ! commandsConfig.deny.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandClear: {
                title: "Clear Command",
                feature: "Clear User Slash Command",
                description: "Enable or disable the '/clear' command.",
                explanation: commandsConfig.clear.description,
                metaData: [
                    commandsConfig.clear.description
                ],
                isPremium: commandsConfig.clear.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.clear.isPremium,
                    premium: ! commandsConfig.clear.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandKick: {
                title: "Kick Command",
                feature: "Kick User Slash Command",
                description: "Enable or disable the '/kick' command.",
                explanation: commandsConfig.kick.description,
                metaData: [
                    commandsConfig.kick.description
                ],
                isPremium: commandsConfig.kick.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.kick.isPremium,
                    premium: ! commandsConfig.kick.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandMute: {
                title: "Mute Command",
                feature: "Mute User Slash Command",
                description: "Enable or disable the '/mute' command.",
                explanation: commandsConfig.mute.description,
                metaData: [
                    commandsConfig.mute.description,
                    `This command contributes towards the total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel.`
                ],
                isPremium: commandsConfig.mute.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.mute.isPremium,
                    premium: ! commandsConfig.mute.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandUnmute: {
                title: "Unmute Command",
                feature: "Unmute User Slash Command",
                description: "Enable or disable the '/unmute' command.",
                explanation: commandsConfig.unmute.description,
                metaData: [
                    commandsConfig.unmute.description,
                    `This command contributes towards the total modified permissions limit of '${ globalConfig.modifiedPermissionLimit }' per dynamic voice channel.`
                ],
                isPremium: commandsConfig.unmute.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.unmute.isPremium,
                    premium: ! commandsConfig.unmute.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandTransfer: {
                title: "Transfer Command",
                feature: "Transfer Ownership Slash Command",
                description: "Enable or disable the '/transfer' command.",
                explanation: commandsConfig.transfer.description,
                metaData: [
                    commandsConfig.transfer.description
                ],
                isPremium: commandsConfig.transfer.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.transfer.isPremium,
                    premium: ! commandsConfig.transfer.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandClaim: {
                title: "Claim Command",
                feature: "Claim Ownership Slash Command",
                description: "Enable or disable the '/claim' command.",
                explanation: commandsConfig.claim.description,
                metaData: [
                    commandsConfig.claim.description
                ],
                isPremium: commandsConfig.claim.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.claim.isPremium,
                    premium: ! commandsConfig.claim.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            },

            enableCommandDelete: {
                title: "Delete Command",
                feature: "Delete Channel Slash Command",
                description: "Enable or disable the '/delete' command.",
                explanation: commandsConfig.delete.description,
                metaData: [
                    commandsConfig.delete.description
                ],
                isPremium: commandsConfig.delete.isPremium,
                isDisplayed: true,
                displayValue: false,
                default: {
                    free: ! commandsConfig.delete.isPremium,
                    premium: ! commandsConfig.delete.isPremium
                },
                trueText: "Enabled",
                falseText: "Disabled"
            }

        }
    }

    // ...

};
