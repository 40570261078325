/**
 * PermissionsDialog Component
 *
 * /components/PermissionsDialog/PermissionsDialog.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Dialog } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useParams } from "react-router-dom";

import { FormattedPermissionsMap } from "../../types/formatted/FormattedPermissionsMap";
import { RequiredProperties } from "../../types/wrappers/RequiredProperties";

import { useServer } from "../../hooks/useServer";

import { InputPermissionRadio } from "../InputPermissionRadio/InputPermissionRadio";
import { ErrorCircle } from "../ErrorCircle/ErrorCircle";
import { SettingSection } from "../SettingSection/SettingSection";
import { FormFooter } from "../FormFooter/FormFooter";
import { SettingPage } from "../SettingPage/SettingPage";

import { StyledBackdrop } from "../../custom/StyledBackdrop/StyledBackdrop";

import { permissionsConfig } from "../../config/permissions.config";

import { PermissionsSchema } from "../../schemas/permissions.schema";

type PermissionsDialogProps = {
    title : string;
    description : string;
    updating : boolean;
    error : string;
    success : boolean;
    setSuccess : ( value : boolean ) => void;
    isPermsOpen : boolean;
    setPermsOpen : ( value : boolean ) => void;
    permsValue : FormattedPermissionsMap;
    setPermsValue : ( value : FormattedPermissionsMap ) => void;
};

/**
 * Define the PermissionsDialog Component
 * @param props The PermissionsDialog props
 * @returns PermissionsDialog Component
 */
export const PermissionsDialog = ( props : PermissionsDialogProps ) => {

    // Define relevant data
    const { systemNumber } = useParams();
    const { server } = useServer();

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Define formik form and validation
    const formik = useFormik<RequiredProperties<FormattedPermissionsMap>>( {
        enableReinitialize: true,
        initialValues: Object.fromEntries( permissionsConfig.allowedPerms.map( ( permission ) => [
            permission, props.permsValue[ permission ] ?? 0
        ] ) ),
        validationSchema: PermissionsSchema(),
        onSubmit: ( values ) => {
            props.setPermsValue( values );
        }
    } );

    // Display the component
    return (
        <Dialog onClose={ () => { props.setPermsOpen( false ); } } open={ props.isPermsOpen } scroll="body" maxWidth="md"
            components={{ Backdrop: StyledBackdrop }}
            PaperProps={{ elevation: 0 }}>

            <SettingPage
                heading={ props.title }
                subheading={ props.description }>

                <FormikProvider value={ formik }>
                    <form onSubmit={ formik.handleSubmit } onReset={ formik.handleReset } autoComplete="off">

                        <SettingSection title="Channel Permissions">

                            <InputPermissionRadio
                                title={ permissionsConfig.details.CREATE_INSTANT_INVITE.title }
                                name="CREATE_INSTANT_INVITE"
                                description={ permissionsConfig.details.CREATE_INSTANT_INVITE.description }
                                value={ formik.values.CREATE_INSTANT_INVITE } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.VIEW_CHANNEL.title }
                                name="VIEW_CHANNEL"
                                description={ permissionsConfig.details.VIEW_CHANNEL.description }
                                value={ formik.values.VIEW_CHANNEL } />

                        </SettingSection>

                        <SettingSection title="Voice Permissions">

                            <InputPermissionRadio
                                title={ permissionsConfig.details.CONNECT.title }
                                name="CONNECT"
                                description={ permissionsConfig.details.CONNECT.description }
                                value={ formik.values.CONNECT } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.SPEAK.title }
                                name="SPEAK"
                                description={ permissionsConfig.details.SPEAK.description }
                                value={ formik.values.SPEAK } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.USE_VAD.title }
                                name="USE_VAD"
                                description={ permissionsConfig.details.USE_VAD.description }
                                value={ formik.values.USE_VAD } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.STREAM.title }
                                name="STREAM"
                                description={ permissionsConfig.details.STREAM.description }
                                value={ formik.values.STREAM } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.PRIORITY_SPEAKER.title }
                                name="PRIORITY_SPEAKER"
                                description={ permissionsConfig.details.PRIORITY_SPEAKER.description }
                                value={ formik.values.PRIORITY_SPEAKER } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.MOVE_MEMBERS.title }
                                name="MOVE_MEMBERS"
                                description={ permissionsConfig.details.MOVE_MEMBERS.description }
                                value={ formik.values.MOVE_MEMBERS } />

                        </SettingSection>

                        <SettingSection title="Text Permissions">

                            <InputPermissionRadio
                                title={ permissionsConfig.details.SEND_MESSAGES.title }
                                name="SEND_MESSAGES"
                                description={ permissionsConfig.details.SEND_MESSAGES.description }
                                value={ formik.values.SEND_MESSAGES } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.READ_MESSAGE_HISTORY.title }
                                name="READ_MESSAGE_HISTORY"
                                description={ permissionsConfig.details.READ_MESSAGE_HISTORY.description }
                                value={ formik.values.READ_MESSAGE_HISTORY } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.MANAGE_MESSAGES.title }
                                name="MANAGE_MESSAGES"
                                description={ permissionsConfig.details.MANAGE_MESSAGES.description }
                                value={ formik.values.MANAGE_MESSAGES } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.EMBED_LINKS.title }
                                name="EMBED_LINKS"
                                description={ permissionsConfig.details.EMBED_LINKS.description }
                                value={ formik.values.EMBED_LINKS } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.ATTACH_FILES.title }
                                name="ATTACH_FILES"
                                description={ permissionsConfig.details.ATTACH_FILES.description }
                                value={ formik.values.ATTACH_FILES } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.ADD_REACTIONS.title }
                                name="ADD_REACTIONS"
                                description={ permissionsConfig.details.ADD_REACTIONS.description }
                                value={ formik.values.ADD_REACTIONS } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.USE_APPLICATION_COMMANDS.title }
                                name="USE_APPLICATION_COMMANDS"
                                description={ permissionsConfig.details.USE_APPLICATION_COMMANDS.description }
                                value={ formik.values.USE_APPLICATION_COMMANDS } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.USE_EXTERNAL_EMOJIS.title }
                                name="USE_EXTERNAL_EMOJIS"
                                description={ permissionsConfig.details.USE_EXTERNAL_EMOJIS.description }
                                value={ formik.values.USE_EXTERNAL_EMOJIS } />

                            <InputPermissionRadio
                                title={ permissionsConfig.details.USE_EXTERNAL_STICKERS.title }
                                name="USE_EXTERNAL_STICKERS"
                                description={ permissionsConfig.details.USE_EXTERNAL_STICKERS.description }
                                value={ formik.values.USE_EXTERNAL_STICKERS } />

                        </SettingSection>

                        <FormFooter ready={ formik.dirty && formik.isValid } success={ props.success } setSuccess={ props.setSuccess } updating={ props.updating } error={ props.error } />

                    </form>
                </FormikProvider>

            </SettingPage>

        </Dialog>
    );

};
