/**
 * InputTextSetting Component
 *
 * /components/InputTextSetting/InputTextSetting.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { HTMLInputTypeAttribute } from "react";
import { InputAdornment } from "@mui/material";

import { SettingWrapper } from "../SettingWrapper/SettingWrapper";

import { StyledTextField } from "../../custom/StyledTextField/StyledTextField";

import { SettingCaption } from "../../font/SettingCaption";

type InputTextSettingProps = {
    title : string;
    name : string;
    description : string;
    metaData : string[];
    value : string;
    type : HTMLInputTypeAttribute;
    error ?: string;
    handleChange : ( e : React.ChangeEvent ) => void;
    startAdornment ?: string;
    endAdornment ?: string;
};

/**
 * Define the InputTextSetting Component
 * @param props The InputTextSetting props
 * @returns InputTextSetting Component
 */
export const InputTextSetting = ( props : InputTextSettingProps ) => {

    // Display the component
    return (
        <SettingWrapper title={ props.title } description={ props.description } metaData={ props.metaData } error={ props.error }>

            {/* Display text field */}
            <StyledTextField id={ props.name } name={ props.name }
                onChange={ props.handleChange }
                value={ props.value }
                type={ props.type }

                // Display field adornments
                InputProps={{
                    startAdornment: props.startAdornment
                        ? <InputAdornment position="start">
                            <SettingCaption textTransform="uppercase">{ props.startAdornment }</SettingCaption>
                        </InputAdornment>
                        : undefined,
                    endAdornment: props.endAdornment
                        ? <InputAdornment position="end">
                            <SettingCaption textTransform="uppercase">{ props.endAdornment }</SettingCaption>
                        </InputAdornment>
                        : undefined
                }} />

        </SettingWrapper>
    );

};
