/**
 * ServerMenuItem
 *
 * /components/ServerMenu/ServerMenuItem.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { NavLink } from "react-router-dom";
import { ReactElement } from "react";

import { StyledTreeItem } from "../../custom/StyledTreeItem/StyledTreeItem";

import { BodyText } from "../../font/BodyText";

type ServerMenuItemProps = {
    index : number;
    link : string;
    label : string;
    icon : ReactElement;
    children ?: JSX.Element[];
};

/**
 * Define the ServerMenuItem Component
 * @param props The ServerMenuItem props
 * @returns ServerMenuItem Component
 */
export const ServerMenuItem = ( props : ServerMenuItemProps ) => {

    // Display the component
    return (
        <NavLink to={ props.link } style={{ textDecoration: "none" }} children={ ( { isActive } ) =>
            <StyledTreeItem nodeId={ `${ props.index }` } label={ <BodyText color="inherit !important">{ props.label }</BodyText> } icon={ props.icon } children={ props.children } className={ isActive ? "active" : "inactive" } />
        } />
    );

};
