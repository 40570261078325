/**
 * AppraisalCard Component
 *
 * /components/AppraisalCard/AppraisalCard.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Box } from "@mui/material";

import { SettingTitle } from "../../font/SettingTitle";
import { BodyText } from "../../font/BodyText";

import { theme } from "../../theme/theme";

type AppraisalCardProps = {
    text : string;
    server : string;
};

/**
 * Define the AppraisalCard Component
 * @param props The AppraisalCard props
 * @returns AppraisalCard Component
 */
export const AppraisalCard = ( props : AppraisalCardProps ) => {

    // Display the component
    return (
        <Box border={ `1px solid ${ theme.palette.divider }`} borderRadius="10px" padding={ theme.spacing( 2 ) }>
            <BodyText>{ props.text }</BodyText>
            <SettingTitle textAlign="right" paddingTop={ theme.spacing( 1 ) }>{ props.server }</SettingTitle>
        </Box>
    );

};
