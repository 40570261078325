/**
 * useServer Hook
 * For handling server settings
 *
 * /hooks/useServer.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { createContext, useContext, useState } from "react";

import { ServerSettingsResponse } from "../types/responses/ServerSettingsResponse";

interface ServerProviderProps {
    children : React.ReactElement
}

interface IServerContext {
    server : ServerSettingsResponse | null;
    setServer : ( value : ServerSettingsResponse | null ) => void;
}

/**
 * Define the Server Context
 */
export const ServerContext = createContext<IServerContext>( {} as IServerContext );

/**
 * Define the Server Provider
 * @returns Server Provider
 */
export const ServerProvider = ( { children } : ServerProviderProps ) => {

    // Define relevant data
    const [ server, setServer ] = useState<ServerSettingsResponse | null>( null );

    // Display the component
    return (
        <ServerContext.Provider value={ { server, setServer } }>
            { children }
        </ServerContext.Provider>
    );

};

/**
 * Define the useServer Hook
 * @returns useServer Hook
 */
export const useServer = () => {
    return useContext( ServerContext );
};
