/**
 * LogoutPage
 *
 * /pages/LogoutPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";

import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";

import { Section } from "../../layout/Section/Section";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { LoadingCircle } from "../../components/LoadingCircle/LoadingCircle";

import { authRequest } from "../../utils/API";

/**
 * Define the LogoutPage Component
 * @returns LogoutPage Component
 */
export const LogoutPage = () => {

    // Define relevant data
    const { setAuth } = useAuth();
    const [ error, setError ] = useState( "" );

    // Invalidate refresh token
    useEffect( () => {
        authRequest.post( "/logout" ).then( () => {
            setAuth( null );
            localStorage.clear();
            window.location.replace( "/" );
        } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
            setError( e.response?.data?.message || "Failed to log out." );
        } );
    }, [] );

    // Display the page
    return <Section>
        { error
            ? <ErrorCircle title={ error } />
            : <LoadingCircle title="Logging out..." /> }
    </Section>;

};
