/**
 * Links Utility
 * For handling link generation
 *
 * /utils/Links.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { globalConfig } from "../config/global.config";

/**
 * Generate a discord login url
 * @param redirectOrigin The hostname location for the website redirect
 * @returns The login url
 */
export const getLoginUrl = ( redirectOrigin : string ) => {

    return "https://discordapp.com/api/oauth2/authorize?" + new URLSearchParams( {
        response_type: "code",
        client_id: globalConfig.clientId,
        scope: globalConfig.loginScope,
        state: localStorage.getItem( "VB_STATE" ) || "",
        redirect_uri: `${ redirectOrigin }/login`
    } ).toString();

};

/**
 * Generate a discord invite url
 * @returns The invite url
 */
export const getInviteUrl = () => {
    return "https://discord.com/api/oauth2/authorize?" + new URLSearchParams( {
        client_id: globalConfig.clientId,
        scope: globalConfig.inviteScope,
        permissions: "5087442628417"
    } );
};
