/**
 * SystemPermissionsPage Component
 *
 * /pages/ConfigurePage/SystemPermissionsPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import { useServer } from "../../hooks/useServer";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { SettingSection } from "../../components/SettingSection/SettingSection";
import { SettingPage } from "../../components/SettingPage/SettingPage";
import { EditFixedPermissions } from "../../components/EditFixedPermissions/EditFixedPermissions";
import { CreateRolePermissions } from "../../components/CreateRolePermissions/CreateRolePermissions";
import { EditRolePermissions } from "../../components/EditRolePermissions/EditRolePermissions";

import { settingsConfig } from "../../config/settings.config";

/**
 * Define the SystemPermissionsPage Component
 * @returns SystemPermissionsPage Component
 */
export const SystemPermissionsPage = () => {

    // Define relevant data
    const { systemNumber } = useParams();
    const { server } = useServer();
    const [ roleCount, setRoleCount ] = useState( 0 );
    const [ roleSettings, setRoleSettings ] = useState<JSX.Element[]>( [] );
    const [ state, updateState ] = useState( 0 );
    const forceUpdate = useCallback( () => {
        updateState( Date.now() );
    }, [] );

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    const systemSettings = systemCheck;

    // Handle system number change
    useEffect( () => {
        forceUpdate();
    }, [ systemNumber ] );

    // Handle role permissions
    useEffect( () => {

        // Create list of role settings
        setRoleSettings( Object.keys( systemSettings.rolePerms ).map( ( value, index ) => {
            return <EditRolePermissions
                key={ index }
                title={ `Role: ${ server.roles[ value ]?.name }` }
                name={ `rolePerms-${ index }` }
                description={ `Role ID: ${ server.roles[ value ]?.id }` }
                metaData={ settingsConfig.permissions.settings.createRolePermissions.metaData }
                roleId={ value }
                permsValue={ systemSettings.rolePerms[ value ]?.value || {} }
                setRoleCount={ setRoleCount } />;
        } ) );

    }, [ systemNumber, roleCount ] );

    // Display the page
    return (

        <SettingPage
            key={ state }
            heading={ `System #${ systemNumber } Permissions` }
            subheading={ `${ systemSettings.systemName } System` }>

            <SettingSection title="Public Permissions">

                <EditFixedPermissions
                    title={ settingsConfig.permissions.settings.unlockedPerms.title }
                    name="unlockedPerms"
                    description={ settingsConfig.permissions.settings.unlockedPerms.description }
                    metaData={ settingsConfig.permissions.settings.unlockedPerms.metaData }
                    permsValue={ systemSettings.unlockedPerms } />

                <EditFixedPermissions
                    title={ settingsConfig.permissions.settings.lockedPerms.title }
                    name="lockedPerms"
                    description={ settingsConfig.permissions.settings.lockedPerms.description }
                    metaData={ settingsConfig.permissions.settings.lockedPerms.metaData }
                    permsValue={ systemSettings.lockedPerms } />

            </SettingSection>

            <SettingSection title="User Permissions">

                <EditFixedPermissions
                    title={ settingsConfig.permissions.settings.ownerPerms.title }
                    name="ownerPerms"
                    description={ settingsConfig.permissions.settings.ownerPerms.description }
                    metaData={ settingsConfig.permissions.settings.ownerPerms.metaData }
                    permsValue={ systemSettings.ownerPerms } />

                <EditFixedPermissions
                    title={ settingsConfig.permissions.settings.grantedPerms.title }
                    name="grantedPerms"
                    description={ settingsConfig.permissions.settings.grantedPerms.description }
                    metaData={ settingsConfig.permissions.settings.grantedPerms.metaData }
                    permsValue={ systemSettings.grantedPerms } />

                <EditFixedPermissions
                    title={ settingsConfig.permissions.settings.deniedPerms.title }
                    name="deniedPerms"
                    description={ settingsConfig.permissions.settings.deniedPerms.description }
                    metaData={ settingsConfig.permissions.settings.deniedPerms.metaData }
                    permsValue={ systemSettings.deniedPerms } />

            </SettingSection>

            <SettingSection title="Role Permissions">

                <>{ roleSettings }</>

                <CreateRolePermissions
                    title={ settingsConfig.permissions.settings.createRolePermissions.title }
                    name="createRolePermissions"
                    description={ settingsConfig.permissions.settings.createRolePermissions.description }
                    metaData={ settingsConfig.permissions.settings.createRolePermissions.metaData }
                    setRoleCount={ setRoleCount } />

            </SettingSection>

        </SettingPage>

    );

};
