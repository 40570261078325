/**
 * InputRadioSelection Component
 *
 * /components/InputRadioSelection/InputRadioSelection.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { ChangeEvent } from "react";
import { FormControlLabel } from "@mui/material";
import { FormikErrors } from "formik";

import { SettingWrapper } from "../SettingWrapper/SettingWrapper";

import { StyledRadio } from "../../custom/StyledRadioGroup/StyledRadio";
import { StyledRadioGroup } from "../../custom/StyledRadioGroup/StyledRadioGroup";

import { SettingValue } from "../../font/SettingValue";
import { SettingBox } from "../SettingBox/SettingBox";

type InputRadioSelectionProps = {
    title : string;
    name : string;
    description : string;
    metaData : string[];
    value : string | number | boolean;
    error ?: string;
    setFieldValue : ( field : string, value : string | number | boolean, shouldValidate ?: boolean | undefined ) => Promise<void> | Promise<FormikErrors<unknown>>;
    options : {
        value : string | number | boolean;
        label : string;
    }[];
};

/**
 * Define the InputRadioSelection Component
 * @param props The InputRadioSelection props
 * @returns InputRadioSelection Component
 */
export const InputRadioSelection = ( props : InputRadioSelectionProps ) => {

    // Define change function
    const onChange = ( event : ChangeEvent<HTMLInputElement>, ) => {
        if ( [ "true", "false" ].includes( event.target.value ) ) {
            const value = event.target.value === "true";
            props.setFieldValue( props.name, value, true );
        }
    };

    // Display the component
    return (
        <SettingWrapper title={ props.title } description={ props.description } metaData={ props.metaData } error={ props.error }>

            <SettingBox>

                {/* Display radio selection */}
                <StyledRadioGroup name={ props.name } value={ props.value } onChange={ onChange }>

                    {/* Display radio options */}
                    {
                        props.options.map( ( entry, index ) => {
                            return <FormControlLabel key={ index } value={ entry.value } labelPlacement="end"
                                label={ <SettingValue>{ entry.label }</SettingValue> }
                                control={ <StyledRadio /> } />;
                        } )
                    }

                </StyledRadioGroup>

            </SettingBox>

        </SettingWrapper>
    );

};
