/**
 * StyledRadio Component
 *
 * /custom/StyledRadioGroup/StyledRadio.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Radio, RadioProps, radioClasses } from "@mui/material";

import { theme } from "../../theme/theme";

/**
 * Define the StyledRadio Component
 * @param props The StyledRadio props
 * @returns StyledRadio Component
 */
export const StyledRadio = styled( ( props : RadioProps ) => {

    return <Radio color="secondary" { ... props } />;

} )( () => ( {

    color: theme.palette.text.primary,
    margin: "0 5px",
    padding: 5,
    transition: "200ms",

    [ `&.${ radioClasses.checked }` ]: {
        color: theme.palette.text.primary
    }

} ) );
