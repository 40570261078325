/**
 * SystemDefaultsPage Component
 *
 * /pages/ConfigurePage/SystemDefaultsPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";
import { RequiredProperties } from "../../types/wrappers/RequiredProperties";
import { FormattedSystemDefaults } from "../../types/formatted/FormattedSystemDefaults";

import { useServer } from "../../hooks/useServer";
import { useRequest } from "../../hooks/useRequest";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { InputTextSetting } from "../../components/InputTextSetting/InputTextSetting";
import { SettingSection } from "../../components/SettingSection/SettingSection";
import { SettingPage } from "../../components/SettingPage/SettingPage";
import { FormFooter } from "../../components/FormFooter/FormFooter";
import { InputRadioSelection } from "../../components/InputRadioSelection/InputRadioSelection";

import { settingsConfig } from "../../config/settings.config";

import { DefaultsSchema } from "../../schemas/defaults.schema";

/**
 * Define the SystemDefaultsPage Component
 * @returns SystemDefaultsPage Component
 */
export const SystemDefaultsPage = () => {

    // Define relevant data
    const request = useRequest();
    const { systemNumber } = useParams();
    const { server, setServer } = useServer();
    const [ updating, setUpdating ] = useState( false );
    const [ success, setSuccess ] = useState( false );
    const [ error, setError ] = useState( "" );

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    let systemSettings = systemCheck;

    // Define formik form and validation
    const formik = useFormik<RequiredProperties<FormattedSystemDefaults>>( {
        enableReinitialize: true,
        initialValues: {
            defaultChannelName: systemSettings.defaultChannelName
                ?? ( server.premium ? settingsConfig.defaults.settings.defaultChannelName.default.premium : settingsConfig.defaults.settings.defaultChannelName.default.free ),
            defaultChannelUserLimit: systemSettings.defaultChannelUserLimit
                ?? ( server.premium ? settingsConfig.defaults.settings.defaultChannelUserLimit.default.premium : settingsConfig.defaults.settings.defaultChannelUserLimit.default.free ),
            defaultChannelBitrate: systemSettings.defaultChannelBitrate
                ?? ( server.premium ? settingsConfig.defaults.settings.defaultChannelBitrate.default.premium : settingsConfig.defaults.settings.defaultChannelBitrate.default.free ),
            defaultChannelState: systemSettings.defaultChannelState
                ?? ( server.premium ? settingsConfig.defaults.settings.defaultChannelState.default.premium : settingsConfig.defaults.settings.defaultChannelState.default.free )
        },
        validationSchema: DefaultsSchema(),
        onSubmit: ( values ) => {
            setUpdating( true );
            setError( "" );
            request.post( `/server/${ server.id }/system/${ systemNumber }/defaults`, values ).then( () => {
                systemSettings = { ... systemSettings, ... values };
                server.settings.systems[ systemNumber ] = systemSettings;
                setServer( server );
                setUpdating( false );
                setSuccess( true );
            } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
                setUpdating( false );
                setSuccess( false );
                setError( e.response?.data?.message ?? "Failed to update system." );
            } );
        }
    } );

    // Display the page
    return (

        <SettingPage
            heading={ `System #${ systemNumber } Defaults` }
            subheading={ `${ systemSettings.systemName } System` }>

            <FormikProvider value={ formik }>
                <form onSubmit={ formik.handleSubmit } onReset={ formik.handleReset } autoComplete="off">

                    <SettingSection title="Text Settings">

                        <InputTextSetting
                            title={ settingsConfig.defaults.settings.defaultChannelName.title }
                            name="defaultChannelName"
                            description={ settingsConfig.defaults.settings.defaultChannelName.description }
                            metaData={ settingsConfig.defaults.settings.defaultChannelName.metaData }
                            value={ formik.values.defaultChannelName }
                            error={ formik.errors.defaultChannelName }
                            type="text"
                            handleChange={ formik.handleChange } />

                    </SettingSection>

                    <SettingSection title="Number Settings">

                        <InputTextSetting
                            title={ settingsConfig.defaults.settings.defaultChannelUserLimit.title }
                            name="defaultChannelUserLimit"
                            description={ settingsConfig.defaults.settings.defaultChannelUserLimit.description }
                            metaData={ settingsConfig.defaults.settings.defaultChannelUserLimit.metaData }
                            value={ `${ formik.values.defaultChannelUserLimit }` }
                            error={ formik.errors.defaultChannelUserLimit }
                            type="number"
                            handleChange={ formik.handleChange }
                            endAdornment="users" />

                        <InputTextSetting
                            title={ settingsConfig.defaults.settings.defaultChannelBitrate.title }
                            name="defaultChannelBitrate"
                            description={ settingsConfig.defaults.settings.defaultChannelBitrate.description }
                            metaData={ settingsConfig.defaults.settings.defaultChannelBitrate.metaData }
                            value={ `${ formik.values.defaultChannelBitrate }` }
                            error={ formik.errors.defaultChannelBitrate }
                            type="number"
                            handleChange={ formik.handleChange }
                            endAdornment="kbps" />

                    </SettingSection>

                    <SettingSection title="Toggle Settings">

                        <InputRadioSelection
                            title={ settingsConfig.defaults.settings.defaultChannelState.title }
                            name="defaultChannelState"
                            description={ settingsConfig.defaults.settings.defaultChannelState.description }
                            metaData={ settingsConfig.defaults.settings.defaultChannelState.metaData }
                            value={ formik.values.defaultChannelState }
                            error={ formik.errors.defaultChannelState }
                            setFieldValue={ formik.setFieldValue }
                            options={ [
                                { label: settingsConfig.defaults.settings.defaultChannelState.trueText, value: true },
                                { label: settingsConfig.defaults.settings.defaultChannelState.falseText, value: false }
                            ] } />

                    </SettingSection>

                    <FormFooter ready={ formik.dirty && formik.isValid } success={ success } setSuccess={ setSuccess } updating={ updating } error={ error } />

                </form>
            </FormikProvider>

        </SettingPage>

    );

};
