/**
 * System Basics Schema
 *
 * /schemas/basics.schema.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import * as yup from "yup";

import { settingsConfig } from "../config/settings.config";

import { FormattedSystemBasics } from "../types/formatted/FormattedSystemBasics";

/**
 * Define the Basics Schema
 * @param usedNames A list of used system names
 * @param usedChannels A list of used create channels
 * @returns Basics Schema
 */
export const BasicsSchema = ( usedNames : string[], usedChannels : string[] ) => {
    return yup.object<Partial<Record<keyof FormattedSystemBasics, yup.AnySchema>>>( {

        systemName: yup.string()
            .required( `A valid '${ settingsConfig.basics.settings.systemName.title }' value is required.` )
            .typeError( "Must be a string." )
            .min( settingsConfig.basics.settings.systemName.minLength, `Must be at least '${ settingsConfig.basics.settings.systemName.minLength }' characters long.` )
            .max( settingsConfig.basics.settings.systemName.maxLength, `Must be at most '${ settingsConfig.basics.settings.systemName.maxLength }' characters long.` )
            .notOneOf( usedNames, `This '${ settingsConfig.basics.settings.systemName.title }' value is already in use.` ),

        createChannel: yup.string()
            .required( `A valid '${ settingsConfig.basics.settings.createChannel.title }' value is required.` )
            .typeError( "Must be a string." )
            .min( settingsConfig.basics.settings.createChannel.minLength, `Must be at least '${ settingsConfig.basics.settings.createChannel.minLength }' characters long.` )
            .max( settingsConfig.basics.settings.createChannel.maxLength, `Must be at most '${ settingsConfig.basics.settings.createChannel.maxLength }' characters long.` )
            .notOneOf( usedChannels, `This '${ settingsConfig.basics.settings.createChannel.title }' is already in use.` )
            .matches( /^\d+$/g, "Must be numeric." ),

        parentCategory: yup.string()
            .required( `A valid '${ settingsConfig.basics.settings.parentCategory.title }' value is required.` )
            .typeError( "Must be a string." )
            .min( settingsConfig.basics.settings.parentCategory.minLength, `Must be at least '${ settingsConfig.basics.settings.parentCategory.minLength }' characters long.` )
            .max( settingsConfig.basics.settings.parentCategory.maxLength, `Must be at most '${ settingsConfig.basics.settings.parentCategory.maxLength }' characters long.` )
            .matches( /^\d+$/g, "Must be numeric." ),

        publicRole: yup.string()
            .required( `A valid '${ settingsConfig.basics.settings.publicRole.title }' value is required.` )
            .typeError( "Must be a string." )
            .min( settingsConfig.basics.settings.publicRole.minLength, `Must be at least '${ settingsConfig.basics.settings.publicRole.minLength }' characters long.` )
            .max( settingsConfig.basics.settings.publicRole.maxLength, `Must be at most '${ settingsConfig.basics.settings.publicRole.maxLength }' characters long.` )

    } );
};
