/**
 * BodyText Component
 *
 * /font/BodyText.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Typography, TypographyProps } from "@mui/material";

import { theme } from "../theme/theme";

/**
 * Define the BodyText Component
 * @param props The BodyText props
 * @returns BodyText Component
 */
export const BodyText = styled( ( props : TypographyProps ) => {

    return <Typography variant="body2" { ... props } />;

} )( () => ( {

    color: theme.palette.text.secondary

} ) );
