/**
 * RequireServers Component
 *
 * /providers/RequireServers.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { useRequest } from "../hooks/useRequest";
import { useServers } from "../hooks/useServers";

import { UserServersResponse } from "../types/responses/UserServersResponse";
import { ErrorMessageResponse } from "../types/responses/ErrorMessageResponse";

import { Section } from "../layout/Section/Section";

import { ErrorCircle } from "../components/ErrorCircle/ErrorCircle";
import { LoadingCircle } from "../components/LoadingCircle/LoadingCircle";

/**
 * Define the RequireServers Component
 * @returns RequireServers Component
 */
export const RequireServers = () => {

    // Define relevant data
    const request = useRequest();
    const [ loaded, setLoaded ] = useState( false );
    const [ error, setError ] = useState( "" );
    const { setServers } = useServers();

    // Get all user servers
    useEffect( () => {
        request.get( "/servers" ).then( ( serversResponse : AxiosResponse<UserServersResponse> ) => {
            setServers( serversResponse.data );
            setLoaded( true );
        } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
            const errorContent = e.response?.data;
            setError( errorContent?.message || "Failed to load servers." );
        } );
    }, [] );

    // Display the component
    return (
        error
            ? <Section><ErrorCircle title={ error } /></Section>
            : loaded
                ? <Outlet />
                : <Section><LoadingCircle title="Loading user servers..."/></Section>
    );

};
