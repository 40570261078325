/**
 * useServers Hook
 * For handling user servers
 *
 * /hooks/useServers.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { createContext, useContext, useState } from "react";

import { UserServersResponse } from "../types/responses/UserServersResponse";

interface ServersProviderProps {
    children : React.ReactElement
}

interface IServersContext {
    servers : UserServersResponse | null;
    setServers : ( value : UserServersResponse | null ) => void;
}

/**
 * Define the Servers Context
 */
export const ServersContext = createContext<IServersContext>( {} as IServersContext );

/**
 * Define the Servers Provider
 * @returns Servers Provider
 */
export const ServersProvider = ( { children } : ServersProviderProps ) => {

    // Define relevant data
    const [ servers, setServers ] = useState<UserServersResponse | null>( null );

    // Display the component
    return (
        <ServersContext.Provider value={ { servers, setServers } }>
            { children }
        </ServersContext.Provider>
    );

};

/**
 * Define the useServers Hook
 * @returns useServers Hook
 */
export const useServers = () => {
    return useContext( ServersContext );
};
