/// <reference types="react-scripts" />

/**
 * Root File
 * For starting the website
 *
 * /index.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { ThemeProvider } from "@mui/material";
import { createRoot } from "react-dom/client";

import { App } from "./App";

import { theme } from "./theme/theme";

import { AuthProvider } from "./hooks/useAuth";
import { ServersProvider } from "./hooks/useServers";
import { ServerProvider } from "./hooks/useServer";

// Get the root container
const container = document.getElementById( "root" );

// Display the application
if ( container ) {
    createRoot( container ).render(
        <ThemeProvider theme={ theme }>
            <AuthProvider>
                <ServersProvider>
                    <ServerProvider>
                        <App />
                    </ServerProvider>
                </ServersProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}
