/**
 * PricingCard Component
 *
 * /components/PricingCard/PricingCard.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Grid, Box, Divider, Stack, alpha } from "@mui/material";

import { Heading } from "../../font/Heading";
import { SubHeading } from "../../font/SubHeading";
import { KickerText } from "../../font/KickerText";
import { SubTitle } from "../../font/SubTitle";

import { theme } from "../../theme/theme";

type PricingCardProps = {
    title : string;
    months : number;
    price : number;
    discount : number;
};

/**
 * Define the PricingCard Component
 * @param props The PricingCard props
 * @returns PricingCard Component
 */
export const PricingCard = ( props : PricingCardProps ) => {

    const price = props.price * props.months;
    const discount = ( 100 - props.discount ) / 100;

    // Display the component
    return (
        <Grid item md={ 4 } xs={ 12 }>
            <Box bgcolor={ alpha( theme.palette.divider, 0.2 ) } borderRadius="5px" padding={ theme.spacing( 2 ) }>
                <Grid container spacing={ 1 }>

                    {/* Display title */}
                    <Grid item xs={ 6 }>
                        <SubHeading>{ `${ props.months } Month${ props.months > 1 ? "s" : "" }` }</SubHeading>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <SubTitle textAlign="right" color={ `${ props.discount ? theme.palette.success.main : theme.palette.error.main } !important` }>
                            { props.discount ? `SAVE ${ props.discount }%` : "NO SAVING" }
                        </SubTitle>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <Divider />
                    </Grid>

                    {/* Display price and discount values */}
                    <Grid item xs={ 12 }>
                        <Stack direction="row" justifyContent="center" spacing={ 1 }>
                            { props.discount > 0 && <KickerText display="inline-block" textAlign="center"
                                color={ `${ theme.palette.grey[ 900 ] } !important` }
                                sx={{
                                    position: "relative",
                                    "&:before": {
                                        position: "absolute",
                                        content: "''",
                                        left: 0,
                                        top: "38%",
                                        right: 0,
                                        borderTop: `1px solid ${ theme.palette.error.main }`,
                                        transform: "rotate(-25deg)"
                                    }
                                }}>
                                { `£${ price }` }
                            </KickerText> }
                            <Heading display="inline-block" textAlign="center">
                                { `£${ price * discount }` }
                            </Heading>
                        </Stack>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <Divider />
                    </Grid>

                    {/* Display breakdown */}
                    <Grid item xs={ 6 }>
                        <SubTitle>{ props.title }</SubTitle>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <SubTitle textAlign="right">{ `= £${ ( props.price * discount ).toFixed( 2 ) } / Month` }</SubTitle>
                    </Grid>

                </Grid>
            </Box>
        </Grid>
    );

};
