/**
 * ConfigurePage
 *
 * /pages/ConfigurePage/ConfigurePage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { alpha, Box, Toolbar, Stack, IconButton, useMediaQuery, Divider } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import { Section } from "../../layout/Section/Section";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { ServerIcon } from "../../components/ServerIcon/ServerIcon";
import { BreadcrumbTrail } from "../../components/BreadcrumbTrail/BreadcrumbTrail";
import { ServerMenu } from "../../components/ServerMenu/ServerMenu";

import { SubHeading } from "../../font/SubHeading";
import { BodyText } from "../../font/BodyText";

import { useServer } from "../../hooks/useServer";
import { useAuth } from "../../hooks/useAuth";

import { theme } from "../../theme/theme";

/**
 * Define the ConfigurePage Component
 * @returns ConfigurePage Component
 */
export const ConfigurePage = () => {

    // Define relevant data
    const { auth } = useAuth();
    const { server } = useServer();
    const location = useLocation();
    const isNarrow = useMediaQuery( theme.breakpoints.down( "md" ) );
    const [ menuOpen, setMenuOpen ] = useState( ! isNarrow );
    const menuWidth = isNarrow ? "240px" : "260px";

    // Update menu based on window width
    useEffect( () => {
        if ( isNarrow ) { menuOpen && setMenuOpen( false ); }
        else { ! menuOpen && setMenuOpen( true ); }
    }, [ isNarrow, location.pathname ] );

    // Verify auth
    if ( ! auth ) {
        return (
            <Section><ErrorCircle title="Failed to load user." /></Section>
        );
    }

    // Verify server
    if ( ! server ) {
        return (
            <Section><ErrorCircle title="Failed to load server settings." /></Section>
        );
    }

    // Display the page
    return (
        <Box>
            <Section>

                {/* Display page header */}
                <Toolbar disableGutters>
                    <IconButton onClick={ () => { setMenuOpen( ! menuOpen ); } } sx={{ margin: "5px" }}>
                        { menuOpen ? <FirstPageIcon /> : <LastPageIcon /> }
                    </IconButton>
                    <ServerIcon id={ server.id } name={ server.name } icon={ server.icon } size={ isNarrow ? 80 : 100 } scale={ 128 } />
                    <Stack direction="column" paddingLeft={ isNarrow ? theme.spacing( 2 ) : theme.spacing( 4 ) }>
                        <SubHeading>{ server.name }</SubHeading>
                        <BodyText>{ server.premium ? "Premium Access" : "Basic Access" }</BodyText>
                    </Stack>
                </Toolbar>

                <Box>

                    {/* Display breadcrumb trail */}
                    <BreadcrumbTrail />

                    {/* Display page contents */}
                    <Stack direction="row" width="100%" sx={{ overflowX: "hidden", position: "relative" }}>

                        {/* Display server settings menu */}
                        <Box maxWidth={ menuOpen ? menuWidth : "0px" } minWidth={ menuOpen ? menuWidth : "0px" } sx={{ overflowX: "hidden", transition: "200ms" }}>
                            <Box textAlign="right" width="100%" bgcolor={ alpha( theme.palette.divider, 0.2 ) }>
                                <IconButton onClick={ () => { setMenuOpen( ! menuOpen ); } } sx={{ margin: "5px" }}>
                                    <FirstPageIcon />
                                </IconButton>
                            </Box>
                            <Divider />
                            <ServerMenu auth={ auth } server={ server } width={ menuWidth } />
                        </Box>

                        {/* Display server settings page */}
                        <Box borderLeft={ menuOpen ? `1px solid ${ theme.palette.divider }` : "none" }
                            minWidth={ `calc(100% - ${ menuOpen ? isNarrow ? "0px" : menuWidth : "0px" })` }
                            sx={{ boxShadow: menuOpen ? isNarrow ? `inset 7px 0 9px -7px ${ theme.palette.background.paper }` : "none" : "none", transition: "200ms" }}>

                            <Box sx={{ transition: "200ms", opacity: menuOpen ? isNarrow ? 0.2 : 1 : 1 }}>
                                <Outlet />
                            </Box>

                        </Box>

                    </Stack>

                </Box>

            </Section>
        </Box>
    );

};
