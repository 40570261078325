/**
 * Heading Component
 *
 * /font/Heading.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Typography, TypographyProps } from "@mui/material";

import { theme } from "../theme/theme";

/**
 * Define the Heading Component
 * @param props The Heading props
 * @returns Heading Component
 */
export const Heading = styled( ( props : TypographyProps ) => {

    return <Typography variant="h1" { ... props } />;

} )( () => ( {

    color: theme.palette.text.primary

} ) );
