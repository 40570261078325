/**
 * Hero Component
 *
 * /components/Hero/Hero.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useMediaQuery, Box, Grid, Stack, Toolbar } from "@mui/material";

import { KickerText } from "../../font/KickerText";
import { Heading } from "../../font/Heading";
import { SubTitle } from "../../font/SubTitle";

import { theme } from "../../theme/theme";

type HeroProps = {
    kicker ?: string;
    title : string;
    subtitle : string;
    image ?: string;
    buttons ?: JSX.Element[];
    flipped ?: boolean;
};

/**
 * Define the Hero Component
 * @param props The Hero props
 * @returns Hero Component
 */
export const Hero = ( props : HeroProps ) => {

    // Define relevant data
    const isNarrow = useMediaQuery( theme.breakpoints.down( "md" ) );
    const hasImage = Boolean( props.image );
    const alignContent = isNarrow ? "left" : props.flipped ? "right" : "left";

    // Display the component
    return (
        <Grid container spacing={ 4 }>

            <Grid item md={ hasImage ? 6 : 12 } xs={ 12 } order={{ xs: props.flipped && ! isNarrow ? 2 : 1 }}>
                <Toolbar sx={{ height: "100%", width: "100%" }} disableGutters>
                    <Box width="100%">

                        {/* Display hero text */}
                        { props.kicker && <KickerText textAlign={ hasImage ? alignContent : "center" }>{ props.kicker } </KickerText> }
                        <Heading textAlign={ hasImage ? alignContent : "center" }>{ props.title }</Heading>
                        <SubTitle padding={ `${ theme.spacing( isNarrow ? 2 : 4 ) } 0 0` } textAlign={ hasImage ? alignContent : "center" }>
                            { props.subtitle }
                        </SubTitle>

                        {/* Display hero buttons */}
                        { props.buttons && <Grid container spacing={ 2 } justifyContent={ hasImage ? alignContent : "center" } paddingTop={ theme.spacing( isNarrow ? 2 : 4 ) }>
                            { props.buttons.map( ( element, index ) => {
                                return <Grid item key={ index }>{ element }</Grid>;
                            } ) }
                        </Grid> }

                    </Box>
                </Toolbar>
            </Grid>

            {/* Display hero image */}
            { hasImage && <Grid item md={ 6 } xs={ 12 } order={{ xs: props.flipped && ! isNarrow ? 1 : 2 }}>
                <Toolbar sx={{ height: "100%", width: "100%" }} disableGutters>
                    <Stack justifyContent={ isNarrow ? "center" : alignContent } sx={{ flexGrow: 1 }}>
                        <Box overflow="hidden" width="100%"
                            bgcolor={ theme.palette.grey[ 800 ] }
                            border={ `2px solid ${ theme.palette.grey[ 900 ] }` } borderRadius="15px">
                            <img src={ props.image } style={{ verticalAlign: "bottom" }} width="100%" />
                        </Box>
                    </Stack>
                </Toolbar>
            </Grid> }

        </Grid>
    );

};
