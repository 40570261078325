/**
 * StyledToggleButtonGroup Component
 *
 * /custom/StyledToggleButtonGroup/StyledToggleButtonGroup.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import { FieldProps } from "formik";

export interface StyledToggleButtonGroupProps extends FieldProps, Omit<ToggleButtonGroupProps, "name" | "onChange" | "value" | "defaultValue"> {}

/**
 * Define the StyledToggleButtonGroup Component
 * @param props The StyledToggleButtonGroup props
 * @returns StyledToggleButtonGroup Component
 */
export const StyledToggleButtonGroup = styled( ( props : StyledToggleButtonGroupProps ) => {

    return <ToggleButtonGroup size="small" exclusive { ... props } onChange={ ( e, value ) => {
        props.form.setFieldValue( props.field.name, value ?? 0 );
    } } />;

} )( () => ( {

} ) );
