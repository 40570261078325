/**
 * StyledToggleButton Component
 *
 * /custom/StyledToggleButtonGroup/StyledToggleButton.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, ToggleButton, toggleButtonClasses, ToggleButtonProps } from "@mui/material";

/**
 * Define the StyledToggleButton Component
 * @param props The StyledToggleButton props
 * @returns StyledToggleButton Component
 */
export const StyledToggleButton = styled( ( props : ToggleButtonProps ) => {

    return <ToggleButton size="small" { ... props } />;

} )( () => ( {

    [ `&.${ toggleButtonClasses.selected }` ]: {
        opacity: 1
    }

} ) );
