/**
 * StyledBackdrop Component
 *
 * /custom/StyledBackdrop/StyledBackdrop.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { alpha, styled, Backdrop, BackdropProps } from "@mui/material";

/**
 * Define the StyledBackdrop Component
 * @param props The StyledBackdrop props
 * @returns StyledBackdrop Component
 */
export const StyledBackdrop = styled( ( props : BackdropProps ) => {

    return <Backdrop { ... props } />;

} )( () => ( {

    backgroundColor: alpha( "#000", 0.8 )

} ) );
