/**
 * System Persistence Schema
 *
 * /schemas/persistence.schema.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import * as yup from "yup";

import { settingsConfig } from "../config/settings.config";

import { FormattedSystemPersistence } from "../types/formatted/FormattedSystemPersistence";

/**
 * Define the Persistence Schema
 * @returns Persistence Schema
 */
export const PersistenceSchema = () => {
    return yup.object<Partial<Record<keyof FormattedSystemPersistence, yup.AnySchema>>>( {

        saveChannelNames: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelNames.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelNamesDefault: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelNamesDefault.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelNamesOverride: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelNamesOverride.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelUserLimits: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelUserLimits.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelUserLimitsDefault: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelUserLimitsDefault.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelUserLimitsOverride: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelUserLimitsOverride.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelBitrates: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelBitrates.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelBitratesDefault: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelBitratesDefault.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelBitratesOverride: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelBitratesOverride.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelStates: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelStates.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelStatesDefault: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelStatesDefault.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveChannelStatesOverride: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveChannelStatesOverride.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveGrantedUsers: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveGrantedUsers.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveGrantedUsersDefault: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveGrantedUsersDefault.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveGrantedUsersOverride: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveGrantedUsersOverride.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveDeniedUsers: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveDeniedUsers.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveDeniedUsersDefault: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveDeniedUsersDefault.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        saveDeniedUsersOverride: yup.boolean()
            .required( `A valid '${ settingsConfig.persistence.settings.saveDeniedUsersOverride.title }' value is required.` )
            .typeError( "Must be a boolean." )

    } );
};
