/**
 * System Behaviour Schema
 *
 * /schemas/behaviour.schema.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import * as yup from "yup";

import { settingsConfig } from "../config/settings.config";

import { FormattedSystemBehaviour } from "../types/formatted/FormattedSystemBehaviour";

/**
 * Define the Behaviour Schema
 * @returns Behaviour Schema
 */
export const BehaviourSchema = () => {
    return yup.object<Partial<Record<keyof FormattedSystemBehaviour, yup.AnySchema>>>( {

        deleteChannelWhenEmpty: yup.boolean()
            .required( `A valid '${ settingsConfig.behaviour.settings.deleteChannelWhenEmpty.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        deleteChannelWhenOwnerDC: yup.boolean()
            .required( `A valid '${ settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        moveUserOnCreate: yup.boolean()
            .required( `A valid '${ settingsConfig.behaviour.settings.moveUserOnCreate.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        disconnectUserOnDeny: yup.boolean()
            .required( `A valid '${ settingsConfig.behaviour.settings.disconnectUserOnDeny.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        inheritParentPermissions: yup.boolean()
            .required( `A valid '${ settingsConfig.behaviour.settings.inheritParentPermissions.title }' value is required.` )
            .typeError( "Must be a boolean." )

    } );
};
