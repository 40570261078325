/**
 * StyledListItem Component
 *
 * /custom/StyledList/StyledListItem.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, ListItem, listItemIconClasses, ListItemProps } from "@mui/material";

import { theme } from "../../theme/theme";

/**
 * Define the StyledListItem Component
 * @param props The StyledListItem props
 * @returns StyledListItem Component
 */
export const StyledListItem = styled( ( props : ListItemProps ) => {

    return <ListItem dense disableGutters disablePadding { ... props } />;

} )( () => ( {

    [ `& .${ listItemIconClasses.root }` ]: {
        minWidth: theme.spacing( 4 )
    }

} ) );
