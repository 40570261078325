/**
 * ServerListItem Component
 *
 * /components/ServerList/ServerListItem.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Link as RouterLink } from "react-router-dom";
import { Link, useMediaQuery } from "@mui/material";

import { ServerIcon } from "../ServerIcon/ServerIcon";

import { DiscordServerResponse } from "../../types/discord/DiscordServerResponse";

import { theme } from "../../theme/theme";

import { BodyText } from "../../font/BodyText";

type ServerListItemProps = {
    server : DiscordServerResponse;
    link : string;
    visible : boolean;
};

/**
 * Define the ServerListItem Component
 * @param props The ServerListItem props
 * @returns ServerListItem Component
 */
export const ServerListItem = ( props : ServerListItemProps ) => {

    // Define relevant data
    const isNarrow = useMediaQuery( theme.breakpoints.down( "md" ) );
    const size = isNarrow ? 100 : 150;
    const scale = isNarrow ? 128 : 256;

    // Display the component
    return (
        <Link to={ `${ props.link }/${ props.server.id }` } component={ RouterLink } underline="none" sx={{
            display: props.visible ? "inline-block" : "none",
            margin: theme.spacing( 1.5 ),
            maxWidth: `${ size }px`,
            transition: "200ms",
            verticalAlign: "top",
            "&:hover": {
                transform: `translate( 0, -${ theme.spacing( 1 ) } )`
            } }}>

            {/* Display server icon */}
            <ServerIcon id={ props.server.id } name={ props.server.name } icon={ props.server.icon } size={ size } scale={ scale } />

            {/* Display server name */}
            <BodyText textAlign="center" paddingTop={ theme.spacing( 1 ) }>
                { props.server.name }
            </BodyText>

        </Link>
    );

};
