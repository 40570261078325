/**
 * API Utility
 * For handling API requests
 *
 * /utils/API.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import axios from "axios";

import { globalConfig } from "../config/global.config";

/**
 * Define the auth request handler
 */
export const authRequest = axios.create( {
    baseURL: `${ globalConfig.authUrl }/api`,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
} );

/**
 * Define the server request handler
 */
export const serverRequest = axios.create( {
    baseURL: `${ globalConfig.serverUrl }/api`,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json"
    }
} );
