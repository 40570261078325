/**
 * PermissionRadio Component
 *
 * /components/InputPermissionRadio/PermissionRadio.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import DoneIcon from "@mui/icons-material/Done";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CloseIcon from "@mui/icons-material/Close";

import { StyledToggleButtonGroup, StyledToggleButtonGroupProps } from "../../custom/StyledToggleButtonGroup/StyledToggleButtonGroup";
import { StyledToggleButton } from "../../custom/StyledToggleButtonGroup/StyledToggleButton";

/**
 * Define the PermissionRadio Component
 * @param props The PermissionRadio props
 * @returns PermissionRadio Component
 */
export const PermissionRadio = ( props : StyledToggleButtonGroupProps ) => {

    // Display the component
    return <StyledToggleButtonGroup size="small" { ... props }>

        {/* Display the false button */}
        <StyledToggleButton value={ - 1 } color="error">
            <CloseIcon />
        </StyledToggleButton>

        {/* Display the null button */}
        <StyledToggleButton value={ 0 } color="secondary">
            <HorizontalRuleIcon />
        </StyledToggleButton>

        {/* Display the true button */}
        <StyledToggleButton value={ 1 } color="success">
            <DoneIcon />
        </StyledToggleButton>

    </StyledToggleButtonGroup>;

};
