/**
 * Appraisals config file
 * For storing appraisal configuration details
 *
 * /config/appraisal.config.ts
 *
 * Copyright (C) 2022 wadawe
 */

type AppraisalsConfig = {

    displayName : string;
    contents : string;
    submitter : string;

}[];

/**
 * The appraisals configuration
 */
export const appraisalsConfig : AppraisalsConfig = [

    {
        displayName: "ARK: Survival Evolved",
        contents: "A few years ago we decided to add voice channels to our 150K+ Discord server. We knew the channels would require self-moderation, as our mods wouldn't be able to monitor them at all times, so we began looking for 3rd party bots. Most of the bots we tried were either missing key features, or were vulnerable to trolling attempts or channel raids. Then we found VeeBot; a dynamic voice channel bot that allowed users to create and manage their own voice channels. It was in the early stages of development at the time and lacked a few features we were looking for, but the developer worked with us one-on-one to scope out our required features, and integrated them just under a week later. Since then, feature requests have continued to get added to the bot, and its now one of the most versatile dynamic voice bots available.",
        submitter: "370195843350921216"
    },

    {
        displayName: "Personal & Community Server Admin",
        contents: "A wonderful bot for lowering channel creep when it comes to smaller servers, and a wonderful way to handle spin-off chats and calls without causing side-bar bloat.",
        submitter: "172202524705685505"
    }

    // ...

];
