/**
 * useAuth Hook
 * For handling user auth
 *
 * /hooks/useAuth.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { createContext, useContext, useState } from "react";

import { UserLoginResponse } from "../types/responses/UserLoginResponse";

interface AuthProviderProps {
    children : React.ReactElement
}

interface IAuthContext {
    auth : UserLoginResponse | null;
    setAuth : ( value : UserLoginResponse | null ) => void;
}

/**
 * Define the Auth Context
 */
export const AuthContext = createContext<IAuthContext>( {} as IAuthContext );

/**
 * Define the Auth Provider
 * @returns Auth Provider
 */
export const AuthProvider = ( { children } : AuthProviderProps ) => {

    // Define relevant data
    const [ auth, setAuth ] = useState<UserLoginResponse | null>( null );

    // Display the component
    return (
        <AuthContext.Provider value={ { auth, setAuth } }>
            { children }
        </AuthContext.Provider>
    );

};

/**
 * Define the useAuth Hook
 * @returns useAuth Hook
 */
export const useAuth = () => {
    return useContext( AuthContext );
};
