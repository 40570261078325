/**
 * SystemBasicsPage Component
 *
 * /pages/ConfigurePage/SystemBasicsPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { FormattedServerChannel } from "../../types/formatted/FormattedServerChannel";
import { FormattedServerRole } from "../../types/formatted/FormattedServerRole";
import { FormattedSystemBasics } from "../../types/formatted/FormattedSystemBasics";
import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";
import { RequiredProperties } from "../../types/wrappers/RequiredProperties";
import { FormattedSystem } from "../../types/formatted/FormattedSystem";

import { useServer } from "../../hooks/useServer";
import { useRequest } from "../../hooks/useRequest";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { InputTextSetting } from "../../components/InputTextSetting/InputTextSetting";
import { InputManageableSelection } from "../../components/InputManageableSelection/InputManageableSelection";
import { SettingSection } from "../../components/SettingSection/SettingSection";
import { SettingPage } from "../../components/SettingPage/SettingPage";
import { FormFooter } from "../../components/FormFooter/FormFooter";

import { settingsConfig } from "../../config/settings.config";

import { BasicsSchema } from "../../schemas/basics.schema";

/**
 * Define the SystemBasicsPage Component
 * @returns SystemBasicsPage Component
 */
export const SystemBasicsPage = () => {

    // Define relevant data
    const request = useRequest();
    const { systemNumber } = useParams();
    const { server, setServer } = useServer();
    const [ updating, setUpdating ] = useState( false );
    const [ success, setSuccess ] = useState( false );
    const [ error, setError ] = useState( "" );

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    let systemSettings = systemCheck;

    // Create systems array
    const systemsArray = Array.from( Object.values( server.settings.systems ).filter( ( entry ) : entry is FormattedSystem => {
        return entry !== undefined;
    } ) );

    // Create used values arrays
    const usedNames = systemsArray.map( ( item ) => { return item.systemName; } ).filter( ( systemName ) => { return systemName !== systemSettings.systemName; } );
    const usedChannels = systemsArray.map( ( item ) => { return item.createChannel; } ).filter( ( createChannel ) => { return createChannel !== systemSettings.createChannel; } );

    // Define formik form and validation
    const formik = useFormik<RequiredProperties<FormattedSystemBasics>>( {
        enableReinitialize: true,
        initialValues: {
            systemName: systemSettings.systemName,
            createChannel: systemSettings.createChannel,
            parentCategory: systemSettings.parentCategory,
            publicRole: systemSettings.publicRole
        },
        validationSchema: BasicsSchema( usedNames, usedChannels ),
        onSubmit: ( values ) => {
            setUpdating( true );
            setError( "" );
            request.put( `/server/${ server.id }/system/${ systemNumber }/basics`, values ).then( () => {
                systemSettings = { ... systemSettings, ... values };
                server.settings.systems[ systemNumber ] = systemSettings;
                setServer( server );
                setUpdating( false );
                setSuccess( true );
            } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
                setUpdating( false );
                setSuccess( false );
                setError( e.response?.data?.message ?? "Failed to update system." );
            } );
        }
    } );

    // Display the page
    return (

        <SettingPage
            heading={ `System #${ systemNumber } Basics` }
            subheading={ `${ systemSettings.systemName } System` }>

            <FormikProvider value={ formik }>
                <form onSubmit={ formik.handleSubmit } onReset={ formik.handleReset } autoComplete="off">

                    <SettingSection title="System Settings">

                        <InputTextSetting
                            title={ settingsConfig.basics.settings.systemName.title }
                            name="systemName"
                            description={ settingsConfig.basics.settings.systemName.description }
                            metaData={ settingsConfig.basics.settings.systemName.metaData }
                            value={ formik.values.systemName }
                            error={ formik.errors.systemName }
                            type="text"
                            handleChange={ formik.handleChange } />

                    </SettingSection>

                    <SettingSection title="Channel Selection">

                        <InputManageableSelection
                            title={ settingsConfig.basics.settings.createChannel.title }
                            name="createChannel"
                            description={ settingsConfig.basics.settings.createChannel.description }
                            metaData={ settingsConfig.basics.settings.createChannel.metaData }
                            value={ formik.values.createChannel }
                            error={ formik.errors.createChannel }
                            handleChange={ formik.handleChange }
                            manageables={ Object.values( server.channels ).filter( ( entry ) : entry is FormattedServerChannel => {
                                return entry !== undefined && entry.type === "GUILD_VOICE";
                            } ) } />

                        <InputManageableSelection
                            title={ settingsConfig.basics.settings.parentCategory.title }
                            name="parentCategory"
                            description={ settingsConfig.basics.settings.parentCategory.description }
                            metaData={ settingsConfig.basics.settings.parentCategory.metaData }
                            value={ formik.values.parentCategory }
                            error={ formik.errors.parentCategory }
                            handleChange={ formik.handleChange }
                            manageables={ Object.values( server.channels ).filter( ( entry ) : entry is FormattedServerChannel => {
                                return entry !== undefined && entry.type === "GUILD_CATEGORY";
                            } ) } />

                    </SettingSection>

                    <SettingSection title="Role Selection">

                        <InputManageableSelection
                            title={ settingsConfig.basics.settings.publicRole.title }
                            name="publicRole"
                            description={ settingsConfig.basics.settings.publicRole.description }
                            metaData={ settingsConfig.basics.settings.publicRole.metaData }
                            value={ formik.values.publicRole }
                            error={ formik.errors.publicRole }
                            handleChange={ formik.handleChange }
                            manageables={ Object.values( server.roles ).filter( ( entry ) : entry is FormattedServerRole => {
                                return entry !== undefined;
                            } ) } />

                    </SettingSection>

                    <FormFooter ready={ formik.dirty && formik.isValid } success={ success } setSuccess={ setSuccess } updating={ updating } error={ error } />

                </form>
            </FormikProvider>

        </SettingPage>

    );

};
