/**
 * StyledList Component
 *
 * /custom/StyledList/StyledList.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, List, ListProps } from "@mui/material";

/**
 * Define the StyledList Component
 * @param props The StyledList props
 * @returns StyledList Component
 */
export const StyledList = styled( ( props : ListProps ) => {

    return <List dense disablePadding { ... props } />;

} )( () => ( {

} ) );
