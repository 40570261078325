/**
 * SettingPage Component
 *
 * /components/SettingPage/SettingPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Box, Grid, Divider } from "@mui/material";

import { SubHeading } from "../../font/SubHeading";
import { BodyText } from "../../font/BodyText";

import { theme } from "../../theme/theme";

type SettingPageProps = {
    heading : string;
    subheading : string;
    children : JSX.Element | JSX.Element[];
};

/**
 * Define the SettingPage Component
 * @param props The SettingPage props
 * @returns SettingPage Component
 */
export const SettingPage = ( props : SettingPageProps ) => {

    // Display the component
    return (
        <Grid container>

            {/* Display the header */}
            <Grid item xs>
                <Box sx={{ margin: theme.spacing( 2 ), width: `calc( 100% - ${ theme.spacing( 4 ) })` }}>
                    <SubHeading>{ props.heading }</SubHeading>
                    <BodyText>{ props.subheading }</BodyText>
                </Box>
            </Grid>

            {/* Display the contents */}
            <Grid item xs={ 12 }>
                <Divider />
                { props.children }
            </Grid>

        </Grid>
    );

};
