/**
 * HeaderButton Component
 *
 * /components/Header/HeaderButton.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { alpha, Box, Tab, Toolbar } from "@mui/material";

import { ButtonText } from "../../font/ButtonText";

import { theme } from "../../theme/theme";

type HeaderButtonProps = {
    index : number;
    text : string;
    icon : ReactElement;
    link : string;
    color : string;
    activePath : string;
    setActiveTab : ( value : number ) => void;
    setDrawerOpen : ( value : boolean ) => void;
    vertical ?: boolean;
};

/**
 * Define the HeaderButton Component
 * @param props The HeaderButton props
 * @returns HeaderButton Component
 */
export const HeaderButton = ( props : HeaderButtonProps ) => {

    // Display the component
    return (

        <Box sx={{ color: alpha( props.color, 0.8 ), transition: "200ms", "&:hover": { color: props.color, backgroundColor: alpha( theme.palette.divider, 0.5 ) } }}>
            <NavLink to={ props.link } style={{ color: "inherit", textDecoration: "none" }} onClick={ () => {
                props.setDrawerOpen( false );
                props.setActiveTab( props.index );
            } } children={ ( { isActive } ) => {

                // Display tab contents
                return <Box position="relative" bgcolor={ isActive ? alpha( theme.palette.divider, 0.5 ) : "inherit" }>

                    {/* Force tab width when orientation is vertical */}
                    <Tab value={ props.link } sx={{ width: props.vertical ? "250px" : "auto", opacity: 1 }} label={
                        <Box color={ isActive ? `${ props.color } !important` : "inherit" } textAlign={ props.vertical ? "left" : "center" } position="relative" width="100%">

                            {/* Center button items */}
                            <Toolbar sx={{ minHeight: 0 }} variant="dense" disableGutters>

                                {/* Display button icon */}
                                { props.icon }

                                {/* Display button text */}
                                <ButtonText paddingLeft={ theme.spacing( props.vertical ? 2 : 1 ) } textAlign="left">{ props.text }</ButtonText>

                            </Toolbar>

                        </Box>
                    } />
                </Box>;

            } } />
        </Box>

    );

};
