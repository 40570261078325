/**
 * StyledContainer Component
 *
 * /custom/StyledContainer/StyledContainer.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Container, ContainerProps } from "@mui/material";

/**
 * Define the StyledContainer Component
 * @param props The StyledContainer props
 * @returns StyledContainer Component
 */
export const StyledContainer = styled( ( props : ContainerProps ) => {

    return <Container maxWidth="xl" { ... props } />;

} )( () => ( {

} ) );
