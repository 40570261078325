/**
 * InputManageableSelection Component
 *
 * /components/InputManageableSelection/InputManageableSelection.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { SettingWrapper } from "../SettingWrapper/SettingWrapper";

import { StyledTextField } from "../../custom/StyledTextField/StyledTextField";
import { StyledMenuItem } from "../../custom/StyledMenuItem/StyledMenuItem";

import { FormattedServerChannel } from "../../types/formatted/FormattedServerChannel";
import { FormattedServerRole } from "../../types/formatted/FormattedServerRole";

import { SettingValue } from "../../font/SettingValue";
import { SettingCaption } from "../../font/SettingCaption";

type InputManageableSelectionProps = {
    title : string;
    name : string;
    description : string;
    metaData : string[];
    value : string;
    error ?: string;
    handleChange : ( e : React.ChangeEvent ) => void;
    manageables : FormattedServerChannel[] | FormattedServerRole[];
};

/**
 * Define the InputManageableSelection Component
 * @param props The InputManageableSelection props
 * @returns InputManageableSelection Component
 */
export const InputManageableSelection = ( props : InputManageableSelectionProps ) => {

    // Display the component
    return (
        <SettingWrapper title={ props.title } description={ props.description } metaData={ props.metaData } error={ props.error }>

            {/* Display text field */}
            <StyledTextField id={ props.name } name={ props.name } select
                onChange={ props.handleChange }
                value={ props.value }
                type="text">

                {/* Display field options */}
                {
                    props.manageables.map( ( entry, index ) => {
                        return <StyledMenuItem key={ index } value={ entry.id } disabled={ ! entry.manageable }>
                            <SettingValue>{ entry.name }</SettingValue>
                            <SettingCaption>{ entry.id }</SettingCaption>
                        </StyledMenuItem>;
                    } )
                }

            </StyledTextField>

        </SettingWrapper>
    );

};
