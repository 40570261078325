/**
 * System Permissions Schema
 *
 * /schemas/permissions.schema.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import * as yup from "yup";

import { permissionsConfig } from "../config/permissions.config";

import { FormattedPermissionsMap } from "../types/formatted/FormattedPermissionsMap";

/**
 * Define the Permissions Schema
 * @returns Permissions Schema
 */
export const PermissionsSchema = () => {
    return yup.object<Partial<Record<keyof FormattedPermissionsMap, yup.AnySchema>>>(

        Object.fromEntries( permissionsConfig.allowedPerms.map( ( permission ) => [
            permission, yup.number().integer()
                .required( `A '${ permissionsConfig.details[ permission ].title }' value is required.` )
                .typeError( "Must be a number." )
                .min( - 1, "Must be '-1' or above." )
                .max( 1, "Must be '1' or below." )
        ] ) )

    );
};
