/**
 * RequireAuth Component
 *
 * /providers/RequireAuth.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useRefresh } from "../hooks/useRefresh";
import { useAuth } from "../hooks/useAuth";

import { Section } from "../layout/Section/Section";

import { LoadingCircle } from "../components/LoadingCircle/LoadingCircle";

/**
 * Define the RequireAuth Component
 * @returns RequireAuth Component
 */
export const RequireAuth = () => {

    // Define relevant data
    const location = useLocation();
    const refreshAccess = useRefresh();
    const [ validated, setValidated ] = useState( false );
    const [ errored, setErrored ] = useState( false );
    const { auth } = useAuth();

    // Refresh access token
    useEffect( () => {
        refreshAccess().then( ( accessToken ) => {
            if ( accessToken ) {
                setValidated( true );
            }
            else {
                setErrored( true );
            }
        } );
    }, [] );

    // Show contents based on auth status
    // Redirect to login if necessary
    return (
        errored
            ? <Navigate to="/login" state={{ from: location }} replace />
            : validated
                ? auth?.id
                    ? <Outlet />
                    : <Navigate to="/login" state={{ from: location }} replace />
                : <Section><LoadingCircle title="Verifying user login..."/></Section>
    );

};
