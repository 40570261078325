/**
 * InputToggleSwitch Component
 *
 * /components/InputToggleSwitch/InputToggleSwitch.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { FormControlLabel } from "@mui/material";

import { StyledSwitch } from "../../custom/StyledSwitch/StyledSwitch";

import { SettingWrapper } from "../SettingWrapper/SettingWrapper";
import { SettingBox } from "../SettingBox/SettingBox";

import { SettingValue } from "../../font/SettingValue";

type InputToggleSwitchProps = {
    title : string;
    name : string;
    description : string;
    metaData : string[];
    value : boolean;
    error ?: string;
    handleChange : ( e : React.ChangeEvent ) => void;
    trueText ?: string;
    falseText ?: string;
};

/**
 * Define the InputToggleSwitch Component
 * @param props The InputToggleSwitch props
 * @returns InputToggleSwitch Component
 */
export const InputToggleSwitch = ( props : InputToggleSwitchProps ) => {

    // Define relevant data
    const trueText = props.trueText ?? "Enabled";
    const falseText = props.falseText ?? "Disabled";

    // Display the component
    return (
        <SettingWrapper title={ props.title } description={ props.description } metaData={ props.metaData } error={ props.error }>

            <SettingBox>

                {/* Display toggle switch */}
                <FormControlLabel
                    labelPlacement="end"

                    // Define switch text
                    label={
                        <SettingValue>
                            { props.value ? trueText : falseText }
                        </SettingValue>
                    }

                    // Define switch component
                    control={
                        <StyledSwitch id={ props.name } name={ props.name }
                            onChange={ props.handleChange }
                            checked={ props.value } />
                    } />

            </SettingBox>

        </SettingWrapper>
    );

};
