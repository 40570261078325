/**
 * StyledAccordion Component
 *
 * /custom/StyledAccordion/StyledAccordion.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Accordion, accordionClasses, accordionDetailsClasses, AccordionProps, accordionSummaryClasses } from "@mui/material";

import { theme } from "../../theme/theme";

/**
 * Define the StyledAccordion Component
 * @param props The StyledAccordion props
 * @returns StyledAccordion Component
 */
export const StyledAccordion = styled( ( props : AccordionProps ) => {

    return <Accordion disableGutters elevation={ 0 } square { ... props } />;

} )( () => ( {

    backgroundColor: "transparent",
    border: "none",
    marginTop: "0",

    [ `&.${ accordionClasses.root }` ]: {
        margin: "0"
    },

    "&:before": {
        display: "none"
    },

    [ `& .${ accordionSummaryClasses.root }` ]: {
        minHeight: theme.spacing( 2 ),
        padding: "0"
    },

    [ `& .${ accordionSummaryClasses.content }` ]: {
        margin: "0"
    },

    [ `& .${ accordionDetailsClasses.root }` ]: {
        padding: "0"
    }

} ) );
