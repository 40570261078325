/**
 * KickerText Component
 *
 * /font/KickerText.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Typography, TypographyProps } from "@mui/material";

import { theme } from "../theme/theme";

/**
 * Define the KickerText Component
 * @param props The KickerText props
 * @returns KickerText Component
 */
export const KickerText = styled( ( props : TypographyProps ) => {

    return <Typography variant="h3" { ... props } />;

} )( () => ( {

    color: theme.palette.secondary.main

} ) );
