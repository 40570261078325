/**
 * FormFooter Component
 *
 * /components/FormFooter/FormFooter.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Box, Button, Snackbar, Toolbar, Stack, AlertProps, Alert as MuiAlert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { forwardRef, SyntheticEvent } from "react";

import { SettingCaption } from "../../font/SettingCaption";
import { SettingTitle } from "../../font/SettingTitle";

import { theme } from "../../theme/theme";

type FormFooterProps = {
    success : boolean;
    setSuccess : ( value : boolean ) => void;
    ready : boolean;
    updating : boolean;
    error : string;
};

/**
 * Define the FormFooter Component
 * @param props The FormFooter props
 * @returns FormFooter Component
 */
export const FormFooter = ( props : FormFooterProps ) => {

    // Define feedback alert
    const Alert = forwardRef<HTMLDivElement, AlertProps>( function Alert ( props, ref, ) {
        return <MuiAlert ref={ ref } variant="filled" { ... props } />;
    } );

    // Define close alert function
    const closeAlert = ( _event ?: SyntheticEvent | Event, reason ?: string ) => {
        if ( reason === "clickaway" ) { return; }
        props.setSuccess( false );
    };

    // Display the component
    return (
        <Box>

            <Toolbar disableGutters>

                {/* Display updating or error text */}
                <Stack direction="row" sx={{ flexGrow: 1 }} spacing={ 1 } paddingLeft={ theme.spacing( 2 ) }>
                    <SettingCaption padding={ `${ theme.spacing( 2 ) } 0` } color={ props.error ? "error" : theme.palette.text.secondary }>
                        { props.updating || props.error ? props.error || "Updating..." : "" }
                    </SettingCaption>
                </Stack>

                {/* Display form buttons */}
                <Stack direction="row-reverse" sx={{ flexGrow: 1 }} spacing={ 1 } paddingRight={ theme.spacing( 2 ) }>

                    {/* Display save button */}
                    {/* Disable if form is updating or if there is an error */}
                    <LoadingButton color="primary" variant="contained" type="submit" size="small" disabled={ props.updating || ! props.ready || Boolean( props.error ) } loading={ props.updating }>
                        { props.updating ? "Saving" : "Save" }
                    </LoadingButton>

                    {/* Display reset button */}
                    <Button color="error" variant="contained" type="reset" size="small">Reset</Button>

                </Stack>

            </Toolbar>

            {/* Display snackbar alert */}
            <Snackbar open={ props.success } autoHideDuration={ 3500 } onClose={ closeAlert }>
                <Alert onClose={ closeAlert } severity="success" sx={{ width: "100%" }}>
                    <SettingTitle>Updated settings!</SettingTitle>
                </Alert>
            </Snackbar>

        </Box>
    );

};
