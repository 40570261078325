/**
 * ButtonText Component
 *
 * /font/ButtonText.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Typography, TypographyProps } from "@mui/material";

/**
 * Define the ButtonText Component
 * @param props The ButtonText props
 * @returns ButtonText Component
 */
export const ButtonText = styled( ( props : TypographyProps ) => {

    return <Typography variant="button" { ... props } />;

} )( () => ( {

    color: "inherit"

} ) );
