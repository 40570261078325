/**
 * System Commands Schema
 *
 * /schemas/commands.schema.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import * as yup from "yup";

import { settingsConfig } from "../config/settings.config";

import { FormattedSystemCommands } from "../types/formatted/FormattedSystemCommands";

/**
 * Define the Commands Schema
 * @returns Commands Schema
 */
export const CommandsSchema = () => {
    return yup.object<Partial<Record<keyof FormattedSystemCommands, yup.AnySchema>>>( {

        enableCommandName: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandName.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandLimit: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandLimit.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandBitrate: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandBitrate.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandLock: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandLock.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandUnlock: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandUnlock.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandGrant: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandGrant.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandDeny: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandDeny.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandClear: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandClear.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandKick: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandKick.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandMute: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandMute.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandUnmute: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandUnmute.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandTransfer: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandTransfer.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandClaim: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandClaim.title }' value is required.` )
            .typeError( "Must be a boolean." ),

        enableCommandDelete: yup.boolean()
            .required( `A valid '${ settingsConfig.commands.settings.enableCommandDelete.title }' value is required.` )
            .typeError( "Must be a boolean." )

    } );
};
