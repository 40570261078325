/**
 * InputPermissionRadio Component
 *
 * /components/InputPermissionRadio/InputPermissionRadio.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Grid, Divider, Stack } from "@mui/material";
import { Field } from "formik";

import { SettingTitle } from "../../font/SettingTitle";
import { SettingCaption } from "../../font/SettingCaption";

import { theme } from "../../theme/theme";

import { PermissionRadio } from "./PermissionRadio";

type InputPermissionRadioProps = {
    title : string;
    name : string;
    description : string;
    value : number | undefined;
    error ?: string;
};

/**
 * Define the InputPermissionRadio Component
 * @param props The InputPermissionRadio props
 * @returns InputPermissionRadio Component
 */
export const InputPermissionRadio = ( props : InputPermissionRadioProps ) => {

    // Display the component
    return (
        <Grid container>

            {/* Display top divider */}
            <Grid item xs={ 12 } marginBottom={ theme.spacing( 1 ) }>
                <Divider/>
            </Grid>

            {/* Display setting text */}
            <Grid item xs>
                <Stack direction="column" margin={ `0 ${ theme.spacing( 1 ) } ${ theme.spacing( 1 ) } ${ theme.spacing( 3 ) }` }>

                    {/* Display setting title */}
                    <SettingTitle>{ props.title }</SettingTitle>

                    {/* Display setting description */}
                    <SettingCaption>{ props.description }</SettingCaption>

                </Stack>
            </Grid>

            {/* Display permissions radio */}
            <Grid item marginBottom={ theme.spacing( 1 ) } marginRight={ theme.spacing( 2 ) }>
                <Field
                    name={ props.name }
                    component={ PermissionRadio }
                    value={ props.value ?? 0 } />
            </Grid>

        </Grid>
    );

};
