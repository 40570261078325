/**
 * SettingSection Component
 *
 * /components/SettingSection/SettingSection.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { alpha, Grid, Box, Divider } from "@mui/material";

import { SectionTitle } from "../../font/SectionTitle";

import { theme } from "../../theme/theme";

type SettingSectionProps = {
    title : string;
    children : JSX.Element | JSX.Element[];
};

/**
 * Define the SettingSection Component
 * @param props The SettingSection props
 * @returns SettingSection Component
 */
export const SettingSection = ( props : SettingSectionProps ) => {

    // Display the component
    return (
        <Grid container>

            {/* Display the section title */}
            <Grid item xs={ 12 }>
                <Box sx={{ padding: `${ theme.spacing( 2 ) } 0`, width: "100%",
                    backgroundColor: alpha( theme.palette.divider, 0.2 ) }}>
                    <SectionTitle paddingLeft={ theme.spacing( 2 ) }>{ props.title }</SectionTitle>
                </Box>
            </Grid>

            {/* Display the section contents */}
            <Grid item xs={ 12 }>
                <Divider />
                { props.children }
                <Divider />
            </Grid>

        </Grid>
    );

};
