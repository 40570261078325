/**
 * CreateRolePermissions Component
 *
 * /components/CreateRolePermissions/CreateRolePermissions.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { useParams } from "react-router-dom";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { ButtonGroup, Menu } from "@mui/material";
import { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

import { useRequest } from "../../hooks/useRequest";
import { useServer } from "../../hooks/useServer";

import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";
import { FormattedPermissionsMap } from "../../types/formatted/FormattedPermissionsMap";

import { ErrorCircle } from "../ErrorCircle/ErrorCircle";
import { SettingWrapper } from "../SettingWrapper/SettingWrapper";

import { StyledMenuItem } from "../../custom/StyledMenuItem/StyledMenuItem";

import { permissionsConfig } from "../../config/permissions.config";

import { SettingValue } from "../../font/SettingValue";
import { SettingCaption } from "../../font/SettingCaption";

type CreateRolePermissionsProps = {
    title : string;
    name : string;
    description : string;
    metaData : string[];
    setRoleCount : ( value : number ) => void;
};

/**
 * Define the CreateRolePermissions Component
 * @param props The CreateRolePermissions props
 * @returns CreateRolePermissions Component
 */
export const CreateRolePermissions = ( props : CreateRolePermissionsProps ) => {

    // Define relevant data
    const request = useRequest();
    const { systemNumber } = useParams();
    const { server, setServer } = useServer();
    const [ updating, setUpdating ] = useState( false );
    const [ value, setValue ] = useState( "" );
    const [ error, setError ] = useState( "" );
    const [ menu, setMenu ] = useState<null | HTMLElement>( null );
    const open = Boolean( menu );
    const isMounted = useRef( false );

    // Define menu handling functions
    const openMenu = ( event : MouseEvent<HTMLButtonElement> ) => { setMenu( event.currentTarget ); };
    const closeMenu = () => { setMenu( null ); };

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    const systemSettings = systemCheck;

    // Handle value change
    useEffect( () => {

        // Verify component is mounted
        if ( isMounted.current && value ) {

            // Send values to server
            setUpdating( true );
            setError( "" );
            closeMenu();
            const permissionsMap : FormattedPermissionsMap = permissionsConfig.allowedPerms.reduce( ( result, value ) => ( { ... result, [ value ]: 0 } ), {} );
            request.post( `/server/${ server.id }/system/${ systemNumber }/permissions/role/${ value }`, permissionsMap ).then( () => {
                systemSettings.rolePerms[ value ] = { id: value, value: permissionsMap };
                server.settings.systems[ systemNumber ] = systemSettings;
                setServer( server );
                setUpdating( false );
                setValue( "" );
                props.setRoleCount( Object.keys( systemSettings.rolePerms ).length );
            } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
                setUpdating( false );
                setError( e.response?.data?.message || "Failed to update system." );
            } );

        }

        // Set component as mounted
        else {
            isMounted.current = true;
        }

    }, [ value ] );

    // Create role arrays
    const allRoles = Object.values( server.roles );
    const existingRoles = Object.keys( systemSettings.rolePerms );

    // Display the component
    return (
        <SettingWrapper title={ props.title } description={ props.description } metaData={ props.metaData } error={ error }>

            <ButtonGroup variant="outlined" size="small" disableElevation>

                {/* Display add button */}
                <LoadingButton onClick={ openMenu } variant="outlined" loading={ updating } disabled={ updating }>
                    <AddIcon />
                </LoadingButton>

                {/* Display dropdown menu */}
                <Menu open={ open } onClose={ closeMenu } anchorEl={ menu }>

                    {/* Create menu items for all roles */}
                    {
                        allRoles.map( ( entry, index ) => {

                            // Verify role
                            if ( entry ) {

                                // Return menu item
                                return <StyledMenuItem key={ index } value={ entry.id } onClick={ () => setValue( entry.id ) }

                                    // Define whether the menu item is disabled
                                    disabled={ ! entry.manageable || existingRoles.includes( entry.id ) || systemSettings.publicRole === entry.id }>

                                    {/* Display item label */}
                                    <SettingValue>{ entry.name }</SettingValue>

                                    {/* Display item id */}
                                    <SettingCaption>{ entry.id }</SettingCaption>

                                </StyledMenuItem>;
                            }

                            // Return empty item
                            return <></>;

                        } )
                    }

                </Menu>

            </ButtonGroup>

        </SettingWrapper>
    );

};
