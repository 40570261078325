/**
 * ServerIcon Component
 *
 * /components/ServerIcon/ServerIcon.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Avatar, Box, Card } from "@mui/material";

import { SubTitle } from "../../font/SubTitle";

import { theme } from "../../theme/theme";

type ServerIconProps = {
    name : string;
    size : number;
    id : string;
    icon : string;
    scale ?: number;
};

/**
 * Define the ServerIcon Component
 * @param props The ServerIcon props
 * @returns ServerIcon Component
 */
export const ServerIcon = ( props : ServerIconProps ) => {

    // Define relevant data
    const iconColour = nameToColour( props.name );

    // Display the component
    return (
        <Box textAlign="center" sx={{ maxWidth: `${ props.size }px`, position: "relative" }}>

            {/* Display centered card */}
            <Card variant="elevation" elevation={ 5 } sx={{ backgroundColor: theme.palette.divider, borderRadius: "50%", margin: "0 auto", maxWidth: `${ props.size }px`, position: "relative" }}>

                {/* Display icon avatar */}
                <Avatar src={ `https://cdn.discordapp.com/icons/${ props.id }/${ props.icon }.webp?size=${ props.scale ?? props.size }` }
                    sx={{ backgroundColor: iconColour, height: `${ props.size }px`, width: `${ props.size }px`, margin: "0 auto" }}>

                    {/* Display fallback icon */}
                    <SubTitle color={ `${ theme.palette.getContrastText( iconColour ) } !important` }>
                        { props.name.split( " " ).map( ( result ) => { return result[ 0 ]; } ) }
                    </SubTitle>

                </Avatar>

            </Card>

            <Box height={ `${ props.size - 2 }px` } width={ `${ props.size - 2 }px` } sx={{
                top: - 1, left: - 1, position: "absolute",
                border: `2px solid ${ theme.palette.background.default }`, borderRadius: "50%"
            }} />

        </Box>
    );

};

/**
 * Convert a string value into a hashed colour code
 * @param name The name to convert
 * @returns The generated hex code
 */
const nameToColour = ( name : string ) => {

    // Create hash value
    let hash = 0;
    for ( let i = 0; i < name.length; i ++ ) {
        hash = name.charCodeAt( i ) + ( hash << 8 ) - hash;
    }

    // Create colour code
    let colour = "#";
    for ( let i = 0; i < 3; i ++ ) {
        colour += `00${ ( hash >> i * 8 & 0xff ).toString( 16 ) }`.slice( - 2 );
    }

    // Return created value
    return colour;

};
