/**
 * SystemBehaviourPage Component
 *
 * /pages/ConfigurePage/SystemBehaviourPage.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { ErrorMessageResponse } from "../../types/responses/ErrorMessageResponse";
import { FormattedSystemBehaviour } from "../../types/formatted/FormattedSystemBehaviour";
import { RequiredProperties } from "../../types/wrappers/RequiredProperties";

import { useServer } from "../../hooks/useServer";
import { useRequest } from "../../hooks/useRequest";

import { ErrorCircle } from "../../components/ErrorCircle/ErrorCircle";
import { InputToggleSwitch } from "../../components/InputToggleSwitch/InputToggleSwitch";
import { SettingSection } from "../../components/SettingSection/SettingSection";
import { SettingPage } from "../../components/SettingPage/SettingPage";
import { FormFooter } from "../../components/FormFooter/FormFooter";

import { settingsConfig } from "../../config/settings.config";

import { BehaviourSchema } from "../../schemas/behaviour.schema";

/**
 * Define the SystemBehaviourPage Component
 * @returns SystemBehaviourPage Component
 */
export const SystemBehaviourPage = () => {

    // Define relevant data
    const request = useRequest();
    const { systemNumber } = useParams();
    const { server, setServer } = useServer();
    const [ updating, setUpdating ] = useState( false );
    const [ success, setSuccess ] = useState( false );
    const [ error, setError ] = useState( "" );

    // Verify server
    if ( ! server ) {
        return <ErrorCircle title="Failed to load server settings." />;
    }

    // Verify system count
    if ( ! systemNumber || parseInt( systemNumber ) > server.systemCount || parseInt( systemNumber ) > server.maxSystems ) {
        return <ErrorCircle title={ `Maximum system count reached : ${ server.systemCount }/${ server.maxSystems }` } />;
    }

    // Verify system
    const systemCheck = server.settings.systems[ `${ systemNumber }` ];
    if ( ! systemCheck ) {
        return <ErrorCircle title="Failed to load system settings." />;
    }
    let systemSettings = systemCheck;

    // Define formik form and validation
    const formik = useFormik<RequiredProperties<FormattedSystemBehaviour>>( {
        enableReinitialize: true,
        initialValues: {
            deleteChannelWhenEmpty: systemSettings.deleteChannelWhenEmpty
                ?? ( server.premium ? settingsConfig.behaviour.settings.deleteChannelWhenEmpty.default.premium : settingsConfig.behaviour.settings.deleteChannelWhenEmpty.default.free ),
            deleteChannelWhenOwnerDC: systemSettings.deleteChannelWhenOwnerDC
                ?? ( server.premium ? settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.default.premium : settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.default.free ),
            moveUserOnCreate: systemSettings.moveUserOnCreate
                ?? ( server.premium ? settingsConfig.behaviour.settings.moveUserOnCreate.default.premium : settingsConfig.behaviour.settings.moveUserOnCreate.default.free ),
            disconnectUserOnDeny: systemSettings.disconnectUserOnDeny
                ?? ( server.premium ? settingsConfig.behaviour.settings.disconnectUserOnDeny.default.premium : settingsConfig.behaviour.settings.disconnectUserOnDeny.default.free ),
            inheritParentPermissions: systemSettings.inheritParentPermissions
                ?? ( server.premium ? settingsConfig.behaviour.settings.inheritParentPermissions.default.premium : settingsConfig.behaviour.settings.inheritParentPermissions.default.free )
        },
        validationSchema: BehaviourSchema(),
        onSubmit: ( values ) => {
            setUpdating( true );
            setError( "" );
            request.post( `/server/${ server.id }/system/${ systemNumber }/behaviour`, values ).then( () => {
                systemSettings = { ... systemSettings, ... values };
                server.settings.systems[ systemNumber ] = systemSettings;
                setServer( server );
                setUpdating( false );
                setSuccess( true );
            } ).catch( ( e : AxiosError<ErrorMessageResponse> ) => {
                setUpdating( false );
                setSuccess( false );
                setError( e.response?.data?.message ?? "Failed to update system." );
            } );
        }
    } );

    // Display the page
    return (

        <SettingPage
            heading={ `System #${ systemNumber } Behaviour` }
            subheading={ `${ systemSettings.systemName } System` }>

            <FormikProvider value={ formik }>
                <form onSubmit={ formik.handleSubmit } onReset={ formik.handleReset } autoComplete="off">

                    <SettingSection title="Channel Deletion">

                        <InputToggleSwitch
                            title={ settingsConfig.behaviour.settings.deleteChannelWhenEmpty.title }
                            name="deleteChannelWhenEmpty"
                            description={ settingsConfig.behaviour.settings.deleteChannelWhenEmpty.description }
                            metaData={ settingsConfig.behaviour.settings.deleteChannelWhenEmpty.metaData }
                            value={ formik.values.deleteChannelWhenEmpty }
                            error={ formik.errors.deleteChannelWhenEmpty }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.behaviour.settings.deleteChannelWhenEmpty.trueText }
                            falseText={ settingsConfig.behaviour.settings.deleteChannelWhenEmpty.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.title }
                            name="deleteChannelWhenOwnerDC"
                            description={ settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.description }
                            metaData={ settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.metaData }
                            value={ formik.values.deleteChannelWhenOwnerDC }
                            error={ formik.errors.deleteChannelWhenOwnerDC }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.trueText }
                            falseText={ settingsConfig.behaviour.settings.deleteChannelWhenOwnerDC.falseText } />

                    </SettingSection>

                    <SettingSection title="User Movement">

                        <InputToggleSwitch
                            title={ settingsConfig.behaviour.settings.moveUserOnCreate.title }
                            name="moveUserOnCreate"
                            description={ settingsConfig.behaviour.settings.moveUserOnCreate.description }
                            metaData={ settingsConfig.behaviour.settings.moveUserOnCreate.metaData }
                            value={ formik.values.moveUserOnCreate }
                            error={ formik.errors.moveUserOnCreate }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.behaviour.settings.moveUserOnCreate.trueText }
                            falseText={ settingsConfig.behaviour.settings.moveUserOnCreate.falseText } />

                        <InputToggleSwitch
                            title={ settingsConfig.behaviour.settings.disconnectUserOnDeny.title }
                            name="disconnectUserOnDeny"
                            description={ settingsConfig.behaviour.settings.disconnectUserOnDeny.description }
                            metaData={ settingsConfig.behaviour.settings.disconnectUserOnDeny.metaData }
                            value={ formik.values.disconnectUserOnDeny }
                            error={ formik.errors.disconnectUserOnDeny }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.behaviour.settings.disconnectUserOnDeny.trueText }
                            falseText={ settingsConfig.behaviour.settings.disconnectUserOnDeny.falseText } />

                    </SettingSection>

                    <SettingSection title="Permissions">

                        <InputToggleSwitch
                            title={ settingsConfig.behaviour.settings.inheritParentPermissions.title }
                            name="inheritParentPermissions"
                            description={ settingsConfig.behaviour.settings.inheritParentPermissions.description }
                            metaData={ settingsConfig.behaviour.settings.inheritParentPermissions.metaData }
                            value={ formik.values.inheritParentPermissions }
                            error={ formik.errors.inheritParentPermissions }
                            handleChange={ formik.handleChange }
                            trueText={ settingsConfig.behaviour.settings.inheritParentPermissions.trueText }
                            falseText={ settingsConfig.behaviour.settings.inheritParentPermissions.falseText } />

                    </SettingSection>

                    <FormFooter ready={ formik.dirty && formik.isValid } success={ success } setSuccess={ setSuccess } updating={ updating } error={ error } />

                </form>
            </FormikProvider>

        </SettingPage>

    );

};
