/**
 * LoadingCircle Component
 *
 * /components/LoadingCircle/LoadingCircle.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { CircularProgress } from "@mui/material";

import { CircleWrapper } from "../CircleWrapper/CircleWrapper";

type LoadingCircleProps = {
    title ?: string;
    description ?: string;
    children ?: JSX.Element | JSX.Element[];
};

/**
 * Define the LoadingCircle Component
 * @param props The LoadingCircle props
 * @returns LoadingCircle Component
 */
export const LoadingCircle = ( props : LoadingCircleProps ) => {

    // Display the component
    return (
        <CircleWrapper title={ props.title } description={ props.description } children={ props.children } icon={
            <CircularProgress color="info" size="70px" />
        } />
    );

};
