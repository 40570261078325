/**
 * StyledSwitch Component
 *
 * /custom/StyledSwitch/StyledSwitch.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { styled, Switch, SwitchProps, switchClasses } from "@mui/material";

import { theme } from "../../theme/theme";

/**
 * Define the StyledSwitch Component
 * @param props The StyledSwitch props
 * @returns StyledSwitch Component
 */
export const StyledSwitch = styled( ( props : SwitchProps ) => {

    return <Switch color="success" { ... props } />;

} )( () => ( {

    height: "30px",
    padding: "0 10px",
    width: "70px",

    [ `& .${ switchClasses.track }` ]: {
        borderRadius: "15px",
        opacity: 0.2
    },

    [ `& .${ switchClasses.switchBase }` ]: {
        color: theme.palette.grey[ 50 ],
        margin: "0 10px",
        padding: "6px"
    },

    [ `& .${ switchClasses.thumb }` ]: {
        height: "18px",
        opacity: 1,
        width: "18px"
    },

    [ `& .${ switchClasses.checked }` ]: {
        color: theme.palette.success.light
    }

} ) );
