/**
 * ServerList Component
 *
 * /components/ServerList/ServerList.tsx
 *
 * Copyright (C) 2022 wadawe
 */

import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { UserServersResponse } from "../../types/responses/UserServersResponse";

import { StyledTextField } from "../../custom/StyledTextField/StyledTextField";

import { Section } from "../../layout/Section/Section";

import { ServerListItem } from "./ServerListItem";
import { ErrorCircle } from "../ErrorCircle/ErrorCircle";

import { Heading } from "../../font/Heading";
import { SettingTitle } from "../../font/SettingTitle";

type ServerListProps = {
    link : string;
    servers : UserServersResponse;
};

/**
 * Define the ServerList Component
 * @param props The ServerList props
 * @returns ServerList Component
 */
export const ServerList = ( props : ServerListProps ) => {

    // Define relevant data
    const [ search, setSearch ] = useState( "" );
    const [ serverList, setServerList ] = useState<JSX.Element[]>( [] );
    const [ serverCount, setServerCount ] = useState( 0 );

    // Create server item list
    // Update server list when search changes
    useEffect( () => {
        setServerCount( 0 );

        // Sort servers alphabetically
        setServerList( Object.values( props.servers ).sort( ( itemA, itemB ) => {
            return itemA.name.localeCompare( itemB.name );
        } ).map( ( server ) => {

            // Increment server count if server matches search term
            const isVisible = server.name.toLowerCase().indexOf( search.toLowerCase() ) > - 1;
            if ( isVisible ) {
                setServerCount( serverCount + 1 );
            }

            // Return the server list item
            return <ServerListItem key={ server.id } server={ server } link={ props.link } visible={ isVisible } />;

        } ) );
    }, [ search ] );

    // Display the component
    return (
        <Section>

            <Heading textAlign="center">Select A Server</Heading>

            <StyledTextField label="Search..." variant="outlined" color="secondary" size="medium" onChange={ ( e ) => { setSearch( e.target.value ); } } fullWidth />

            <Box textAlign="center">
                { serverCount > 0
                    ? serverList
                    : <ErrorCircle title="No servers found..." description="Try a different search term!" /> }
            </Box>

            <SettingTitle textAlign="center">You must have access to manage a server in order to edit its VeeBot configuration.</SettingTitle>

        </Section>
    );

};
