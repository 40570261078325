/**
 * Commands config file
 * For storing command configuration details
 *
 * /config/commands.config.ts
 *
 * Copyright (C) 2022 wadawe
 */

import { ApplicationCommandOptionData } from "discord.js";

type BotCommandConfig = {
    description : string;
    options : ApplicationCommandOptionData[];
};

type PremiumToggle = {
    isPremium : boolean;
}

type CommandsConfig = {

    ping : BotCommandConfig;
    status : BotCommandConfig;
    permissions : BotCommandConfig;

    name : BotCommandConfig & PremiumToggle;
    limit : BotCommandConfig & PremiumToggle;
    bitrate : BotCommandConfig & PremiumToggle;

    lock : BotCommandConfig & PremiumToggle;
    unlock : BotCommandConfig & PremiumToggle;

    grant : BotCommandConfig & PremiumToggle;
    deny : BotCommandConfig & PremiumToggle;
    clear : BotCommandConfig & PremiumToggle;
    kick : BotCommandConfig & PremiumToggle;

    mute : BotCommandConfig & PremiumToggle;
    unmute : BotCommandConfig & PremiumToggle;

    transfer : BotCommandConfig & PremiumToggle;
    claim : BotCommandConfig & PremiumToggle;

    delete : BotCommandConfig & PremiumToggle;

};

/**
 * Get the command option to define a string value
 * @param required Whether the option is required or not
 * @returns The command option
 */
const getStringValueOption = ( required : boolean ) : ApplicationCommandOptionData => {
    return {
        name: "value",
        description: "The new value to use.",
        type: "STRING",
        required: required
    };
};

/**
 * Get the command option to define an integer value
 * @param min The minimum value for the int
 * @param max The maximum value for the int
 * @param required Whether the option is required or not
 * @returns The command option
 */
const getIntegerValueOption = ( min : number, max : number, required : boolean ) : ApplicationCommandOptionData => {
    return {
        name: "value",
        description: "The new value to use.",
        type: "INTEGER",
        required: required,
        minValue: min,
        maxValue: max
    };
};

/**
 * Get the command option to define a user
 * @param required Whether the option is required or not
 * @returns The command option
 */
const getTargetUserOption = ( required : boolean ) : ApplicationCommandOptionData => {
    return {
        name: "user",
        description: "The server user to target.",
        type: "USER",
        required: required
    };
};

/**
 * Get the command option to define a dynamic channel
 * @param required Whether the option is required or not
 * @returns The command option
 */
const getDynamicChannelOption = ( required : boolean ) : ApplicationCommandOptionData => {
    return {
        name: "channel",
        description: "The dynamic channel to target. Negate to use your current channel.",
        type: "CHANNEL",
        channelTypes: [ "GUILD_VOICE" ],
        required: required
    };
};

/**
 * Get the command option to define a channel
 * @param required Whether the option is required or not
 * @returns The command option
 */
const getAnyChannelOption = ( required : boolean ) : ApplicationCommandOptionData => {
    return {
        name: "channel",
        description: "The channel to target.",
        type: "CHANNEL",
        required: required
    };
};

/**
 * Get the command option to define a saved data override
 * @param required Whether the option is required or not
 * @returns The command option
 */
const getSaveOverrideOption = ( required : boolean ) : ApplicationCommandOptionData => {
    return {
        name: "save",
        description: "Should the change persist across subsequent dynamic channels?",
        type: "BOOLEAN",
        required: required
    };
};

/**
 * The commands configuration
 */
export const commandsConfig : CommandsConfig = {

    ping: {
        description: "Retrieve the current latency status.",
        options: []
    },

    status: {
        description: "Retrieve statistics about the bot process.",
        options: []
    },

    permissions: {
        description: "Validate the permissions of a specified channel.",
        options: [
            getAnyChannelOption( true )
        ]
    },

    name: {
        description: "Set the name of a dynamic channel.",
        options: [
            getStringValueOption( true ),
            getDynamicChannelOption( false ),
            getSaveOverrideOption( false )
        ],
        isPremium: false
    },

    limit: {
        description: "Set the user limit of a dynamic channel.",
        options: [
            getIntegerValueOption( 0, 99, true ),
            getDynamicChannelOption( false ),
            getSaveOverrideOption( false )
        ],
        isPremium: false
    },

    bitrate: {
        description: "Set the bitrate of a dynamic channel.",
        options: [
            getIntegerValueOption( 8, 384, true ),
            getDynamicChannelOption( false ),
            getSaveOverrideOption( false )
        ],
        isPremium: false
    },

    lock: {
        description: "Lock a dynamic channel so that only granted users can join.",
        options: [
            getDynamicChannelOption( false ),
            getSaveOverrideOption( false )
        ],
        isPremium: false
    },

    unlock: {
        description: "Unlock a dynamic channel so that only denied users can not join.",
        options: [
            getDynamicChannelOption( false ),
            getSaveOverrideOption( false )
        ],
        isPremium: false
    },

    grant: {
        description: "Grant a user access to a locked dynamic channel.",
        options: [
            getTargetUserOption( true ),
            getDynamicChannelOption( false ),
            getSaveOverrideOption( false )
        ],
        isPremium: false
    },

    deny: {
        description: "Deny a user access to an unlocked dynamic channel.",
        options: [
            getTargetUserOption( true ),
            getDynamicChannelOption( false ),
            getSaveOverrideOption( false )
        ],
        isPremium: false
    },

    clear: {
        description: "Clear the modified access of a user to a dynamic channel.",
        options: [
            getTargetUserOption( true ),
            getDynamicChannelOption( false )
        ],
        isPremium: false
    },

    kick: {
        description: "Kick a user from a dynamic channel.",
        options: [
            getTargetUserOption( true ),
            getDynamicChannelOption( false )
        ],
        isPremium: false
    },

    mute: {
        description: "Mute a user in a dynamic channel.",
        options: [
            getTargetUserOption( true ),
            getDynamicChannelOption( false )
        ],
        isPremium: false
    },

    unmute: {
        description: "Unmute a user in a dynamic channel.",
        options: [
            getTargetUserOption( true ),
            getDynamicChannelOption( false )
        ],
        isPremium: false
    },

    transfer: {
        description: "Transfer ownership of a dynamic channel.",
        options: [
            getTargetUserOption( true ),
            getDynamicChannelOption( false )
        ],
        isPremium: false
    },

    claim: {
        description: "Claim ownership of a dynamic channel.",
        options: [
            getDynamicChannelOption( false )
        ],
        isPremium: false
    },

    delete: {
        description: "Delete a dynamic channel.",
        options: [
            getDynamicChannelOption( false )
        ],
        isPremium: false
    }

    // ...

};
